import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import SuccessContext from "../../../context/SuccessContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import { getGenderTextFromEnum, getUIID, goToNext, isIn, pluck } from "../../../utils/helpers";
import FilteredSelectList from "../../foreign-registration/utils/FilteredSelectList";
import Form from "../../registration/utils/Form";
import WeiterButton from "../../registration/utils/WeiterButton";
import PageTitle from "../../travel-registration/utils/PageTitle";

const AddRelative6 = () => {
  const data = JSON.parse(localStorage.getItem("countries"));
  let countries = pluck(data, "Name");
  countries = countries ? countries.filter((n) => !isIn(n)) : countries;

  const history = useHistory();
  const { t } = useTranslation();

  const { setHeader } = useContext(HeaderContext);
  const { setSuccessBox } = useContext(SuccessContext);

  const [path, setPath] = useState(ApplicationRoute.TravelDashboardNoPermissions);

  const [livingIn, setLivingIn] = useState("");

  useEffect(() => {
    let title = "input.mitreisende.addTitle";

    try {
      title = localStorage.getItem("addTGMTitle");
    } catch (error) { }

    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t(title),
        progress: "no",
      };
    });

    try {
      setPath(localStorage.getItem("addTGMURL"));
    } catch (error) { }
  }, []);

  const isValid = () => {
    return !!livingIn;
  };

  const updateTGM = () => {
    let tgm = JSON.parse(localStorage.getItem("newTGM"));
    localStorage.setItem(
      "newTGM",
      JSON.stringify({
        ...tgm,
        Nationality: livingIn,
        Gender: getGenderTextFromEnum(tgm.Gender, t),
      })
    );
  };

  const onClickHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      setSuccessBox((curr) => {
        return {
          ...curr,
          hidden: false,
          text: t("input.mitreisende.addOne"),
          ariaLabel: t("input.mitreisende.addOne"),
          trigger: getUIID(),
        };
      });

      goToNext(e, isValid, updateTGM, path, history);
    }
  };

  const onSubmitHandler = (e) => {
    goToNext(e, isValid, updateTGM, path, history);
  };

  const onChangeHandlerCountries = (value) => {
    setLivingIn(value);
  };

  return (
    <div className="add-relative-container registration-middle-container">
      <MetaDecorator title="pageTitle.editTrip" />
      <PageTitle id="add-county" text="input.nationality.title" />

      <p className="page-paragraph page-paragraph--small">{t("input.mitreisende.one")}</p>

      <Form id="form" onSubmit={onSubmitHandler} ariaLabelledBy="add-county" className="max-width-380">
        <FilteredSelectList
          label="input.nationality.text"
          state={livingIn}
          onChangeHandler={onChangeHandlerCountries}
          options={countries}
        />

        <HiddenSubmitInput />

        <WeiterButton
          disabled={!isValid()}
          path={path}
          onClick={onClickHandler}
          text="input.mitreisende.addPerson"
          className="floating-weiter-buton"
        />
      </Form>
    </div>
  );
};

export default AddRelative6;