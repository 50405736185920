import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import apipath from "../../apipath";
import HiddenSubmitInput from "../../common/HiddenSubmitInput";
import HorizontalRule from "../../common/HorizontalRule";
import InputValidation from "../../common/InputValidaiton";
import ApplicationRoute from "../../config/routes";
import HeaderContext from "../../context/HeaderContext";
import SuccessContext from "../../context/SuccessContext";
import UserContext from "../../context/UserContext";
import fetcher from "../../services/fetcher";
import MetaDecorator from "../../utils/MetaDecorator";
import { getUIID } from "../../utils/helpers";
import {
  arePasswordsValid,
  isPasswordValid,
} from "../../utils/validation";
import ContentTitle from "../registration/utils/ContentTitle";
import Form from "../registration/utils/Form";
import Info from "../registration/utils/Info";
import Input from "../registration/utils/Input";
import ValidationChecks from "../registration/utils/ValidationChecks.jsx";
import ValidationRow from "../registration/utils/ValidationRow";
import WeiterButton from "../registration/utils/WeiterButton";

//HINT* REGISTRATION
const ChangePassword = () => {
  const history = useHistory();
  const [currentPassword, setCurrentPassword] = useState("");
  const { setSuccessBox } = useContext(SuccessContext);
  const { t } = useTranslation();

  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
  });

  const { user } = useContext(UserContext);
  const { setHeader } = useContext(HeaderContext);

  const [updateProfileError, setUpdateProfileError] = useState(null);
  const [updateProfileLoading, setUpdateProfileLoading] = useState(true);

  const updatePassword = () => {
    fetcher(
      `${apipath.path}users/CURRENT/updatepasswordvialogin `,
      "POST",
      () => { },
      setUpdateProfileError,
      setUpdateProfileLoading,
      () => { },
      false,
      "application/json",
      JSON.stringify({
        OldPassword: currentPassword,
        NewPassword: passwords.password,
        Email: user.Email,
      }),
      false
    );
  };

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: "no",
        title: t("pages.changePasswort.header"),
      };
    });
  }, []);

  const isValid = () => {
    return (
      isPasswordValid(currentPassword) &&
      arePasswordsValid(passwords)
    );
  };

  const onChangeHandler = (e) => {
    setPasswords((curr) => {
      return { ...curr, [e.target.id]: e.target.value };
    });
  };

  const onCurrentPasswordChange = (e) => {
    setCurrentPassword(e.target.value);
  };

  const onClickHandler = (e) => {
    e.preventDefault();
    updatePassword();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updatePassword();
    }
  };

  useEffect(() => {
    if (!updateProfileLoading && !updateProfileError) {
      setSuccessBox((curr) => {
        return {
          ...curr,
          hidden: false,
          text: t("pages.changePasswort.success"),
          trigger: getUIID(),
        };
      });

      history.push(ApplicationRoute.editProfileSecond);
    }
  }, [updateProfileLoading, updateProfileError]);

  useEffect(() => {
    setPasswords((curr) => {
      let psw = user.Password ?? "";
      return { ...curr, password: psw, confirmPassword: psw };
    });
  }, [user]);

  return (
    <section className="registration-middle-container">
      <MetaDecorator title="pageTitle.forgottenPassword" />
      <ContentTitle id="change-password" style={{ marginBottom: "32px" }} text="pages.changePasswort.header" />
      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="mobile-hidden half-col " style={{ float: "right" }}>
        <Info
          style={{ marginBottom: "16px", marginRight: "0" }}
          text="pages.changePasswort.info"
        />

        <div id="hint" style={{ marginBottom: "32px" }} role="alert" aria-relevant="all">
          <ValidationChecks passwords={passwords} />

          <ValidationRow
            isValid={passwords.password.length > 0 && passwords.confirmPassword === passwords.password}
            validationText="errors.theNewPasswordWasEnterIdenticallyTwice"
          />
        </div>
      </div>
      <Form id="form" onSubmit={onSubmitHandler} ariaLabelledBy="change-password" className="max-width-380">
        <Input
          id="currentPassword"
          value={currentPassword}
          onChange={onCurrentPasswordChange}
          placeholder="input.passwort.current"
          type="password"
          labelText="input.passwort.current"
          showValidationBorder={!isPasswordValid(currentPassword)}
        />

        <InputValidation text="input.passwort.falsePw" isHidden={!updateProfileError} />

        <Input
          id="password"
          value={passwords.password}
          onChange={onChangeHandler}
          placeholder="input.passwort.new"
          type="password"
          text="input.passwort.text"
          labelText="input.passwort.new"
          ariaDescribedBy="hint"
          showValidationBorder={!isPasswordValid(passwords.password)}
        />

        <Input
          id="confirmPassword"
          value={passwords.confirmPassword}
          onChange={onChangeHandler}
          placeholder="input.passwort.new2"
          type="password"
          labelText="input.passwort.new2"
          ariaDescribedBy="hint"
          showValidationBorder={
            passwords.confirmPassword.length > 0 && passwords.confirmPassword !== passwords.password
          }
          error={passwords.confirmPassword.length > 0 && passwords.confirmPassword !== passwords.password}
        />

        <div className="desktop-hidden">
          <Info
            style={{ marginTop: "32px", marginBottom: "16px" }}
            text="pages.changePasswort.info"
          />
          <div style={{ marginBottom: "21px" }} id="hint" role="alert" aria-relevant="all">
            <ValidationChecks passwords={passwords} />
          </div>
          <ValidationRow
            isValid={passwords.password.length > 0 && passwords.confirmPassword === passwords.password}
            validationText="errors.theNewPasswordWasEnterIdenticallyTwice"
          />
        </div>

        <HiddenSubmitInput />
      </Form>

      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content" />
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.editProfileSecond}
        onClick={onClickHandler}
        ariaLabel={`${t("pages.contactPerson.weiter")} ${ApplicationRoute.editProfileSecond}`}
        positioned="static"
        style={{ marginTop: "4rem" }}
      />
    </section>
  );
};

export default ChangePassword;