import { useTranslation } from "react-i18next";
import CrisisPhone from "./CrisisPhone";
import GoogleNavigation from "./GoogleNavigation";

const CrisisCard = ({ style, country, img, address, flag, phones, className, longitude, latitude, type }) => {
  const { t } = useTranslation();
  const newTitle = t(type) + " " + t(country);

  return (
    <div style={style} className={`crisis-card__container ${className ?? ""}`}>
      <div className="crisis-card__container__img-title__wrapper">
        <div className="crisis-card__container__title-copy" />
        <div className="crisis-card__container__title">{newTitle}</div>
        <img className="crisis-card__container__img" src={img} alt="" />
      </div>

      <div className="crisis-card__container__adress-flag__wrapper">
        <div className="crisis-card__container__address-copy" />
        <div className="crisis-card__container__address">
          <span>{t(address)}</span>
          <br />
        </div>
        <div className="crisis-card__container__flag-copy" />
        <img src={flag} className="crisis-card__container__flag" alt="" />
      </div>

      {phones.map((phone, index) => {
        return <CrisisPhone key={`${phone}${index}`} phone={phone} />;
      })}

      <GoogleNavigation longitude={longitude} latitude={latitude} />
    </div>
  );
};

export default CrisisCard;