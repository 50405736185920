import { useTranslation } from "react-i18next";
import ArrowRight from "../../../svg/ArrowRight.js";

function NavigationPattern({ id, text, to }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="navigation-info-cards" key={id}>
        <div className="country__name">{t(text)}</div>

        <ArrowRight to={to} />
      </div>
    </div>
  );
}

export default NavigationPattern;