import { memo } from "react";
import { useTranslation } from "react-i18next";

function StarFunc({ color = "#757575", size = "23px", favourite }) {
  const { t } = useTranslation();

  return favourite ? (
    <button aria-label={t("pages.starSvg.remove")} className="button-abs" type="button">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="23"
        viewBox="0 0 25 24"
        role="img"
        aria-label={t("pages.starSvg.remove")}
      >
        <title>{t("pages.starSvg.remove")}</title>
        <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
          <g>
            <g transform="translate(-290 -346) translate(21 312)">
              <g>
                <path
                  stroke="#9E0529"
                  d="M11.9 17.5l6.33 4.58c.38.27.88-.09.74-.54l-2.4-7.44c-.06-.2.01-.41.17-.53l6.31-4.6c.38-.27.18-.87-.28-.87l-7.81-.01c-.21 0-.39-.13-.45-.33L12.08.33c-.14-.44-.77-.44-.91 0L8.74 7.76c-.06.2-.25.33-.45.33L.48 8.1c-.46 0-.66.59-.28.87l6.32 4.6c.17.12.24.34.17.53l-2.4 7.44c-.14.44.36.81.74.54l6.33-4.58c.15-.12.37-.12.54 0z"
                  transform="translate(270 35)"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </button>
  ) : (
    <button aria-label={t("pages.starSvg.add")} className="button-abs" type="button">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="23"
        viewBox="0 0 25 24"
        role="img"
        aria-label={t("pages.starSvg.add")}
      >
        <title>{t("pages.starSvg.add")}</title>
        <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
          <g>
            <g transform="translate(-290 -346) translate(21 312)">
              <g>
                <path
                  stroke="#919191"
                  d="M11.9 17.5l6.33 4.58c.38.27.88-.09.74-.54l-2.4-7.44c-.06-.2.01-.41.17-.53l6.31-4.6c.38-.27.18-.87-.28-.87l-7.81-.01c-.21 0-.39-.13-.45-.33L12.08.33c-.14-.44-.77-.44-.91 0L8.74 7.76c-.06.2-.25.33-.45.33L.48 8.1c-.46 0-.66.59-.28.87l6.32 4.6c.17.12.24.34.17.53l-2.4 7.44c-.14.44.36.81.74.54l6.33-4.58c.15-.12.37-.12.54 0z"
                  transform="translate(270 35)"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </button>
  );
}

const Star = memo(StarFunc);

export default Star;