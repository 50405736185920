import React from "react";
import { useTranslation } from "react-i18next";

const AnswerTravelChanges = () => {
    const { t } = useTranslation();

    return (
        <>
            <p className="answer-text">
                {t("pages.faq.answerTravelChanges")}
            </p>
        </>
    );
};

export default AnswerTravelChanges;
