import apipath from "../../../apipath";
import { compare } from "../../../utils/helpers";
import Countries from "./countries.json";
import CountriesVB from "./countriesVB.json";

const localStorageKey = "countries";

async function getDestinationsFromMiddleware() {
  try {
    const response = await fetch(`${apipath.path}destinations`);
    const data = await response.json();
    return data;
  } catch {
    return Promise.resolve(Countries);
  }
}

function getCountries() {
  let destinations = JSON.parse(localStorage.getItem(localStorageKey));

  //Destinations
  if (!destinations || !destinations.length) {
    let destinationsPromise = getDestinationsFromMiddleware();
    destinationsPromise.then((data) => {
      localStorage.setItem(localStorageKey, JSON.stringify(data));
    });

    return destinationsPromise;
  }

  return Promise.resolve(destinations);
}

export function getCountries__with__loader(setLoader, t) {
  let destinations = JSON.parse(localStorage.getItem(localStorageKey));

  //Destinations
  if (!destinations || !destinations.length) {
    let destinationsPromise = getDestinationsFromMiddleware();
    destinationsPromise.then((data) => {
      let index = data.findIndex((d) => d.Name === t("terms.austria"));
      if (index) {
        let ostObject = data[index];
        data.splice(index, 1);
        data.unshift(ostObject);
      }

      localStorage.setItem(localStorageKey, JSON.stringify(data));
    });
  }
  setLoader(true);
}

////
////
////
////
////
////
////
////
////
////
////
////

export async function getVB__with__loader(setLoader) {
  try {
    const etag = localStorage.getItem("ETag");

    //get VB
    const response = await fetch(apipath.pathBMEIAgvat, {
      method: "GET",
      headers: {
        "If-None-Match": etag,
      },
    }, true, true);

    //set E-TAG
    try {
      localStorage.setItem("ETag", response.headers.get("ETag"));
    } catch (error) { }

    // NO DATA
    if (!localStorage.getItem("countriesVB")) {
      const data = await response.json();
      data.countries.sort(compare);

      localStorage.setItem("countriesVB", JSON.stringify(data));
    }
    //HAS DATA
    else {
      if (response.status === 304) {
        // Keine Logik, da es soll nicht in den else gehen
      } else if (response.status === 200) {
        const data = await response.json();
        data.countries.sort(compare);

        localStorage.setItem("countriesVB", JSON.stringify(data));
      } else {
        let x = CountriesVB;
        x.countries.sort(compare);

        localStorage.setItem("countriesVB", JSON.stringify(x));
      }
    }
  } catch (error) {
    let x = CountriesVB;
    x.countries.sort(compare);
    localStorage.setItem("countriesVB", JSON.stringify(x));
  }

  setLoader(true);
}

export default getCountries;