import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import NavigationCard from "../utils/NavigationCard";

function Notfalltipps() {
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: t("pages.notfalltipps.title"),
      };
    });
  }, []);

  return (
    <div className="content-pages-container">
      <MetaDecorator title="pages.notfalltipps.title" />

      <h5 className="heading-16px-bold">{t("pages.notfalltipps.notfallNummerHeader")}</h5>

      <div className="content-pages-secondary-button">
        <a role="button" href="tel:+431901154411" id="white-button-2" className="white-button">
          <span className="white-button__text">+43 1 90115 4411</span>
        </a>
      </div>

      <h5 className="heading-16px-bold">{t("pages.notfalltipps.reiseInfoHeader")}</h5>
      <div className="content-pages-secondary-button">
        <a role="button" href="tel:+431901153775" id="white-button-1" className="white-button">
          <span className="white-button__text">+43 1 90115 3775</span>
        </a>
      </div>

      <NavigationCard text="pages.notfalltipps.navigationTitle.erreichbarkeitInNotfallen" to={ApplicationRoute.erreichbarkeitInNotfallen} />

      <NavigationCard text="pages.notfalltipps.navigationTitle.unfallErkrankungUndSterbefalle" to={ApplicationRoute.unfallErkrankungUndSterbefalle} />

      <NavigationCard text="pages.notfalltipps.navigationTitle.passverlust" to={ApplicationRoute.passverlust} />

      <NavigationCard
        text="pages.notfalltipps.navigationTitle.verloreneFahrzeugpapiereFuhrerschein"
        to={ApplicationRoute.verloreneFahrzeugpapiereFuhrerschein}
      />

      <NavigationCard text="pages.notfalltipps.navigationTitle.finanzielleNotlage" to={ApplicationRoute.finanzielleNotlage} />

      <NavigationCard text="pages.notfalltipps.navigationTitle.vermissteAngehorige" to={ApplicationRoute.vermissteAngehorige} />

      <NavigationCard text="pages.notfalltipps.navigationTitle.katastrophenPolitischeUnruhen" to={ApplicationRoute.katastrophenPolitischeUnruhen} />

      <NavigationCard text="pages.notfalltipps.navigationTitle.reisewarnungen" to={ApplicationRoute.reisewarnungen} />

      <NavigationCard
        text="pages.notfalltipps.navigationTitle.regressforderungenSchutzmassnahmen"
        to={ApplicationRoute.regressforderungenSchutzmassnahmen}
      />

      <NavigationCard
        text="pages.notfalltipps.navigationTitle.ministeriumAuswartige"
        to={ApplicationRoute.ministeriumAuswartige}
      />
    </div>
  );
}

export default Notfalltipps;