import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import InputValidation from "../../../common/InputValidaiton";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import { goToNext, hasMiddleName } from "../../../utils/helpers";
import { hasNumber, isValidFirstAndLastName } from "../../../utils/validation";
import CustomSelectList from "../../foreign-registration/utils/CustomSelectList";
import Form from "../../registration/utils/Form";
import Input from "../../registration/utils/Input";
import WeiterButton from "../../registration/utils/WeiterButton";
import PageTitle from "../../travel-registration/utils/PageTitle";

const AddRelative1 = () => {
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  const [activated, setActivated] = useState(false);

  const relationships = useMemo(
    () => [
      t("input.relative.options.parent"),
      t("input.relative.options.sibling"),
      t("input.relative.options.child"),
      t("input.relative.options.partner"),
      t("input.relative.options.etc"),
    ],
    [t]
  );

  useEffect(() => {
    let title = "input.mitreisende.addTitle";

    try {
      title = localStorage.getItem("addTGMTitle");
    } catch (error) { }

    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t(title),
        progress: "no",
      };
    });
  }, []);

  let initname = "";
  let initRelationship = "";
  try {
    initname = JSON.parse(localStorage.getItem("newTGM")).Name ?? "";
    initRelationship = JSON.parse(localStorage.getItem("newTGM")).DegreeOfRelationship ?? "";
  } catch (error) { }

  const history = useHistory();

  const [relationship, setRelationship] = useState(initRelationship);
  const [fullName, setFullName] = useState(initname);

  const isValidRelationShip = () => {
    return relationships.includes(relationship);
  };

  const isValid = () => {
    return (
      fullName.length > 0 && isValidFirstAndLastName(fullName) && isValidRelationShip() && !hasMiddleName(fullName)
    );
  };

  const updateTGM = () => {
    localStorage.setItem(
      "newTGM",
      JSON.stringify({
        Name: fullName.trim(),
        FirstName: fullName.split(" ")[0],
        LastName: fullName.split(" ")[1],
        DegreeOfRelationship: relationship,
      })
    );
  };

  const onChangeHandler = (e) => {
    setActivated(() => true);
    setFullName(e.target.value);
  };

  const onChangeHandlerCountries = (value) => {
    setRelationship(value);
  };

  const onSubmitHandler = (e) => {
    goToNext(e, isValid, updateTGM, ApplicationRoute.addRelative2, history);
  };

  const onClickHandler = (e) => {
    goToNext(e, isValid, updateTGM, ApplicationRoute.addRelative2, history);
  };

  return (
    <section className="add-travel add-relative-container registration-middle-container">
      <MetaDecorator title="pageTitle.editTrip" />
      <PageTitle id="pages.addRelative1.formLabel" text="pages.addRelative1.title" />

      <p className="page-paragraph page-paragraph--small">{t("input.mitreisende.one")}</p>
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form id="form" onSubmit={onSubmitHandler} ariaLabelledBy="pages.addRelative1.formLabel" className="max-width-380">
        <Input
          labelText="input.vorNachname.text"
          id="firstName"
          placeholder="input.vorNachname.text"
          type="text"
          value={fullName}
          onChange={onChangeHandler}
        />

        <InputValidation
          style={{ marginBottom: "1.5rem" }}
          isHidden={isValidFirstAndLastName(fullName) || !activated || hasMiddleName(fullName)}
          text={
            hasNumber(fullName)
              ? "errors.nameHasNumber"
              : "errors.notValidContactPersonFirstAndLastName"
          }
        />
        <InputValidation
          isHidden={!hasMiddleName(fullName)}
          text="errors.middleName"
          ariaLabel="errors.middleName"
        />

        <CustomSelectList
          label="input.relative.text"
          state={relationship}
          onChangeHandler={onChangeHandlerCountries}
          options={relationships}
        />

        <HiddenSubmitInput />
      </Form>

      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content" />

      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addRelative2}
        onClick={onClickHandler}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </section>
  );
};

export default AddRelative1;