import React, { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import HeaderContext from "../../context/HeaderContext";
import MetaDecorator from "../../utils/MetaDecorator";
import AnswerChangeEmail from "./AnswerChangeEmail";
import AnswerDeleteAccount from "./AnswerDeleteAccount";
import AnswerDeleteInfos from "./AnswerDeleteInfos.jsx";
import AnswerDenyEmergencyContact from "./AnswerDenyEmergencyContact";
import AnswerForgotPassword from "./AnswerForgotPassword.jsx";
import AnswerForgotUser from "./AnswerForgotUser";
import AnswerNewStays from "./AnswerNewStays";
import AnswerNewTravel from "./AnswerNewTravel";
import AnswerNoLogin from "./AnswerNoLogin.jsx";
import AnswerNoMail from "./AnswerNoMail";
import AnswerNoTravelMail from "./AnswerNoTravelMail";
import AnswerPassportError from "./AnswerPassportError";
import AnswerRegister from "./AnswerRegister.jsx";
import AnswerTravelCancel from "./AnswerTravelCancel";
import AnswerTravelChanges from "./AnswerTravelChanges";
import AnswerTravelersData from "./AnswerTravelersData";
import QuestionAnswerTemplate from "./QuestionAnswerTemplate.jsx";

export const ActiveQuestion = createContext({})

const FAQsPage = () => {
    const { t } = useTranslation();
    const { setHeader } = React.useContext(HeaderContext);

    const [scrolledRef, setScrolledRef] = useState(false);
    const { hash } = useLocation();

    const [question, setQuestion] = useState("")

    useEffect(() => {
        const messageTimeout = setTimeout(() => {
            if (hash && !scrolledRef) {
                const element = document.getElementById(hash.replace('#', ''));
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                    setScrolledRef(true);
                }
            }
        }, 50);
        return () => clearTimeout(messageTimeout);
    }, []);

    useEffect(() => {
        setHeader((curr) => {
            return {
                ...curr,
                inUse: "registration",
                progress: "no",
                title: t("pageTitle.faqs"),
            };
        });
    }, [setHeader]);

    return (
        <div className="registration-middle-container registration-middle-container--se faq-container">
            <MetaDecorator title="pageTitle.faqs" />
            <ActiveQuestion.Provider value={{ question: question, setQuestion: setQuestion }}>
                <QuestionAnswerTemplate
                    question="pages.faq.questionRegister"
                    answer={<AnswerRegister />}
                    anchor="register"
                />
                <QuestionAnswerTemplate
                    question="pages.faq.questionNoLogin"
                    answer={<AnswerNoLogin />}
                    anchor="no-login"
                />
                <QuestionAnswerTemplate
                    question="pages.faq.questionForgotPassword"
                    answer={<AnswerForgotPassword />}
                    anchor="forgot-password"
                />
                <QuestionAnswerTemplate
                    question="pages.faq.questionNoMail"
                    answer={<AnswerNoMail />}
                    anchor="no-mail"
                />
                <QuestionAnswerTemplate
                    question="pages.faq.questionForgotUser"
                    answer={<AnswerForgotUser />}
                    anchor="forgot-user"
                />
                <QuestionAnswerTemplate
                    question="pages.faq.questionNewTravel"
                    answer={<AnswerNewTravel />}
                    anchor="new-travel"
                />
                <QuestionAnswerTemplate
                    question="pages.faq.questionNewStays"
                    answer={<AnswerNewStays />}
                    anchor="new-stays"
                />
                <QuestionAnswerTemplate
                    question="pages.faq.questionPassportError"
                    answer={<AnswerPassportError />}
                    anchor="passport-error"
                />
                <QuestionAnswerTemplate
                    question="pages.faq.questionTravelersData"
                    answer={<AnswerTravelersData />}
                    anchor="travelers-data"
                />
                <QuestionAnswerTemplate
                    question="pages.faq.questionNoTravelMail"
                    answer={<AnswerNoTravelMail />}
                    anchor="no-travel-mail"
                />
                <QuestionAnswerTemplate
                    question="pages.faq.questionTravelCancel"
                    answer={<AnswerTravelCancel />}
                    anchor="travel-cancel"
                />
                <QuestionAnswerTemplate
                    question="pages.faq.questionTravelChanges"
                    answer={<AnswerTravelChanges />}
                    anchor="travel-changes"
                />
                <QuestionAnswerTemplate
                    question="pages.faq.questionDenyEmergencyContact"
                    answer={<AnswerDenyEmergencyContact /> /* */}
                    anchor="emergency-contact"
                />
                <QuestionAnswerTemplate
                    question="pages.faq.questionChangeEmail"
                    answer={<AnswerChangeEmail />}
                    anchor="change-email"
                />
                <QuestionAnswerTemplate
                    question="pages.faq.questionDeleteAccount"
                    answer={<AnswerDeleteAccount />}
                    anchor="delete-account"
                />
                <QuestionAnswerTemplate
                    question="pages.faq.questionDeleteInfos"
                    answer={<AnswerDeleteInfos />}
                    anchor="delete-data"
                />
            </ActiveQuestion.Provider>
        </div>
    );
};

export default FAQsPage;
