import React, { FC, useContext, useEffect, useState } from "react"
import GlobalContext from "../../Contexts/GlobalContext"
import authServivce from "../../HttpServices/AuthService"

const ProtectedPage: FC = ({ children }) => {
    const [complete, setComplete] = useState(false)
    const { setData } = useContext(GlobalContext)

    useEffect(() => {
        if (complete) return

        const authenticate = async () => {
            try {
                await authServivce.verify()
                setComplete(true)
            } catch {
                setComplete(false)
                setData(() => {
                    const newData: Record<string, any> = {
                        process: { step: 0 },
                        modal: true,
                        modalMessage: "common.error.sessionTimeout"
                    }

                    return newData
                })
            }
        }

        authenticate()
    }, [complete, setData])

    return (
        <> {complete && children} </>
    )
}

export default ProtectedPage