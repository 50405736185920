// use => after import HeaderContext
// const { setHeader } = useContext(HeaderContext);

// description
// inUse    => header to be used
// progress => percentage of the progress bar for registration header
// title    => title of the header
// events   => events to be used in the header

// use => set the desired header
//
// setHeader((curr) => {
//   return {
//     ...curr,
//     inUse: "registration",
//     progress: "10",
//     title: t("pageTitle.registrationPages"),
//     events: {}
//   };
// });

// events =>
// events.onClickLogout

import { useContext } from "react";
import { useHistory } from "react-router-dom";
import HeaderContext from "../context/HeaderContext";
import ContentHeader from "./Headers/ContentHeader";
import CountryListHeader from "./Headers/CountryListHeader";
import DseHeader from "./Headers/DseHeader.jsx";
import EditAOHeader from "./Headers/EditAOHeader";
import EditProfileHeader from "./Headers/EditProfileHeader";
import MainHeader from "./Headers/MainHeader";
import MapHeader from "./Headers/MapHeader";
import RegistrationHeader from "./Headers/RegistrationHeader";
import RegistrationHeaderCustomBackButton from "./Headers/RegistrationHeaderCustomBackButton";

const getHeight = () => {
  const lsH = localStorage.getItem("h");
  if (lsH) {
    return lsH;
  }

  const body = document.body;
  const html = document.documentElement;

  let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
  height = height - 226;

  if (height) {
    height += "px";
  } else {
    height = "calc(100vh - 276px)";
  }

  return height;
};

const headers = {
  main: "main",
  registration: "registration",
  registrationCustomGoBack: "registrationCustomGoBack",
  editProfile: "editProfile",
  noHeader: "noHeader",
  content: "content",
  map: "map",
  countriesList: "countriesList",
  editAO: "editAO",
  dse: "dse"
};

const Header = ({ value, setValue, hidden, setHidden, valueC, setValueC, hiddenC, setHiddenC, vb }) => {
  const history = useHistory();
  const { header } = useContext(HeaderContext);

  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const defaultProps = {
    title: header.title,
    ariaLabel: header.ariaLabel,
    currentProgress: header.progress + "%",
    onBackButtonClickHandler: goBack
  }

  switch (header.inUse) {
    case headers.registration:
      return <RegistrationHeader {...defaultProps} />;
    case headers.dse:
      return <DseHeader {...defaultProps} />;
    case headers.map:
      return (
        <MapHeader
          {...defaultProps}
          setMapCenter={header.setMapCenter}
          data={vb}
          value={value}
          setValue={setValue}
          hidden={hidden}
          setHidden={setHidden}
          appHeight={getHeight()}
        />
      );
    case headers.registrationCustomGoBack:
      return (
        <RegistrationHeaderCustomBackButton
          {...defaultProps}
          onBackButtonClickHandler={header.customGoBackFunc}
        />
      );
    case headers.editAO:
      return (
        <EditAOHeader
          {...defaultProps}
          onBackButtonClickHandler={header.customGoBackFunc}
        />
      );
    case headers.editProfile:
      return (
        <EditProfileHeader
          title={header.title}
          onClickCloseHandler={goBack}
          onClickLogoutHandler={header.events.onClickLogout}
        />
      );
    case headers.main:
      return <MainHeader />;
    case headers.content:
      return <ContentHeader {...defaultProps} />;
    case headers.countriesList:
      return (
        <CountryListHeader
          {...defaultProps}
          value={valueC}
          setValue={setValueC}
          hidden={hiddenC}
          setHidden={setHiddenC}
        />
      );
    case headers.noHeader:
    default:
      return <header />;
  }
};

export default Header;