import React from "react";
import { useTranslation } from "react-i18next";

const AnswerForgotPassword = () => {
    const { t } = useTranslation();

    return (
        <div className="faq-answer">
            <p className="answer-text">
                {t("pages.faq.answerForgotPassword_1")}
            </p>
            <ul>
                <li>{t("errors.passwordNotInRange")}</li>
                <li>{t("errors.passwordNoSpaces")}</li>
                <li>{t("errors.paswwordNoLowerLetter")}</li>
                <li>{t("errors.passwordNoCapitalLetter")}</li>
                <li>{t("errors.passwordNoDigit")}</li>
                <li>{t("errors.passwordNoSpecialLetter")}</li>
            </ul>
            <p className="answer-text">
                {t("pages.faq.answerForgotPassword_2")}
            </p>
        </div>
    );
};

export default AnswerForgotPassword;
