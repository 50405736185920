import { useTranslation } from "react-i18next";

const PageDescription = ({ text, tabIndex }) => {
  const { t } = useTranslation();

  return (
    <p className="page-description" tabIndex={tabIndex}>
      {t(text)}
    </p>
  );
};

export default PageDescription;