import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ClearContactDiv from "../../../common/ClearContactDiv";
import EditInput from "../../../common/EditInput";
import HorizontalRule from "../../../common/HorizontalRule";
import InputValidation from "../../../common/InputValidaiton";
import {
  GetBundeslandIdFromName,
  dateFormatter,
  getEnumFromGenderText,
  getGenderTextFromEnum,
  hasMiddleName,
  phoneFormatter
} from "../../../utils/helpers";
import {
  IsDateBiggerThanTodayValidation,
  IsDateSmallerThan_1900_1_1_Validation,
  hasAtSymbolEmailValidation,
  hasMinLettersValidation,
  hasNumber,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isDateValidation,
  isEmailValidation,
  isOlderOrIsEighteenYearsOlds,
  isPassnumberValidation,
  isPersonalNumberValidation,
  isPhoneValidation,
  isValidFirstAndLastName,
} from "../../../utils/validation";

const countriesSelectOptions = [
  "input.bundesland.options.burgenland",
  "input.bundesland.options.kaernten",
  "input.bundesland.options.niederoesterreich",
  "input.bundesland.options.oberoesterreich",
  "input.bundesland.options.salzburg",
  "input.bundesland.options.steiermark",
  "input.bundesland.options.tirol",
  "input.bundesland.options.vorarlberg",
  "input.bundesland.options.wien",
];

const optional = {
  FirstLastName: false,
  PhoneNumber: false,
  Email: false,
};

const EditProfileComponent = ({ user, updateUser, noInitialHorizontalLine, setValid }) => {
  const { t } = useTranslation();

  let { ContactPerson: contactPersonInfo, ...userInfo } = user;

  const [userInfoState, setUserInfoState] = useState(userInfo);
  const [contactPersonInfoState, setContactPersonInfoState] = useState({
    ...contactPersonInfo,
  });

  const [hasContactPerson, setHasContactPerson] = useState(false);

  const [, setOptionals] = useState(optional);
  const [, setClearContactDivAlertBoxHidden] = useState(true);

  const clearContactPersonHandler = () => {
    setClearContactDivAlertBoxHidden(false);
  };

  useEffect(() => {
    setValid(isValid());
  }, [user]);

  const isValid = () => {
    try {
      const validDate1 = IsDateBiggerThanTodayValidation(userInfoState.BirthDate);
      const validDate2 = !IsDateSmallerThan_1900_1_1_Validation(userInfoState.BirthDate);
      const validDate3 = isDateValidation(userInfoState.BirthDate);

      const validDate = validDate3 && validDate2 && validDate1;

      return (
        validDate &&
        (hasTwoCharactersAfterAtSymbolEmailValidation(contactPersonInfoState.Email) ||
          contactPersonInfoState.Email.length === 0) &&
        hasTwoCharactersAfterAtSymbolEmailValidation(userInfoState.Email) &&
        // isValidContactPerson(optionals) &&
        hasMinLettersValidation(userInfoState.FirstName) &&
        hasMinLettersValidation(userInfoState.LastName) &&
        isPassnumberValidation(userInfoState.PassNumber) &&
        isPersonalNumberValidation(userInfoState.IdNumber) &&
        isEmailValidation(userInfoState.Email) &&
        isPhoneValidation(userInfoState.PhoneNumber) &&
        isValidFirstAndLastName(contactPersonInfoState.FirstLastName) &&
        (isPhoneValidation(contactPersonInfoState.PhoneNumber, true) ||
          contactPersonInfoState.PhoneNumber.length === 0) &&
        (isEmailValidation(contactPersonInfoState.Email) || contactPersonInfoState.Email.length === 0)
      );
    } catch (error) {
      return false;
    }
  };

  const onUserInfoChangeHandler = (e) => {
    let userInfoCopy = Object.assign({}, userInfoState);
    setUserInfoState(userInfoCopy);
    if (e.target.id === "BirthDate") {
      userInfoCopy.BirthDate = dateFormatter(userInfoState.BirthDate, e.target.value);
    } else if (e.target.id === "PhoneNumber") {
      userInfoCopy.PhoneNumber = phoneFormatter(userInfoState.PhoneNumber, e.target.value);
    } else if (e.target.id === "CountyHomeRegionName") {
      userInfoCopy.CountyHomeRegionName = e.target.value;
      userInfoCopy.CountyHomeRegionId = GetBundeslandIdFromName(e.target.value, t);
    } else {
      userInfoCopy[e.target.id] = e.target.value;
    }

    setUserInfoState(userInfoCopy);
    updateUser(userInfoCopy, contactPersonInfoState);
  };

  const onContactPersonInfoChangeHandler = (e) => {
    let contactPersonInfoStateCopy = Object.assign({}, contactPersonInfoState);
    let key = e.target.id.replace("Notfalkontakt", "");

    if (key === "PhoneNumber") {
      contactPersonInfoStateCopy[key] = phoneFormatter(contactPersonInfoState.PhoneNumber, e.target.value);
    } else {
      contactPersonInfoStateCopy[key] = e.target.value;
    }
    setContactPersonInfoState(contactPersonInfoStateCopy);
    updateUser(userInfoState, contactPersonInfoStateCopy);
  };

  useEffect(() => { }, [hasContactPerson]);

  useEffect(() => {
    const { ContactPerson: cpInfo, ...uInfo } = user;

    if (cpInfo.FirstLastName.length > 0 && user.TempDeletedContact !== true) {
      setHasContactPerson(true);
    }

    if (cpInfo.PhoneNumber.length === 0) {
      cpInfo.PhoneNumber = "";
    }

    setUserInfoState(() => {
      return uInfo;
    });

    setContactPersonInfoState(() => {
      return { ...cpInfo };
    });
  }, [user]);

  const setOptionalPhoneToTrue = (phone, email) => {
    if (email) {
      return true;
    }
    return isPhoneValidation(phone);
  };

  useEffect(() => {
    setOptionals((curr) => {
      return {
        ...curr,
        FirstLastName: !!contactPersonInfoState.FirstLastName,
        PhoneNumber: setOptionalPhoneToTrue(contactPersonInfoState.PhoneNumber, contactPersonInfoState.Email),
        Email: !!contactPersonInfoState.Email,
      };
    });
  }, [contactPersonInfoState]);

  return (
    <>
      {noInitialHorizontalLine ? null : <HorizontalRule />}

      <EditInput
        id="FirstName"
        placeholder="input.vorname.text"
        value={userInfoState.FirstName}
        labelText="input.vorname.text"
        ariaRequired={true}
        onChange={onUserInfoChangeHandler}
      />
      <InputValidation
        text={
          hasNumber(userInfoState.FirstName)
            ? "errors.nameHasNumber"
            : "errors.firstNameLessCharacters"
        }
        isHidden={hasMinLettersValidation(userInfoState.FirstName)}
      />

      <HorizontalRule />
      <EditInput
        id="LastName"
        placeholder="input.nachname.text"
        value={userInfoState.LastName}
        labelText="input.nachname.text"
        ariaRequired={true}
        onChange={onUserInfoChangeHandler}
      />
      <InputValidation
        text={
          hasNumber(userInfoState.LastName)
            ? "errors.nameHasNumber"
            : "errors.lastNameLessCharacters"
        }
        isHidden={hasMinLettersValidation(userInfoState.LastName)}
      />
      <HorizontalRule />
      <EditInput
        id="Gender"
        placeholder="input.gender.text"
        value={getGenderTextFromEnum(userInfoState.Gender, t)}
        labelText="input.gender.text"
        ariaRequired={true}
        onChange={(value) =>
          onUserInfoChangeHandler({
            target: { id: "Gender", value: getEnumFromGenderText(value, t) },
          })
        }
        options={[t("input.gender.options.male"), t("input.gender.options.female"), t("input.gender.options.other")]}
      />

      <HorizontalRule />
      <EditInput
        id="BirthDate"
        placeholder="input.date.birthdate"
        value={userInfoState.BirthDate}
        labelText="input.date.birthdate"
        ariaRequired={true}
        onChange={onUserInfoChangeHandler}
      />

      <InputValidation
        text="errors.notValidDate"
        isHidden={isDateValidation(userInfoState.BirthDate)}
      />
      <InputValidation
        text="errors.ageLessThan_18"
        isHidden={
          !IsDateBiggerThanTodayValidation(userInfoState.BirthDate) ||
          !isDateValidation(userInfoState.BirthDate) ||
          isOlderOrIsEighteenYearsOlds(userInfoState.BirthDate)
        }
      />

      <InputValidation
        text="errors.dateBiggerThanToday"
        isHidden={
          !isDateValidation(userInfoState.BirthDate) || IsDateBiggerThanTodayValidation(userInfoState.BirthDate)
        }
      />
      <InputValidation
        text="errors.dateOfBirthLessThan_01_01_1900"
        isHidden={
          !isDateValidation(userInfoState.BirthDate) || !IsDateSmallerThan_1900_1_1_Validation(userInfoState.BirthDate)
        }
      />

      <HorizontalRule />

      <EditInput
        id="CountyHomeRegionName"
        placeholder="input.bundesland.text"
        value={userInfoState.CountyHomeRegionName}
        labelText="input.bundesland.text"
        ariaRequired={true}
        onChange={(value) =>
          onUserInfoChangeHandler({
            target: { id: "CountyHomeRegionName", value: value },
          })
        }
        options={countriesSelectOptions}
        optional={true}
      />

      <HorizontalRule />
      <EditInput
        id="PhoneNumber"
        placeholder="input.mobilenum.placeholder"
        value={userInfoState.PhoneNumber}
        labelText="input.mobilenum.text"
        ariaRequired={true}
        onChange={onUserInfoChangeHandler}
      />

      <InputValidation
        text="errors.notValidPhoneNumber"
        isHidden={isPhoneValidation(userInfoState.PhoneNumber)}
      />

      <HorizontalRule />
      <EditInput
        id="Email"
        placeholder="input.email.text"
        value={userInfoState.Email}
        labelText="input.email.text"
        ariaRequired={true}
        onChange={onUserInfoChangeHandler}
      />
      <InputValidation
        isHidden={
          !hasAtSymbolEmailValidation(userInfoState.Email) ||
          !hasTwoCharactersAfterAtSymbolEmailValidation(userInfoState.Email) ||
          isEmailValidation(userInfoState.Email)
        }
        text="errors.notValidEmail"
      />
      <InputValidation
        isHidden={hasAtSymbolEmailValidation(userInfoState.Email)}
        text="errors.missingAtSymbolEmail"
      />
      <InputValidation
        isHidden={
          !hasAtSymbolEmailValidation(userInfoState.Email) ||
          hasTwoCharactersAfterAtSymbolEmailValidation(userInfoState.Email)
        }
        text="errors.charactersAfterAtSymbolLessThanTwoEmail"
      />

      <HorizontalRule />
      <EditInput
        id="PassNumber"
        placeholder="input.passport.text"
        value={userInfoState.PassNumber}
        labelText="input.passport.text"
        ariaRequired={false}
        onChange={onUserInfoChangeHandler}
      />

      <InputValidation
        text="errors.notValidAustrianPassportOrIdentityCardNumberFormat"
        isHidden={isPassnumberValidation(userInfoState.PassNumber)}
      />

      <HorizontalRule />
      <EditInput
        id="IdNumber"
        value={userInfoState.IdNumber}
        onChange={onUserInfoChangeHandler}
        placeholder="input.personalIdCard.text"
        ariaRequired={false}
        labelText="input.personalIdCard.text"
        showValidationBorder={!isPersonalNumberValidation(userInfoState.IdNumber)}
      />

      <InputValidation
        isHidden={isPersonalNumberValidation(userInfoState.IdNumber)}
        text="errors.notValidPersonalNumber"
      />
      
      <>
        <ClearContactDiv
          text={`${t("terms.crisisContact")} (${contactPersonInfoState.FirstLastName ? contactPersonInfoState.FirstLastName : t("text.no_content")
            })`}
          onClick={clearContactPersonHandler}
        />
        {/* <HorizontalRule /> */}
        <EditInput
          id="NotfalkontaktFirstLastName"
          placeholder="input.vorNachname.text"
          value={contactPersonInfoState.FirstLastName}
          labelText="input.vorNachname.text"
          ariaRequired={true}
          onChange={onContactPersonInfoChangeHandler}
        />
        <InputValidation
          text={
            hasNumber(contactPersonInfoState.FirstLastName)
              ? "errors.nameHasNumber"
              : "errors.notValidContactPersonFirstAndLastName"
          }
          isHidden={
            isValidFirstAndLastName(contactPersonInfoState.FirstLastName) ||
            hasMiddleName(contactPersonInfoState.FirstLastName)
          }
        />
        <InputValidation
          isHidden={!hasMiddleName(contactPersonInfoState.FirstLastName)}
          text="errors.middleName"
          ariaLabel="errors.middleName"
        />
        <HorizontalRule />
        <EditInput
          id="NotfalkontaktPhoneNumber"
          placeholder="input.contact.placeholder"
          value={contactPersonInfoState.PhoneNumber}
          labelText="input.mobilenum.text"
          ariaRequired={true}
          onChange={onContactPersonInfoChangeHandler}
        />
        <InputValidation
          text="errors.notValidPhoneNumber"
          isHidden={
            isPhoneValidation(contactPersonInfoState.PhoneNumber, true) ||
            contactPersonInfoState.PhoneNumber.length === 0
          }
        />
        <HorizontalRule />
        <EditInput
          id="NotfalkontaktEmail"
          placeholder="input.email.text"
          value={contactPersonInfoState.Email}
          labelText="input.email.text"
          ariaRequired={false}
          onChange={onContactPersonInfoChangeHandler}
        />
        <InputValidation
          isHidden={
            contactPersonInfoState.Email.length === 0 ||
            !hasAtSymbolEmailValidation(contactPersonInfoState.Email) ||
            !hasTwoCharactersAfterAtSymbolEmailValidation(contactPersonInfoState.Email) ||
            isEmailValidation(contactPersonInfoState.Email)
          }
          text="errors.notValidEmail"
        />
        <InputValidation
          isHidden={
            contactPersonInfoState.Email.length === 0 || hasAtSymbolEmailValidation(contactPersonInfoState.Email)
          }
          text="errors.missingAtSymbolEmail"
        />
        <InputValidation
          isHidden={
            contactPersonInfoState.Email.length === 0 ||
            !hasAtSymbolEmailValidation(contactPersonInfoState.Email) ||
            hasTwoCharactersAfterAtSymbolEmailValidation(contactPersonInfoState.Email)
          }
          text="errors.charactersAfterAtSymbolLessThanTwoEmail"
        />
      </>
    </>
  );
};

export default EditProfileComponent;