import { useTranslation } from "react-i18next";

const ApiRequestErrorMessage = ({ text, style }) => {
  const { t } = useTranslation();

  return (
    <p style={style} className="request-error-message" role="alert">
      {t(text)}
    </p>
  );
};

export default ApiRequestErrorMessage;