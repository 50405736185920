import { useTranslation } from "react-i18next";
import ControledRadioInput from "../../registration/utils/ControledRadioInput.jsx";

const InfoMailForm = ({
  GDPRForeignAustrianOfficialInfo,
  setGDPRForeignAustrianOfficialInfo,
  GDPRForeignAustrianInofficialInfo,
  setGDPRForeignAustrianInofficialInfo
}) => {
  const { t } = useTranslation();

  const onGDPRForeignAustrianOfficialInfoChange = (e) => {
    setGDPRForeignAustrianOfficialInfo(e.target.value === t("input.jaNein.ja"));
  };
  const onGDPRForeignAustrianInofficialInfoChange = (e) => {
    setGDPRForeignAustrianInofficialInfo(e.target.value === t("input.jaNein.ja"));
  };

  return (
    <div style={{ margin: "1.6rem" }}>
      <div>
        <p>
          {t("pages.infoMailForm.mailInfo1")}
          <br />
          {t("pages.infoMailForm.mailInfo2")}
        </p>
        <ControledRadioInput
          role="radio"
          id="GDPRForeignAustrianOfficialInfo_yes"
          value={t("input.jaNein.ja")}
          name="gdprforeignaustrianofficialinfo"
          textInfo="input.jaNein.ja"
          ariaLabel=""
          checked={GDPRForeignAustrianOfficialInfo}
          onChange={onGDPRForeignAustrianOfficialInfoChange}
          ariaChecked={GDPRForeignAustrianOfficialInfo}
        />
        <ControledRadioInput
          role="radio"
          id="GDPRForeignAustrianOfficialInfo_no"
          value={t("input.jaNein.nein")}
          name="gdprforeignaustrianofficialinfo"
          textInfo="input.jaNein.nein"
          ariaLabel=""
          checked={GDPRForeignAustrianOfficialInfo != null && !GDPRForeignAustrianOfficialInfo}
          onChange={onGDPRForeignAustrianOfficialInfoChange}
          ariaChecked={!GDPRForeignAustrianOfficialInfo}
        />
      </div>
      <div style={{ marginTop: "2.4rem" }}>
        <p>{t("pages.infoMailForm.dsgvoInfo")}</p>
        <ControledRadioInput
          role="radio"
          id="GDPRForeignAustrianInofficialInfo_yes"
          value={t("input.jaNein.ja")}
          name="gdprforeignaustrianinofficialinfo"
          textInfo="input.jaNein.ja"
          checked={!!GDPRForeignAustrianInofficialInfo}
          onChange={onGDPRForeignAustrianInofficialInfoChange}
          ariaChecked={GDPRForeignAustrianInofficialInfo}
        />
        <ControledRadioInput
          role="radio"
          id="GDPRForeignAustrianInofficialInfo_no"
          value={t("input.jaNein.nein")}
          name="gdprforeignaustrianinofficialinfo"
          textInfo="input.jaNein.nein"
          checked={GDPRForeignAustrianInofficialInfo != null && !GDPRForeignAustrianInofficialInfo}
          onChange={onGDPRForeignAustrianInofficialInfoChange}
          ariaChecked={!GDPRForeignAustrianInofficialInfo}
        />
      </div>

      <p style={{ marginTop: "2.4rem" }}>{t("pages.infoMailForm.dsgvoInfo")}</p>
      <p style={{ marginTop: "1.2rem" }}>
        {t("pages.dsgvo.infoText")}{" "}
        <a
          href="https://www.bmeia.gv.at/reise-aufenthalt/auslandsservice/datenschutz"
          target="_blank"
          style={{ color: "black" }}
          aria-label={t("pages.dsgvo.title")}
          rel="noopener noreferrer"
        >
          {t("pages.dsgvo.title")}
        </a>
        .
      </p>
    </div>
  )
};

export default InfoMailForm;