import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import apipath from "../../apipath";
import EditInput from "../../common/EditInput";
import HiddenSubmitInput from "../../common/HiddenSubmitInput";
import HorizontalRule from "../../common/HorizontalRule";
import InputValidation from "../../common/InputValidaiton";
import MainButton from "../../common/MainButton";
import ApplicationRoute from "../../config/routes";
import HeaderContext from "../../context/HeaderContext";
import UserContext from "../../context/UserContext";
import fetcher from "../../services/fetcher";
import MetaDecorator from "../../utils/MetaDecorator";
import { dateFormatter, phoneFormatter, setApiUserObjectUpdate } from "../../utils/helpers";
import {
  IsDateBiggerThanTodayValidation,
  IsDateSmallerThan_1900_1_1_Validation,
  hasAtSymbolEmailValidation,
  hasMinLettersValidation,
  hasNumber,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isDateValidation,
  isEmailValidation,
  isOlderOrIsEighteenYearsOlds,
  isPhoneValidation,
} from "../../utils/validation";
import Form from "../registration/utils/Form";
import Input from "../registration/utils/Input";
import Loader from "../travel-registration/utils/Loader";

const PushLogin = () => {
  const history = useHistory();
  const { user, setUser } = useContext(UserContext);
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  const { ...userInfo } = user;

  if (!userInfo?.PhoneNumber) {
    userInfo.PhoneNumber = "";
  }

  const [userInfoState, setUserInfoState] = useState(userInfo);

  const [activated, setActivated] = useState({
    FirstName: false,
    LastName: false,
    BirthDate: false,
    PhoneNumber: false,
    Email: false,
  });

  const [, setUpdateProfile] = useState(null);
  const [updateProfileError, setUpdateProfileError] = useState(null);
  const [updateProfileLoading, setUpdateProfileLoading] = useState(true);
  const [submttingUpdateProfile, setSubmttingUpdateProfile] = useState(false);

  const updateUserMiddleware = () => {
    const toSend = {
      ...user,
      Email: userInfoState.Email,
      FirstName: userInfoState.FirstName,
      LastName: userInfoState.LastName,
      PhoneNumber: userInfoState.PhoneNumber,
      BirthDate: userInfoState.BirthDate,
    };

    fetcher(
      `${apipath.path}users/CURRENT`,
      "PUT",
      setUpdateProfile,
      setUpdateProfileError,
      setUpdateProfileLoading,
      setSubmttingUpdateProfile,
      false,
      "application/json",
      JSON.stringify(setApiUserObjectUpdate(toSend, t))
    );
  };

  ///new logic updating crisis status
  const [, setUpdatePush] = useState(null);
  const [updatePushError, setUpdatePushError] = useState(null);
  const [updatePushLoading, setUpdatePushLoading] = useState(true);
  const [submittingIpdatePush, submittingSetUpdatePush] = useState(false);

  const updateCrisis = () => {
    const crisisInfo = JSON.parse(localStorage.getItem("pushNotifcations"));

    fetcher(
      `${apipath.path}users/CURRENT/crisisstatus`,
      "POST",
      setUpdatePush,
      setUpdatePushError,
      setUpdatePushLoading,
      submittingSetUpdatePush,
      false,
      "application/json",
      JSON.stringify({
        CurrentLocation: crisisInfo.shareLocation ? `${crisisInfo.latitude}, ${crisisInfo.longitude}` : "-",
        IsActionNeeded: crisisInfo.needHelp,
        CrisisId: crisisInfo.crisisId,
      }),
      false
    );
  };
  ///

  //After user update
  useEffect(() => {
    if (!updateProfileLoading && !updateProfileError) {
      updateCrisis();
    }
  }, [updateProfileLoading]);

  //Updating crisis status
  useEffect(() => {
    if (!updatePushLoading && !updatePushError) {
      history.push(ApplicationRoute.secondPushStep);
    }
  }, [updatePushLoading]);

  //In case of error in updating crisis
  useEffect(() => {
    if (updatePushError && updatePushError.status !== 401 && updatePushError.status !== 403) {
      history.push(ApplicationRoute.errorPage);
    }
  }, [updatePushError]);

  const onUserInfoChangeHandler = (e) => {
    setActivated((curr) => {
      return { ...curr, [e.target.id]: true };
    });

    setUserInfoState((curr) => {
      if (e.target.id === "BirthDate") {
        return {
          ...curr,
          [e.target.id]: dateFormatter(userInfoState.BirthDate, e.target.value),
        };
      }

      if (e.target.id === "PhoneNumber") {
        return {
          ...curr,
          [e.target.id]: phoneFormatter(userInfoState.PhoneNumber, e.target.value),
        };
      }

      return { ...curr, [e.target.id]: e.target.value };
    });
  };

  const isValid = () => {
    try {
      return (
        validDate() &&
        hasTwoCharactersAfterAtSymbolEmailValidation(userInfoState.Email) &&
        hasMinLettersValidation(userInfoState.FirstName) &&
        hasMinLettersValidation(userInfoState.LastName) &&
        isEmailValidation(userInfoState.Email) &&
        isPhoneValidation(userInfoState.PhoneNumber)
      );
    } catch (error) {
      return false;
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    updateUser();

    updateUserMiddleware();
  };

  const validDate = () => {
    return (
      IsDateBiggerThanTodayValidation(userInfoState.BirthDate) &&
      isDateValidation(userInfoState.BirthDate) &&
      isOlderOrIsEighteenYearsOlds(userInfoState.BirthDate) &&
      !IsDateSmallerThan_1900_1_1_Validation(userInfoState.BirthDate)
    );
  };

  const updateUser = () => {
    if (isValid()) {
      //delete contact person on page witer button in case its deleted

      setUser((curr) => {
        return {
          ...curr,
          Email: userInfoState.Email,
          FirstName: userInfoState.FirstName,
          LastName: userInfoState.LastName,
          PhoneNumber: userInfoState.PhoneNumber,
          BirthDate: userInfoState.BirthDate,
        };
      });
    }
  };

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "noHeader",
      };
    });
  }, []);

  if (submttingUpdateProfile && updateProfileLoading) return <Loader />;
  if (updatePushLoading && submittingIpdatePush) return <Loader />;

  return (
    <div className="pushlogin-container">
      <MetaDecorator title="pageTitle.crisisWarningPage" />
      <div className="pushlogin-container__title">
        {t("pages.pushLogin.title")}
      </div>

      <div className="specialAlertContainer">
        <Form id="form" ariaLabel="pages.editProfile.edit" onSubmit={onSubmitHandler}>
          {userInfo.FirstName && <HorizontalRule />}
          {userInfo.FirstName ? (
            <EditInput
              id="FirstName"
              placeholder="input.vorname.text"
              value={userInfoState.FirstName}
              labelText="input.vorname.text"
              ariaRequired={true}
              onChange={onUserInfoChangeHandler}
              backGroundClassName="pushlogin-container__back-ground"
            />
          ) : (
            <Input
              id="FirstName"
              placeholder="input.vorname.text"
              value={userInfoState.FirstName}
              labelText="input.vorname.text"
              ariaRequired={true}
              onChange={onUserInfoChangeHandler}
              backGroundClassName="pushlogin-container__back-ground"
              labelStyle={{ backgroundColor: "#f0f4f5" }}
            />
          )}
          <InputValidation
            text={
              hasNumber(userInfoState.FirstName)
                ? "errors.nameHasNumber"
                : "errors.firstNameLessCharacters"
            }
            isHidden={!activated.FirstName || hasMinLettersValidation(userInfoState.FirstName)}
          />

          {userInfo.FirstName && <HorizontalRule className="pushlogin-container__hr" />}
          {userInfo.FirstName ? (
            <EditInput
              id="LastName"
              placeholder="input.nachname.text"
              value={userInfoState.LastName}
              labelText="input.nachname.text"
              ariaRequired={true}
              onChange={onUserInfoChangeHandler}
              backGroundClassName="pushlogin-container__back-ground"
            />
          ) : (
            <Input
              id="LastName"
              placeholder="input.nachname.text"
              value={userInfoState.LastName}
              labelText="input.nachname.text"
              ariaRequired={true}
              onChange={onUserInfoChangeHandler}
              backGroundClassName="pushlogin-container__back-ground"
              labelStyle={{ backgroundColor: "#f0f4f5" }}
            />
          )}
          <InputValidation
            text={
              hasNumber(userInfoState.LastName)
                ? "errors.nameHasNumber"
                : "errors.lastNameLessCharacters"
            }
            isHidden={!activated.LastName || hasMinLettersValidation(userInfoState.LastName)}
          />

          {userInfo.FirstName && <HorizontalRule className="pushlogin-container__hr" />}
          {userInfo.FirstName ? (
            <EditInput
              id="BirthDate"
              placeholder="input.date.birthdate"
              value={userInfoState.BirthDate}
              labelText="input.date.birthdate"
              ariaRequired={true}
              onChange={onUserInfoChangeHandler}
              backGroundClassName="pushlogin-container__back-ground"
            />
          ) : (
            <Input
              id="BirthDate"
              placeholder="input.date.birthdate"
              value={userInfoState.BirthDate}
              labelText="input.date.birthdate"
              ariaRequired={true}
              onChange={onUserInfoChangeHandler}
              backGroundClassName="pushlogin-container__back-ground"
              labelStyle={{ backgroundColor: "#f0f4f5" }}
            />
          )}
          <InputValidation
            text="errors.notValidDate"
            isHidden={!activated.BirthDate || isDateValidation(userInfoState.BirthDate)}
          />
          <InputValidation
            text="errors.ageLessThan_18"
            isHidden={
              !activated.BirthDate ||
              !IsDateBiggerThanTodayValidation(userInfoState.BirthDate) ||
              !isDateValidation(userInfoState.BirthDate) ||
              isOlderOrIsEighteenYearsOlds(userInfoState.BirthDate)
            }
          />
          <InputValidation
            text="errors.dateBiggerThanToday"
            isHidden={
              !activated.BirthDate ||
              !isDateValidation(userInfoState.BirthDate) ||
              IsDateBiggerThanTodayValidation(userInfoState.BirthDate)
            }
          />
          <InputValidation
            text="errors.dateOfBirthLessThan_01_01_1900"
            isHidden={
              !activated.BirthDate ||
              !isDateValidation(userInfoState.BirthDate) ||
              !IsDateSmallerThan_1900_1_1_Validation(userInfoState.BirthDate)
            }
          />

          {userInfo.FirstName && <HorizontalRule className="pushlogin-container__hr" />}
          {userInfo.FirstName ? (
            <EditInput
              id="PhoneNumber"
              placeholder="input.mobilenum.placeholder"
              value={userInfoState.PhoneNumber}
              labelText="input.mobilenum.text"
              ariaRequired={true}
              onChange={onUserInfoChangeHandler}
              backGroundClassName="pushlogin-container__back-ground"
            />
          ) : (
            <Input
              id="PhoneNumber"
              placeholder="input.mobilenum.placeholder"
              value={userInfoState.PhoneNumber}
              labelText="input.mobilenum.text"
              ariaRequired={true}
              onChange={onUserInfoChangeHandler}
              backGroundClassName="pushlogin-container__back-ground"
              labelStyle={{ backgroundColor: "#f0f4f5" }}
            />
          )}
          <InputValidation
            text="errors.notValidPhoneNumber"
            isHidden={!activated.PhoneNumber || isPhoneValidation(userInfoState.PhoneNumber)}
          />

          {userInfo.FirstName && <HorizontalRule className="pushlogin-container__hr" />}
          {userInfo.FirstName ? (
            <EditInput
              id="Email"
              placeholder="input.email.text"
              value={userInfoState.Email}
              labelText="input.email.text"
              ariaRequired={true}
              onChange={onUserInfoChangeHandler}
              backGroundClassName="pushlogin-container__back-ground"
            />
          ) : (
            <Input
              id="Email"
              placeholder="input.email.text"
              value={userInfoState.Email}
              labelText="input.email.text"
              ariaRequired={true}
              onChange={onUserInfoChangeHandler}
              backGroundClassName="pushlogin-container__back-ground"
              labelStyle={{ backgroundColor: "#f0f4f5" }}
            />
          )}
          <InputValidation
            isHidden={
              !activated.Email ||
              !hasAtSymbolEmailValidation(userInfoState.Email) ||
              !hasTwoCharactersAfterAtSymbolEmailValidation(userInfoState.Email) ||
              isEmailValidation(userInfoState.Email)
            }
            text="errors.notValidEmail"
          />
          <InputValidation
            isHidden={!activated.Email || hasAtSymbolEmailValidation(userInfoState.Email)}
            text="errors.missingAtSymbolEmail"
          />
          <InputValidation
            isHidden={
              !activated.Email ||
              !hasAtSymbolEmailValidation(userInfoState.Email) ||
              hasTwoCharactersAfterAtSymbolEmailValidation(userInfoState.Email)
            }
            text="errors.charactersAfterAtSymbolLessThanTwoEmail"
          />

          <HiddenSubmitInput id="hiddenSubmitButton" />
          <div className="pushlogin-margin-fix" />

          <MainButton path="./" ariaLabel="terms.weiter" linkId="" positioned="static" disabled={!isValid()} />
        </Form>
      </div>
    </div>
  );
};

export default PushLogin;