import { useTranslation } from "react-i18next";

function CountryInfoHeader({
  className,
  Country,
  RiskLvl,
  flag,
  countryImage,
}) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="edit-page__title-image">
        <div>
          <img
            alt=""
            src={countryImage}
            onError={(ev) => {
              ev.currentTarget.parentElement.style.paddingTop = "4rem";
              ev.currentTarget.remove();
            }}
          />
        </div>
        <img alt="" className={className} src={flag} onError={(ev) => ev.currentTarget.remove()} />
        <div className="card-header-body">
          <h2 className="lead__text-left">{t(Country)}</h2>

          <p className="text-left">{t(RiskLvl)}</p>
        </div>
      </div>
    </div>
  );
}

export default CountryInfoHeader;