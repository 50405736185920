import React from "react";
import useCleanTranslation from "../DotWebsite/Hooks/UseCleanTranslation";

const AnswerDenyEmergencyContact = () => {
    const { ct } = useCleanTranslation("");

    return (
        <>
            <p className="answer-text" dangerouslySetInnerHTML={{ __html: ct("pages.faq.answerDenyEmergencyContact") }}></p>
        </>
    );
};

export default AnswerDenyEmergencyContact;
