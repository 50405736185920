import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import apipath from "../../../apipath";
import HeaderContext from "../../../context/HeaderContext";
import { replaceAll_rgx } from "../../../utils/helpers";
import LoadingPage from "../../travel-registration/LoadingPage";

function ImpresumInfos() {
  const { setHeader } = useContext(HeaderContext);
  const [content, setContent] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: t("pages.informationen.navigationTitle.impressum"),
      };
    });

    fetch(`${apipath.pathOrig}app-inhalte/impressum-app/type/json/`, { method: "GET" })
      .then((data) => data.json())
      .then((data) => setContent(data.data));
  }, []);

  if (content.length === 0) {
    return <LoadingPage />;
  }

  return (
    <div className="content-pages-container">
      {content.map((headerBody) => (
        <>
          <div className="content-pages-heading" dangerouslySetInnerHTML={{ __html: t(headerBody.header) }} />
          <div
            className="content-pages-paragraph"
            dangerouslySetInnerHTML={{ __html: t(replaceAll_rgx(t(headerBody.bodytext))) }}
          />
        </>
      ))}
    </div>
  );
}

export default ImpresumInfos;