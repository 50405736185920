import MainHeaderDesktop from "./MainHeaderDesktop";
import RegistrationSubHeader from "./RegistrationSubHeader.jsx";

const DseHeader = ({ title, currentProgress, onBackButtonClickHandler, ariaLabel }) => {
  return (
    <div id="registration-header-id" className="mobile-sticky">
      <MainHeaderDesktop />
      <RegistrationSubHeader title={title} currentProgress={currentProgress} onBackButtonClickHandler={onBackButtonClickHandler} ariaLabel={ariaLabel} />
    </div>
  );
};

export default DseHeader;