// Options to set for the button
//
//positioned => fixed/static
//color => red/transperant => setting the background and color of the button

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const WeiterButton = ({
  disabled,
  path,
  ariaLabel = "",
  onClick,
  linkId = "linkId",
  text = "terms.weiter",
  ariaLabelledBy,
  positioned = "fixed",
  color = "red",
  style,
  className = "",
}) => {
  const { t } = useTranslation();

  const onKeyPressHandler = (e) => {
    if (disabled) {
      e.preventDefault();
    }
  };

  return (
    <div
      className={`weiter-button-wrapper ${positioned === "fixed" ? "weiter-button-wrapper--fixed" : "weiter-button-wrapper--static"}`}
      onKeyDown={onKeyPressHandler}
      aria-disabled={disabled}
    >
      <div className={`weiter-button-container ${className}`}>
        <Link
          role="button"
          id={linkId}
          to={path}
          onClick={onClick}
          style={disabled ? { pointerEvents: "none" } : { pointerEvents: "auto" }}
          className={`weiter-button ${color === "transperant"
              ? "weiter-button--transperant"
              : disabled
                ? "weiter-button--red--disabled"
                : "weiter-button--red--enabled"
            }`}
          aria-label={t(ariaLabel)}
          aria-labelledby={ariaLabelledBy}
          aria-disabled={disabled}
        >
          {t(text)}
        </Link>
      </div>
      {(positioned === "fixed" || positioned === "absolute") && <div className="button-space-on-keyboard" />}
    </div>
  );
};

export default WeiterButton;