import { useTranslation } from "react-i18next";

const Form = ({
  id,
  children,
  onSubmit,
  ariaLabel,
  ariaLabelledBy,
  className,
  tabIndex,
}) => {
  const { t } = useTranslation();

  return (
    <form
      role="group"
      id={id}
      onSubmit={onSubmit}
      aria-label={t(ariaLabel)}
      aria-labelledby={ariaLabelledBy}
      className={className}
      tabIndex={tabIndex}
    >
      {children}
    </form>
  );
};

export default Form;