import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import apipath from "../../apipath";
import ApplicationRoute from "../../config/routes";
import HeaderContext from "../../context/HeaderContext";
import PushContext from "../../context/PushContext";
import SuccessContext from "../../context/SuccessContext";
import fetcher from "../../services/fetcher";
import Cross from "../../svg/Cross";
import MetaDecorator from "../../utils/MetaDecorator";
import { getDistance, getUIID, isEmptyObject, toShowRepresentation } from "../../utils/helpers";
import Loader from "../travel-registration/utils/Loader";
import {
  getCountryNameFromId,
  getCountryUidFromName,
  getIsoCodeFromName,
} from "../travel-registration/utils/countryInfo";
import FirstPushStepComponent from "./utils/1-push-step-component";
import SecondPushStepComponent from "./utils/2-push-step-component";

const initChoices = { needHelp: false, noHelp: false };

const initCard = {
  country: "",
  img: "",
  phones: [""],
  address: "",
  flag: "",
};

const PushEditFromDashBoard = () => {
  const { setHeader } = useContext(HeaderContext);
  const { pushNotifcations, setPushNotifications } = useContext(PushContext);
  const { setSuccessBox } = useContext(SuccessContext);
  const { t } = useTranslation();

  if (isEmptyObject(pushNotifcations) || !pushNotifcations) {
    try {
      const psLs = localStorage.getItem(pushNotifcations);
      setPushNotifications(() => psLs);
    } catch (error) { }
  }

  useEffect(() => {
    setChoices((curr) => {
      return {
        ...curr,
        needHelp: pushNotifcations.needHelp,
        noHelp: !pushNotifcations.needHelp,
      };
    });
  }, [pushNotifcations]);

  const [location, setLocation] = useState({ long: 0, lat: 0 });
  const [showCrisisCard, setShowCrisisCard] = useState(false);

  const [card, setCard] = useState(initCard);

  let initChecked;
  if (!pushNotifcations.shareLocation || pushNotifcations.shareLocation === "-") {
    initChecked = false;
  } else {
    initChecked = true;
  }

  const [checked, setChecked] = useState(initChecked);
  const [choices, setChoices] = useState({
    needHelp: pushNotifcations.needHelp,
    noHelp: !pushNotifcations.needHelp,
  });

  const [repLocation, setRepLocation] = useState({ long: "", lat: "" });

  const [serverChoices] = useState({
    needHelp: pushNotifcations.needHelp,
    noHelp: !pushNotifcations.needHelp,
    shareLocation: initChecked,
  });
  const history = useHistory();

  const [, setUpdatePush] = useState(null);
  const [updatePushError, setUpdatePushError] = useState(null);
  const [updatePushLoading, setUpdatePushLoading] = useState(true);
  const [submittingIpdatePush, submittingSetUpdatePush] = useState(false);

  const updateCrisis = () => {
    fetcher(
      `${apipath.path}users/CURRENT/crisisstatus/${pushNotifcations.editId}`,
      "PUT",
      setUpdatePush,
      setUpdatePushError,
      setUpdatePushLoading,
      submittingSetUpdatePush,
      false,
      "application/json",
      JSON.stringify({
        CurrentLocation: checked ? `${location.lat}, ${location.long}` : "-",
        IsActionNeeded: choices.needHelp,
      })
    );
  };

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "noHeader",
      };
    });

    enableLocation();
  }, []);

  const onChoiceHandler = (e) => {
    setChoices(() => {
      return { ...initChoices, [e.target.id]: true };
    });
  };

  const onCheckedHandler = () => {
    setChecked((curr) => !curr);
  };

  const isValid = () => {
    const sameNeedHelp = serverChoices.needHelp === choices.needHelp;
    const sameShareLocation = !!serverChoices.shareLocation === checked;

    return !(sameNeedHelp && sameShareLocation);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    updateCrisis();
  };

  //after update
  useEffect(() => {
    if (!updatePushLoading && !updatePushError) {
      setSuccessBox((curr) => {
        return {
          ...curr,
          hidden: false,
          text: t("pages.pushEditFromDash.success"),
          trigger: getUIID(),
        };
      });

      history.goBack();
    }
  }, [updatePushLoading]);

  //setting location
  function enableLocation() {
    if (navigator.geolocation) navigator.geolocation.getCurrentPosition(handleGetCurrentPosition, onError);
  }

  function handleGetCurrentPosition(location) {
    setLocation((curr) => {
      return {
        ...curr,
        long: location.coords.longitude,
        lat: location.coords.latitude,
      };
    });
  }

  function onError() { }

  useEffect(() => {
    if (!!checked) {
      enableLocation();
    }
  }, [checked]);

  useEffect(() => {
    let countries = JSON.parse(localStorage.getItem("countriesVB")).countries;

    let isoCODE = getIsoCodeFromName(getCountryNameFromId(pushNotifcations.countryId));

    let country = countries.filter((c) => c.countryIsoCode === isoCODE)[0];

    let countryUID = getCountryUidFromName(getCountryNameFromId(pushNotifcations.countryId));

    //No country in bmeia.gv.at
    if (!country) {
      return;
    }

    let flag = `${apipath.pathBMEIAgvat_API}flags/${isoCODE.toLowerCase()}.png`;

    let img = `${apipath.pathBMEIAgvat_API}country/${countryUID}.jpg`;

    let address = "";
    let type = "";
    let city = "";
    //USER LOCATION
    let userLat = location.lat;
    let userLong = location.long;

    let numbers = [];

    country.representations = country.representations.filter((r) => toShowRepresentation(r.type));

    if (country.representations.length > 0) {
      setShowCrisisCard(true);

      if (country.representations.length === 1) {
        setRepLocation((curr) => {
          return {
            ...curr,
            long: country.representations[0].longitude,
            lat: country.representations[0].latitude,
          };
        });

        //numbers
        if (country.representations[0].phone.length > 0) {
          country.representations[0].phone.forEach((obj) => {
            numbers.push(obj.number);
          });
        }
        if (country.representations[0].mobile.length > 0) {
          country.representations[0].mobile.forEach((obj) => {
            numbers.push(obj.number);
          });
        }
        address = country.representations[0].address;
        type = country.representations[0].type;
        city = country.representations[0].city;
      }
      //More than one representation => finding nearest
      else if (country.representations.length > 1) {
        let distances = [];
        for (let index = 0; index < country.representations.length; index++) {
          let latitudeRepre = country.representations[index].latitude;
          let longitudeRepre = country.representations[index].longitude;

          if (!latitudeRepre || !longitudeRepre) {
            latitudeRepre = 99999999;
            longitudeRepre = 99999999;
          }

          distances.push(getDistance(userLat, userLong, latitudeRepre, longitudeRepre, index));
        }

        const minDistanceArrayIndex = distances.reduce((prev, curr) =>
          prev.distance < curr.distance ? prev : curr
        ).arrayIndex;

        if (country.representations[minDistanceArrayIndex].phone.length > 0) {
          country.representations[minDistanceArrayIndex].phone.forEach((obj) => {
            numbers.push(obj.number);
          });
        }

        if (country.representations[minDistanceArrayIndex].mobile.length > 0) {
          country.representations[minDistanceArrayIndex].mobile.forEach((obj) => {
            numbers.push(obj.number);
          });
        }

        address = country.representations[minDistanceArrayIndex].address;
        type = country.representations[minDistanceArrayIndex].type;
        city = country.representations[minDistanceArrayIndex].city;

        setRepLocation((curr) => {
          return {
            ...curr,
            long: country.representations[minDistanceArrayIndex].longitude,
            lat: country.representations[minDistanceArrayIndex].latitude,
          };
        });
      }
    }

    setCard((curr) => {
      return {
        ...curr,
        country: city,
        flag: flag,
        address: address,
        phones: numbers,
        img: img,
        type: type,
      };
    });
  }, [pushNotifcations, location]);

  const onCloseHandler = () => {
    history.push(ApplicationRoute.TravelDashboardNoPermissions);
  };

  if (submittingIpdatePush && updatePushLoading) return <Loader />;

  return (
    <div className="push-edit-container">
      <MetaDecorator title="pageTitle.crisisWarningPage" />
      <Cross onClick={onCloseHandler} className="push-edit-container__cross" color="black" />

      <FirstPushStepComponent
        onSubmit={onSubmit}
        onChoiceHandler={onChoiceHandler}
        choices={choices}
        onCheckedHandler={onCheckedHandler}
        checked={checked}
        isValid={isValid}
        weiterText="pages.pushEditFromDash.weiter"
        showTopPart={false}
        className="push-edit-container__first-container"
        setChecked={setChecked}
      />

      <hr className="push-edit-container__hr" />

      <SecondPushStepComponent
        needHelp={pushNotifcations.needHelp}
        crisisCardObj={card}
        showTopPart={false}
        showBottomText={false}
        className="push-edit-container__second-container"
        showCrisisCard={showCrisisCard}
        longitude={repLocation.long}
        latitude={repLocation.lat}
      />
    </div>
  );
};

export default PushEditFromDashBoard;