import { useTranslation } from "react-i18next";

const StopsSeparater = ({ id = "stopsSeparaterClear", text, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="stops-separater-container">
      <h2>
        <div className="stops-separater-container__title">{t(text)}</div>
      </h2>
      <button id={id} type="button" className="stops-separater-container__clear-link" onClick={onClick}>
        {t("terms.delete")}
      </button>
    </div>
  );
};

export default StopsSeparater;