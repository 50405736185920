import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import apipath from "../../../../apipath";
import HeaderContext from "../../../../context/HeaderContext";
import MetaDecorator from "../../../../utils/MetaDecorator";

const NotfalltippView = (props) => {
  const { t } = useTranslation();
  const { setHeader } = useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: t(props.title),
      };
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const [data, setData] = useState([]);
  const fetchData = async () => {
    const response = await fetch(
      `${apipath.pathOrig}app-inhalte/tipp-${props.tippId}/type/json/`,
      { method: "GET" },
      true
    );
    const json = await response.json();
    setData(json.data);
  };

  return (
    <>
      <MetaDecorator title={props.title} />
      <div className="content-pages-container">
        {data?.map((info, index) => {
          return (
            <div
              key={index}
              dangerouslySetInnerHTML={{ __html: t(info.bodytext) }}
              className="content-pages-paragraph"
            />
          );
        })}
      </div>
    </>
  );
};

export default NotfalltippView;