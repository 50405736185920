export const httpDemoResponses = {
    code: {
        login: {
            pass: () => Promise.resolve(true),
            fail: () => {
                throw Error();
            },
        },
    },
    auth: {
        verify: {
            pass: () => Promise.resolve(true),
            fail: () => {
                throw Error();
            },
        },
        info: {
            data: {
                firstName: "Max",
                lastName: "Mustermann",
                birthdate: "2000-04-29",
            },
        },
    },
    documents: {
        data: [
            {
                id: 1,
                translationKey: "document.birthCertificate",
                docGroupId: 1,
                docInfoKey: "document.group1.info",
                dependsOn: [
                    {
                        id: 1,
                        docGroupTransKey: "document.group1.required.1",
                        docGroupId: 1,
                        docGroupInfoTransKey: "document.group1.required.1.description",
                        required: true
                    },
                    {
                        id: 2,
                        docGroupId: 2,
                        docGroupTransKey: "document.group1.required.2",
                        docGroupInfoTransKey: "document.group1.required.2.description",
                        required: false
                    }
                ]
            },
            {
                id: 2,
                translationKey: "document.marriageCertificate",
                docGroupId: 1,
                docInfoKey: "document.group2.info",
                dependsOn: [{
                    id: 1,
                    docGroupId: 1,
                    docGroupTransKey: "document.group1.required.1",
                    docGroupInfoTransKey: "document.group1.required.1.description",
                    required: true
                },
                {
                    id: 2,
                    docGroupId: 2,
                    docGroupTransKey: "document.group1.required.2",
                    docGroupInfoTransKey: "document.group1.required.2.description",
                    required: false
                }
                ],
            },
        ],
    },
    locations: {
        countries: {
            data: ["Frankreich", "Deutschland"],
        },
        regions: {
            Frankreich: {
                data: [
                    {
                        name: "Occitanie",
                        embassy: "Embassy 1",
                    },
                    {
                        name: "Normandy",
                        embassy: "Embassy 2",
                    },
                ],
            },
            Deutschland: {
                data: [
                    {
                        name: "Berlin",
                        embassy: "Embassy 3",
                    },
                    {
                        name: "Hamburg",
                        embassy: "Embassy 4",
                    },
                ],
            },
        } as Record<string, any>,
    },
    langauges: {
        data: [
            {
                language: "de",
            },
            {
                language: "en",
            },
        ],
    },
};

export const asyncResponse = async <T>(value: any): Promise<T> => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(value);
        }, 1000);
    });
};
