import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import WeiterButton from "../components/registration/utils/WeiterButton";
import ApplicationRoute from "../config/routes";
import HeaderContext from "../context/HeaderContext";
import MetaDecorator from "../utils/MetaDecorator";
import SadFace from "./sadFace";

const ErrorPage = () => {
  const { t } = useTranslation();
  const { setHeader } = useContext(HeaderContext);

  const history = useHistory();
  const onClickHandler = (e) => {
    e.preventDefault();
    history.push(ApplicationRoute.home);
  };

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "main",
        title: t("pages.login.title"),
        description: t("pages.login.description"),
      };
    });
  }, []);

  return (
    <div className="page-not-found registration-middle-container">
      <MetaDecorator title="pages.eTokenInvalid.header" />
      <div className="page-not-found__content-wrapper">
        <SadFace />
        <h1 className="page-not-found__header">{t("pages.eTokenInvalid.header")}</h1>
        <p className="page-not-found__p">{t("pages.eTokenInvalid.text")}</p>
      </div>

      <WeiterButton
        disabled={false}
        path={ApplicationRoute.home}
        ariaLabel={`${t("pages.contactPerson.weiter")} ${ApplicationRoute.home}`}
        text="pages.error.toHome"
        style={{ marginBottom: "0.6rem" }}
        onClick={onClickHandler}
        positioned="fixed"
      />
    </div>
  );
};

export default ErrorPage;