import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import defaultUserValues from "../../../config/defaultUserValues";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import LoggedContext from "../../../context/LoggedContext";
import SuccessContext from "../../../context/SuccessContext";
import UserContext from "../../../context/UserContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import {
  areIdaRoutesEnabled,
  clearLocalStorageOnLogOut,
  getUIID,
  isMobile,
  isMobileEnv,
} from "../../../utils/helpers";
import {
  getCountries__with__loader,
  getVB__with__loader,
} from "../../travel-registration/utils/GetCountries";
import GlobeIcon from "../../travel-registration/utils/GlobeIcon";
import Loader from "../../travel-registration/utils/Loader";
import ContentPageTeaser from "../utils/ContentPageTeaser";
import LoginTemplate from "../utils/LoginTemplate.jsx";
import WeiterButton from "../utils/WeiterButton";
import { initLoginCred, login, loginUser } from "../utils/loginHelper.js";

const LoginSignInPage = ({ clearSearchInput }) => {
  const { t } = useTranslation();
  clearLocalStorageOnLogOut();
  const isDotEnabled = process.env.REACT_APP_DOT_ENABLED === 'true';

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();

  const { setHeader } = useContext(HeaderContext);
  const { setUser } = useContext(UserContext);
  const { setIsLogged } = useContext(LoggedContext);

  const [loginData, setLoginData] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const [loginLoading, setLoginLoading] = useState(true);
  const [submittingLogin, setSubmittingLogin] = useState(false);

  const [disableButton, setDisableButton] = useState(false);

  const [vbLoader, setVBLoader] = useState(false);

  const [countryDataLoaded, setCountryDataLoaded] = useState(false);
  const { setSuccessBox } = useContext(SuccessContext);

  const [noLoginCred, setNoLoginCred] = useState(initLoginCred);

  // Laded die verschiedenen Länder in den LocalStorage damit sie bei der Reiseerstellung vorhanden sind

  useEffect(() => {
    getCountries__with__loader(setCountryDataLoaded, t);
    getVB__with__loader(setVBLoader);
  }, [t]);

  const submittedLoginWithNoErrors = () => {
    return !loginLoading && !loginError;
  };

  useEffect(() => {
    if (!loginLoading) {
      if (submittedLoginWithNoErrors()) {
        setDisableButton(true);

        loginUser(loginData, setUser, setIsLogged, history, t);
      }
    }
  }, [loginData, loginError, loginLoading, t]);

  const onClickRegisterHandler = () => {
    localStorage.removeItem("registerUser");

    setUser(() => defaultUserValues);
  };

  const onClickLoginHandler = (e) => {
    e.preventDefault();

    history.push(ApplicationRoute.loginPage)
  };

  const onLoginHandler = (e) => {
    e.preventDefault();
    login(email, password, setNoLoginCred, setLoginData, setLoginError, setLoginLoading, setSubmittingLogin);
  };

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "main",
        title: t("pages.login.title"),
        description: t("pages.login.description"),
      };
    });

    checkLoggedOut();
  }, []);

  const checkLoggedOut = () => {
    let loggedOut = localStorage.getItem("autoLoggedOut");
    if (loggedOut) {
      localStorage.setItem("autoLoggedOut", "");

      setSuccessBox((curr) => {
        return {
          ...curr,
          hidden: false,
          text: t("pages.login.logoutSuccess"),
          ariaLabel: t("pages.login.logoutSuccess"),
          trigger: getUIID(),
        };
      });
    }
  };

  if (!vbLoader) return <Loader />;
  if (!countryDataLoaded) return <Loader />;

  return (
    <div className="loginsignin__container content-container">
      <MetaDecorator title="pageTitle.registrationPages" />
      {isMobile() &&
        <h1 className="desktop-hidden login-title">{t("pages.home.title")}</h1>
      }

      <div className="half-col">
        <div className="loginsignin-dashboard__globe-icon ">
          <GlobeIcon />
        </div>
        <p
          role="heading" aria-level="2"
          className="desktop-hidden travel-dashboard__subtitle travel-dashboard__subtitle--no-travel-item m-b-xl"
        >
          {t("pages.home.subtitle")}
        </p>
      </div>
      <div className="half-col max-width-380 m-b-xxl-2">
        {isMobileEnv() ? (
          <>
            <div className="desktop-hidden">
              <ContentPageTeaser
                onClick={(e) => {
                  e.preventDefault();
                  clearSearchInput();
                  window.scrollTo(0, document.body.scrollHeight);
                }}
                type="register"
              />
              <ContentPageTeaser
                onClick={() => {
                  clearSearchInput();
                }}
                type="countryInfo"
              />
              <ContentPageTeaser
                onClick={() => {
                  clearSearchInput();
                }}
                type="proxy"
              />
              <ContentPageTeaser
                onClick={() => {
                  clearSearchInput();
                }}
                type="emergencyTips"
              />
              {isDotEnabled &&
                <ContentPageTeaser
                  onClick={() => { clearSearchInput(); }}
                  type="orderDocument"
                />
              }
            </div>
            <h2 id="contentTitle" className="desktop-hidden login-title m-t-xl m-b-l">
              {t("pages.login.contentTitleMobile")}
            </h2>
          </>
        ) : (
          <h1 id="contentTitle" className="mobile-hidden login-title">
            {t("pages.login.contentTitleDesktop")}
          </h1>
        )}
        {areIdaRoutesEnabled() ?
          <WeiterButton
            disabled={false}
            path={ApplicationRoute.loginPage}
            ariaLabel="pages.login.loginEmail"
            text="pages.login.loginEmail"
            linkId="emailLoginLinkId"
            positioned="static"
            onClick={onClickLoginHandler}
            style={{ marginBottom: "4rem" }}
            color="transperant"
          />
          :
          <LoginTemplate onSubmitHandler={onLoginHandler} noLoginCred={noLoginCred}
            email={email} setEmail={setEmail}
            password={password} setPassword={setPassword}
            error={loginError} loading={loginLoading} submitting={submittingLogin}
            disableButton={disableButton} formLabel="pages.login.formLabel"
            background="rgb(240, 244, 245)" />
        }

        <div className="oder__container">
          <span className="oder__container__content">{t("terms.oder")}</span>
        </div>

        <WeiterButton
          disabled={false}
          path={ApplicationRoute.registerCitizenship}
          ariaLabel="text.neu_registrieren"
          text="text.neu_registrieren"
          linkId="registerLinkId"
          positioned="static"
          icon="register"
          onClick={onClickRegisterHandler}
          style={{ marginBottom: "0px" }}
          id="anmelden"
          color={areIdaRoutesEnabled() ? "transperant" : "red"}
        />

        <a
          href="https://www.bmeia.gv.at/reise-aufenthalt/auslandsservice/datenschutz"
          target="_blank"
          style={{ textDecoration: "none", display: "block" }}
          aria-label={t("pages.dsgvo.title2")}
          rel="noopener noreferrer"
        >
          <p className="centered-link__container">
            <span className="centered-link__container__link centered-link__container__link--red">
              {t("pages.dsgvo.title2")}
            </span>
          </p>
        </a>
      </div>
    </div>
  );
};

export default LoginSignInPage;