import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const passwordIcons = {
  showed: "url(./images/common/passwordeye.png)",
  hidden: "url(./images/common/passwordeyeoff.png)",
};

const togglePassword = (type, id) => {
  if (type === "password") {
    try {
      const input = document.getElementById(id);

      const showPasswordEl = document.getElementById(id + "-passwordToggle");

      showPasswordEl.addEventListener("click", () => {
        if (input.type === "text") {
          input.type = "password";
          showPasswordEl.style.backgroundImage = passwordIcons.showed;
        } else {
          input.type = "text";
          showPasswordEl.style.backgroundImage = passwordIcons.hidden;
        }
      });
    } catch { }
  }
};

const Input = forwardRef(
  (
    {
      id,
      name,
      value,
      onChange,
      onClick,
      placeholder,
      type = "text",
      labelText,
      ariaRequired = true,
      inputStyle,
      labelStyle,
      containerStyle,
      showValidationBorder,
      error = false,
      readonly = false,
      ariaDescribedBy,
    },
    ref
  ) => {
    const staticLabelStyle = { display: value ? "inline-block" : "" };
    const [labelStyleState, setLabelStyleState] = useState(staticLabelStyle);
    const [passwordShown, setPasswordShow] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
      togglePassword(type, id);
    }, [id]);

    useEffect(() => {
      setLabelStyleState(() => {
        if (!!labelStyle) {
          return { display: value ? "inline-block" : "", ...labelStyle };
        }
        return { display: value ? "inline-block" : "" };
      });
    }, [value]);

    const onClickShowPasswordHandler = () => {
      setPasswordShow((curr) => !curr);
    };

    const componentOnClickHandler = (e) => {
      e.stopPropagation();

      const inputs = document.getElementsByTagName("input");
      try {
        for (const input of inputs) {
          if (input.id === "hiddenSubmit") {
            continue;
          }

          if (input.id === id) {
            input.classList.add("customable-input");
            input.previousSibling.classList.add("customable-label");

            input.click();
            input.focus();
          } else {
            input.classList.remove("customable-input");
            input.previousSibling.classList.remove("customable-label");
          }
        }
      } catch (error) { }
    };

    return (
      <div
        style={containerStyle}
        className={`custom-input__container ${error ? "custom-input__container--error" : ""}`}
        onClick={componentOnClickHandler}
      //onTouchStart={componentOnClickHandler}
      >
        <label
          style={labelStyleState}
          className={`custom-input__container__custom-label custom-input__container__custom-label--active ${showValidationBorder ? "custom-input__container__custom-label--active--red" : ""
            }`}
          htmlFor={id}
        >
          {t(labelText)}
        </label>

        <input
          name={name}
          type={type}
          id={id}
          className={`custom-input__container__custom-input ${showValidationBorder ? "custom-input__container__custom-input--red" : ""
            }`}
          value={value}
          onChange={onChange}
          onClick={onClick}
          placeholder={t(placeholder)}
          aria-required={ariaRequired}
          aria-describedby={ariaDescribedBy}
          style={inputStyle}
          readOnly={readonly}
          ref={ref}
        />
        {type === "password" &&
          <button
            type="button"
            onClick={onClickShowPasswordHandler}
            style={{ backgroundImage: passwordIcons.showed }}
            className="custom-input__container__show-password-ico"
            id={`${id}-passwordToggle`}
            aria-label={t(passwordShown ? "text.passwort_verbergen" : "text.passwort_anzeigen")}
          />
        }
      </div>
    );
  }
);

export default Input;