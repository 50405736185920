import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import userSuccess from "../../../../assets/icons/user-added-success.PNG";
import HorizontalRule from "../../../../common/HorizontalRule";
import ApplicationRoute from "../../../../config/routes";
import HeaderContext from "../../../../context/HeaderContext";
import TravelContext from "../../../../context/TravelContext";
import MetaDecorator from "../../../../utils/MetaDecorator";
import PageTitle from "../../../travel-registration/utils/PageTitle";
import WeiterButton from "../../../travel-registration/utils/WeiterButton";

export function AddRelativesSuccess() {
  const { setHeader } = useContext(HeaderContext);
  const [name, setName] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t("pageTitle.foreignRegistration"),
        ariaLabel: t("pageTitle.foreignRegistration"),
        progress: parseInt((16 / 17) * 100),
      };
    });

    const index =
      travel.TravelGroupMember.length > 0
        ? travel.TravelGroupMember.length - 1
        : 0;

    setName(travel.TravelGroupMember[index].FirstLastName);
  }, []);

  const { travel, setTravel } = useContext(TravelContext);

  const updateTravel = () => {
    // Logik
  };

  const onClickHandler = () => {
    updateTravel();
  };

  const defValue = {
    Birthdate: "",
    DegreeOfRelationship: "",
    Email: "",
    FirstLastName: "",
    Gender: "",
    Id: "",
    MobilePhone: "",
    Name: "",
    Nationality: "",
  };

  const addNewTravelGroupMember = () => {
    let travelCopy = Object.assign({}, travel);
    travelCopy.TravelGroupMember.push(Object.assign({}, defValue));

    setTravel(travelCopy);
  };

  return (
    <section className="foreign-registration registration-middle-container relative-success-page">
      <MetaDecorator title="pageTitle.foreignRegistration" />
      <PageTitle
        text={`${name} ${t("input.relatives.success")}`}
        ariaLabel={`${name} ${t("input.relatives.success")}`}
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <div
        role="img"
        aria-label=""
        className="picture__container picture__container--mtb-60"
      >
        <img alt="user-success" aria-hidden={true} src={userSuccess} />
      </div>
      <HorizontalRule className="horizontal-rule-desktop" />

      <WeiterButton
        disabled={false}
        path={ApplicationRoute.foreignRegistrationAddRelativesName}
        ariaLabel="pages.relSuccess.text"
        text="pages.relSuccess.text"
        positioned="fixed"
        color="transperant"
        onClick={addNewTravelGroupMember}
      />

      <WeiterButton
        disabled={false}
        path={ApplicationRoute.foreignRegistrationEditPage}
        onClick={onClickHandler}
        style={{ marginBottom: "97px" }}
        positioned="fixed"
      />
    </section>
  );
}