import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import UserContext from "../../../context/UserContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import { GetBundeslandIdFromName } from "../../../utils/helpers";
import CustomSelectList from "../../foreign-registration/utils/CustomSelectList";
import ContentTitle from "../utils/ContentTitle";
import Form from "../utils/Form";
import WeiterButton from "../utils/WeiterButton";

const countriesSelectOptions = [
  "input.bundesland.options.burgenland",
  "input.bundesland.options.kaernten",
  "input.bundesland.options.niederoesterreich",
  "input.bundesland.options.oberoesterreich",
  "input.bundesland.options.salzburg",
  "input.bundesland.options.steiermark",
  "input.bundesland.options.tirol",
  "input.bundesland.options.vorarlberg",
  "input.bundesland.options.wien",
];

//HINT* NOT REGISTRATION
const LivingInPage = () => {
  const history = useHistory();
  const { t } = useTranslation();

  let init = "";
  try {
    const ls = JSON.parse(localStorage.getItem("registerUser"));
    init = ls.CountyHomeRegionName;
  } catch (error) { }

  const [livingIn, setLivingIn] = useState(init);

  const { setUser } = useContext(UserContext);
  const { setHeader } = useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((4 / 19) * 100),
        title: t("pageTitle.createTripPages"),
      };
    });
  }, []);

  const isValid = () => {
    // OriginalLogik --> return !!livingIn;
    return true;
  };

  const updateUser = () => {
    setUser((curr) => {
      return {
        ...curr,
        CountyHomeRegionName: livingIn,
        CountyHomeRegionId: GetBundeslandIdFromName(livingIn, t),
      };
    });
  };

  const onChangeHandler = (value) => {
    setLivingIn(value);
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history.push(ApplicationRoute.registerGender);
    }
  };

  return (
    <>
      <MetaDecorator title="pageTitle.createTripPages" />
      <div className="registration-middle-container">
        <ContentTitle id="contentTitle" text="pages.livingIn.contentTitle" />
        <p id="helptext-bundesland">{t("pages.livingIn.helptext")}</p>
        <HorizontalRule className="horizontal-rule-desktop" />
        <Form className="max-width-380" id="form" onSubmit={onSubmitHandler} ariaLabelledBy="contentTitleId">
          <CustomSelectList
            id="selectListId"
            label="input.bundesland.placeholder"
            state={livingIn}
            onChangeHandler={onChangeHandler}
            options={countriesSelectOptions}
            ariaLabelledBy="contentTitle"
            optional={true}
          />

          <HiddenSubmitInput />
        </Form>
        <HorizontalRule className="horizontal-rule-desktop" />
        <WeiterButton
          disabled={!isValid()}
          path={ApplicationRoute.registerGender}
          onClick={onClickHandler}
          className="floating-weiter-buton"
        />
      </div>
    </>
  );
};

export default LivingInPage;