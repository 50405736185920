import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import apipath from "../../../apipath";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import LoadingPage from "../../travel-registration/LoadingPage";

function InfoDatenschutz() {
  const { setHeader } = useContext(HeaderContext);
  const [content, setContent] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: t("pages.informationen.navigationTitle.datenschutz"),
      };
    });

    fetch(`${apipath.path}app-inhalte/datenschutz/type/json/`, { method: "GET" })
      .then(data => data.json())
      .then(data => setContent(data.data));
  }, []);

  if (content.length === 0) {
    return <LoadingPage />
  }

  return (
    <div className="content-pages-container">
      <MetaDecorator title="pages.auslandsregistrierung.info" />
      {content.map(headerBody =>
        <>
          <div className="content-pages-heading" dangerouslySetInnerHTML={{ __html: t(headerBody.header) }} />
          <div className="content-pages-paragraph" dangerouslySetInnerHTML={{ __html: t(headerBody.bodytext) }} />
        </>
      )}
    </div>
  );
}

export default InfoDatenschutz;