import Loader from "./utils/Loader";

const LoadingPage = () => {
  return (
    <section className="loading-screen">
      <Loader />
    </section>
  );
};

export default LoadingPage;