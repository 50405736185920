const defaultTravelValuesWithDummyData = {
  Id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  Name: "string",
  StartDate: "2021-02-19T14:18:59.392Z",
  EndDate: "2021-02-19T14:18:59.392Z",
  CountryId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  Comment: "string",
  IsTourGroup: false,
  TravelGroupMember: [
    {
      Id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      Name: "string",
      FirstName: "string",
      LastName: "string",
      Birthdate: "2021-02-19T14:18:59.392Z",
      MobilePhone: "string",
      Gender: "100000000",
      Email: "string",
      Nationality: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    },
  ],
  TravelStops: [
    {
      Id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      Name: "string",
      StopStart: "2021-02-19T14:18:59.392Z",
      StopEnd: "2021-02-19T14:18:59.392Z",
      CountryId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      Comment: "string",
    },
  ],
};

const TravelGroupMemberOrig = {
  Id: "",
  Name: "",
  FirstLastName: "",
  Birthdate: "",
  MobilePhone: "",
  Gender: "",
  Email: "",
  Nationality: "",
  DegreeOfRelationship: "100000000"
};

const TravelStopsOrig = {
  Id: "",
  Name: "",
  StopStart: "",
  StopEnd: "",
  Country: "",
  CountryId: "",
  Comment: "",
  overlapsing: false
};

const defaultTravelOrig = {
  Id: "",
  Name: "",
  StopStart: "",
  StopEnd: "",
  Country: "",
  CountryId: "",
  Comment: "",
  TravelGroupMember: [],
  TravelStops: [Object.assign({}, TravelStopsOrig)],
};

const defaultForeingOrig = {
  FirstName: "",
  LastName: "",
  Street: "",
  PostalCode: "",
  City: "",
  Country: "",
  County: "",
  Passport: "",
  BirthDate: "",
  Gender: "",
  MobilePhone: "",
  Nationality: "",
  IsLivingAbroad: true,
  HomeRegion: "",
  JobTitle: "",
  FamilyStatusCode: "",
  PreviousNames: "",
  AdditionalTravelDokuments: "",
  EmergencyContactName: "",
  EmergencyContactEmail: "",
  EmergencyContactPhoneNumber: "",
  IsAlone: false,
  TravelGroupMember: []
};

export default Object.assign({}, defaultTravelOrig);

export const TravelData = Object.assign({}, defaultTravelOrig);
export const TravelTravelDummyData = Object.assign({}, defaultTravelValuesWithDummyData);

export const TravelGroupMember = Object.assign({}, TravelGroupMemberOrig);
export const TravelStops = Object.assign({}, TravelStopsOrig);
export const TravelForeignData = Object.assign({}, defaultForeingOrig);