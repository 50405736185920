import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import apipath from "../../../apipath";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";

function Bildquellen() {
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: t("pages.informationen.navigationTitle.bildquellen"),
      };
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const [bildquellen, setBildquellen] = useState([]);
  const fetchData = async () => {
    const response = await fetch(
      `${apipath.pathOrig}app-inhalte/impressum/verzeichnis-bildquellen-laenderfotos/type/json/`,
      { method: "GET" }
    );
    const bildquellen = await response.json();
    setBildquellen(bildquellen.data);
  };

  return (
    <div className="content-pages-container">
      <MetaDecorator title="pages.informationen.navigationTitle.bildquellen" />

      {bildquellen.map((list, index) => {
        return (
          <div
            key={index}
            dangerouslySetInnerHTML={{ __html: t(list.bodytext) }}
            className="content-pages-paragraph"
          />
        );
      })}
    </div>
  );
}

export default Bildquellen;