import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ApiRequestErrorMessage from "../../../common/ApiRequestErrorMessage.jsx";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput.jsx";
import ApplicationRoute from "../../../config/routes";
import Form from "../utils/Form.jsx";
import Input from "../utils/Input.jsx";
import WeiterButton from "../utils/WeiterButton.jsx";

//HINT* REGISTRATION
const LoginTemplate = (
  {
    onSubmitHandler,
    email,
    setEmail,
    password,
    setPassword,
    noLoginCred,
    error,
    loading,
    submitting,
    background,
    formLabel,
    disableButton
  }
) => {
  const { t } = useTranslation();
  const onEmailChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  const onPasswordChangeHandler = (e) => {
    setPassword(e.target.value);
  };

  const submittingNow = () => {
    return submitting && loading;
  };

  return (
    <>
      <Form id="form" ariaLabel={formLabel} ariaLabelledBy="contentTitle" onSubmit={onSubmitHandler}>
        <Input
          id="email"
          htmlFor="email"
          placeholder="input.email.text"
          type="text"
          labelText="input.email.text"
          onChange={onEmailChangeHandler}
          value={email}
          error={noLoginCred.email || error?.status === 401}
          labelStyle={!!background ? { backgroundColor: background } : null}
        />
        {(noLoginCred.email || noLoginCred.password || error?.status === 401) &&
          <ApiRequestErrorMessage
            style={{ marginTop: "-1.3rem", marginBottom: "1.6rem" }}
            text="errors.401-login"
          />
        }
        <Input
          id="password"
          htmlFor="password"
          placeholder="terms.passwort"
          type="password"
          labelText="terms.passwort"
          onChange={onPasswordChangeHandler}
          value={password}
          error={noLoginCred.password || error?.status === 401}
          labelStyle={!!background ? { backgroundColor: background } : null}
        />
        <HiddenSubmitInput />
        <WeiterButton
          disabled={disableButton || submittingNow()}
          onClick={onSubmitHandler}
          path={ApplicationRoute.TravelDashboardNoPermissions}
          positioned="static"
          linkId="loginLinkId"
          style={{ marginBottom: "0px" }}
        />
      </Form>

      <Link
        to={ApplicationRoute.registerForgottenPassword}
        aria-label={t("text.passwort_vergessen") + ' ' + t("pages.passwortVergessen.title")}
        style={{ display: "block" }}
      >
        <div className="centered-link__container">
          <div className="centered-link__container__link">{t("text.passwort_vergessen")}</div>
        </div>
      </Link>
    </>
  );
};

export default LoginTemplate;