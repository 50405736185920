import React, { useContext, useEffect, useState } from "react";

import HorizontalRule from "../../common/HorizontalRule";
import ContentTitle from "../registration/utils/ContentTitle.jsx";
import { ActiveQuestion } from "./FAQsPage";

const QuestionAnswerTemplate = ({ question, answer, anchor }) => {
    const { question: questionId, setQuestion } = useContext(ActiveQuestion)
    const [active, setActive] = useState(false)

    const setAsActive = () => {
        if (active) {
            setQuestion("")
            return
        }
        setQuestion(anchor)
    }

    useEffect(() => {
        setActive(anchor === questionId)
    }, [questionId])

    return (
        <>
            <div className={"question-container " + (active ? "active" : "")} onClick={setAsActive}>
                <ContentTitle
                    id={anchor}
                    text={question}
                />
            </div>
            <HorizontalRule className="horizontal-rule-desktop" />
            {
                active && (<>
                    <div className="answer-container">
                        {answer}
                    </div>
                    <HorizontalRule className="horizontal-rule-desktop" />
                </>
                )
            }
        </>
    );
};

export default QuestionAnswerTemplate;
