import {
  hasCapitalLetterValidation, hasDigitValidation,
  hasSmallLetterValidation, hasSpecialSymbolValidation,
  isInLengthRangeValidation
} from "../../../utils/validation.js";
import ValidationRow from "./ValidationRow.jsx";

const ValidationChecks = ({ passwords }) => {
  return (
    <div>
      <ValidationRow
        isValid={isInLengthRangeValidation(passwords.password, 8, 9999)}
        validationText="errors.passwordNotInRange"
      />
      <ValidationRow
        isValid={passwords.password && !passwords.password.includes(" ")}
        validationText="errors.passwordNoSpaces"
      />

      <ValidationRow
        isValid={hasSmallLetterValidation(passwords.password)}
        validationText="errors.paswwordNoLowerLetter"
      />

      <ValidationRow
        isValid={hasCapitalLetterValidation(passwords.password)}
        validationText="errors.passwordNoCapitalLetter"
      />
      <ValidationRow
        isValid={hasDigitValidation(passwords.password)}
        validationText="errors.passwordNoDigit"
      />
      <ValidationRow
        isValid={hasSpecialSymbolValidation(passwords.password)}
        validationText="errors.passwordNoSpecialLetter"
        tooltipText="errors.passwordNoSpecialLetterTooltip"
      />
    </div>
  );
};

export default ValidationChecks;