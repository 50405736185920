// use =>
//
//  const [alertBox, setAlertBox] = useState({ level: "", hidden: true });
//
// setAlertBox((curr) => {
//   return { ...curr, level: "attention", hidden: false };
// });
//
// level  => set the level of the desired alert box
// levels => information/attention
//
//
// change title, message, leftButtonText, rightButtonText for custom text
import { FocusScope } from "@react-aria/focus";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import AlertTriangle from "../svg/AlertTriangle";
import InfoCircle from "../svg/InfoCircle";

const levels = {
  information: "information",
  attention: "attention",
};

const AlertBox = ({
  id,
  level,
  hidden,
  title,
  message,
  leftButtonText = "terms.cancel",
  rightButtonText,
  onClickLeftButton,
  onClickRightButton,
  twoRows = false,
}) => {
  const { t } = useTranslation();

  const startRef = useRef();
  useEffect(() => {
    if (!hidden) {
      startRef.current.focus();
    }
  }, [hidden]);

  const onKeyDownHandler = (e) => {
    if (e.key === "Escape") {
      onClickLeftButton();
    }
  };

  title = title
    ?? (level === levels.information
      ? "pages.alertBox.dialog"
      : "pages.alertBox.deleteInfo")

  message = message
    ?? (level === levels.information
      ? "pages.alertBox.logoutInfo"
      : "pages.alertBox.deleteWarning")

  rightButtonText = rightButtonText
    ?? (level === levels.information
      ? "pages.alertBox.logout"
      : "pages.alertBox.delete")

  return (
    <div
      style={{ display: hidden ? "none" : "block" }}
      id={id}
      className="alert-box-background"
      aria-hidden={hidden}
      aria-atomic="true"
      aria-live="assertive"
      role="dialog"
      aria-label={t("pages.alertBox.dialog")}
      aria-describedby="abdescription"
      aria-modal="true"
      onKeyDown={onKeyDownHandler}
    >
      {/* <div tabindex="0" /> */}
      <FocusScope contain autoFocus>
        <div
          className="alert-box"
          role="alertdialog"
          aria-modal="true"
        // aria-labelledby="alert-box__title"
        // aria-describedby="alert-box__message"
        >
          <div className={`alert-box__icon `}>
            {level === levels.information ? (
              <InfoCircle className="alert-box__icon__information" />
            ) : (
              <AlertTriangle className="alert-box__icon__attention" />
            )}
          </div>

          <div
            //aria-label="Titel"
            id="alert-box__title"
            className="alert-box__title"
          >
            {t(title)}
          </div>

          <div id="abdescription" aria-label={t("pages.alertBox.botschaft")} className="alert-box__message">
            {t(message)}
          </div>

          <div>
            <div className={"alert-box__buttons" + (twoRows ? " alert-box__buttons--two-rows" : "")}>
              <div>
                <button
                  type="button"
                  id="leftButton"
                  className="alert-box__buttons__button alert-box__buttons__button--left"
                  onClick={onClickLeftButton}
                  ref={startRef}
                >
                  {t(leftButtonText)}
                </button>
              </div>
              <div>
                <button
                  type="button"
                  id="rightButton"
                  className=" alert-box__buttons__button alert-box__buttons__button--right"
                  onClick={onClickRightButton}
                >
                  {t(rightButtonText)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </FocusScope>
      {/* <div tabindex="0" /> */}
    </div>
  );
};

export default AlertBox;