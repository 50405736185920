import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../../common/HorizontalRule";
import InputValidation from "../../../../common/InputValidaiton";
import ApplicationRoute from "../../../../config/routes";
import HeaderContext from "../../../../context/HeaderContext";
import UserContext from "../../../../context/UserContext";
import MetaDecorator from "../../../../utils/MetaDecorator";
import { prefilledUserData } from "../../../../utils/helpers";
import {
  hasAtSymbolEmailValidation,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isEmailValidation,
} from "../../../../utils/validation";
import ContentTitle from "../../../registration/utils/ContentTitle";
import Form from "../../../registration/utils/Form";
import Input from "../../../registration/utils/Input";
import WeiterButton from "../../../registration/utils/WeiterButton";

//HINT* NOT REGISTRATION
export function ForeignEmail() {
  const history = useHistory();
  const { user, setUser } = useContext(UserContext);
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  const [email, setEmail] = useState("");

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((7 / 19) * 100),
        title: t("pageTitle.foreignRegistration"),
        ariaLabel: t("pageTitle.foreignRegistration"),
      };
    });
  }, []);

  const isValid = () => {
    return (
      isEmailValidation(email) &&
      hasTwoCharactersAfterAtSymbolEmailValidation(email)
    );
  };

  const updateUser = () => {
    setUser((curr) => {
      return { ...curr, Email: email || null };
    });
  };

  const onChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  const handler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history.push(ApplicationRoute.foreignRegistrationRegistrationPassport);
    }
  }

  const onClickHandler = (e) => {
    handler(e);
  };

  const onSubmitHandler = (e) => {
    handler(e);
  };

  useEffect(() => {
    setEmail(user.Email ?? "");
  }, [user]);

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title="pageTitle.foreignRegistration" />
      <ContentTitle
        id="foreign-registration-email"
        text={prefilledUserData("prefilledUserData.email")}
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        className="max-width-380"
        id="form"
        onSubmit={onSubmitHandler}
        ariaLabelledBy="foreign-registration-email"
      >
        <Input
          id="email"
          value={email}
          onChange={onChangeHandler}
          placeholder="input.email.text"
          type="email"
          labelText="input.email.text"
          showValidationBorder={
            email.length > 0 &&
            (!isValid() || !hasTwoCharactersAfterAtSymbolEmailValidation(email))
          }
        />

        <InputValidation
          isHidden={
            !email.length > 0 ||
            !hasAtSymbolEmailValidation(email) ||
            !hasTwoCharactersAfterAtSymbolEmailValidation(email) ||
            isValid()
          }
          text="errors.notValidEmail"
        />

        <InputValidation
          isHidden={!email.length > 0 || hasAtSymbolEmailValidation(email)}
          text="errors.missingAtSymbolEmail"
        />

        <InputValidation
          isHidden={
            !email.length > 0 ||
            !hasAtSymbolEmailValidation(email) ||
            hasTwoCharactersAfterAtSymbolEmailValidation(email)
          }
          text="errors.charactersAfterAtSymbolLessThanTwoEmail"
        />

        <HiddenSubmitInput />
      </Form>

      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content" />

      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationRegistrationPassport}
        onClick={onClickHandler}
        className="hide-weiter-on-keyboard  floating-weiter-buton"
      />
    </section>
  );
}