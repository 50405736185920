import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import { ReactComponent as UrkundenIcon } from '../assets/icons/icon-bcms_urkundenservice.svg';
import DesktopFooter from "../common/Footers/DesktopFooter";
import ApplicationRoute from "../config/routes.js";
import MemoInfoIcon from "../svg/IconInfoLogo.jsx";
import IconsLNder from "../svg/IconsLNder.jsx";
import MemoIconsNotfalltipps from "../svg/IconsNotfalltipps.jsx";
import MemoIconsTrip from "../svg/IconsTrip.jsx";
import MemoIconsVertretungen from "../svg/IconsVertretungen.jsx";
import { getIsLogged, isMobileEnv } from "../utils/helpers";

function Footer({ value, setValue, hidden, setHidden, valueC, setValueC, hiddenC, setHiddenC, color, path }) {
  const { t } = useTranslation();
  const history = useHistory();
  const isDotEnabled = process.env.REACT_APP_DOT_ENABLED === 'true';

  const navigate = (e) => {
    e.preventDefault();
    if (getIsLogged()) {
      history.push(ApplicationRoute.TravelDashboardNoPermissions);
    } else {
      if (history.location.pathname === "/") {
        window.scrollTo(0, document.body.scrollHeight);
      } else {
        history.push(ApplicationRoute.home);
      }
    }
  };

  const resetInputMapSearch = () => {
    setValue("");
    setHidden(true);
    setValueC("");
    setHiddenC(true);
  };

  const nav = (path) => (e) => {
    e.preventDefault();
    history.push(path);
    resetInputMapSearch();
  };

  return isMobileEnv() ? (
    // history.location.pathname !== "/" &&
    <footer className="mobile__navbar app-footer" role="navigation" aria-label={t("pages.footer.text")}>
      <NavLink
        onClick={navigate}
        to={getIsLogged() ? ApplicationRoute.TravelDashboardNoPermissions : ApplicationRoute.home}
        exact
        activeClassName="active"
        aria-label={`${t("terms.open")} ${t("pages.auslandsregistrierung.title")}`}
      >
        <MemoIconsTrip color={color[1]} className="navbar__icon" />
      </NavLink>
      <NavLink
        onClick={nav(ApplicationRoute.countryList)}
        to={ApplicationRoute.countryList}
        exact
        activeClassName="active"
        aria-label={`${t("terms.open")} ${t("pages.landerinformationen.title")}`}
      >
        <IconsLNder color={color[2]} className="navbar__icon" />
      </NavLink>
      <NavLink
        onClick={nav(ApplicationRoute.vertretungen)}
        to={ApplicationRoute.vertretungen}
        exact
        activeClassName="active"
        aria-label={`${t("terms.open")} ${t("pages.vertretungen.footerTitle")}`}
      >
        <MemoIconsVertretungen color={color[4]} className="navbar__icon" />
      </NavLink>
      <NavLink
        onClick={nav(ApplicationRoute.notfalltipps)}
        to={ApplicationRoute.notfalltipps}
        exact
        activeClassName="active"
        aria-label={`${t("terms.open")} ${t("pages.notfalltipps.title")}`}
      >
        <MemoIconsNotfalltipps color={color[3]} className="navbar__icon" />
      </NavLink>
      <NavLink
        onClick={nav(ApplicationRoute.dashboardInfos)}
        to={ApplicationRoute.dashboardInfos}
        exact
        activeClassName="active"
        aria-label={`${t("terms.open")} ${t("pages.informationen.title")}`}
      >
        <MemoInfoIcon color={color[5]} className="navbar__icon" />
      </NavLink>
      {isDotEnabled &&
        <NavLink
          onClick={nav(ApplicationRoute.dot)}
          to={ApplicationRoute.dot}
          exact
          activeClassName="active"
          aria-label={`${t("terms.open")} ${t("pages.urkundenbestellung.title2")}`}
        >
          <UrkundenIcon color={color[6]} className="navbar__icon" />
        </NavLink>
      }
    </footer>
  ) : (
    <>
      <DesktopFooter path={path} />
    </>
  );
}

export default Footer;