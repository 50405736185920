import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HashRouter } from 'react-router-dom';
import HeaderContext from '../../context/HeaderContext';
import MetaDecorator from '../../utils/MetaDecorator';
import { isMobileEnv } from '../../utils/helpers';
import App from './App';
import './Style/global.scss';

const queryClient = new QueryClient();

const DotWebsite: FC = () => {
  const ctx = useContext(HeaderContext) as any;
  const { t } = useTranslation();

  useEffect(() => {
    ctx.setHeader((curr: any) => {
      return {
        ...curr,
        inUse: isMobileEnv() ? "content" : "main",
        title: t("pages.urkundenbestellung.title"),
      };
    });
  }, []);

  return (
    <>
      <MetaDecorator title="pages.urkundenbestellung.title" description={undefined} />
      <HashRouter basename={process.env.REACT_APP_ROUTER_BASENAME}>
        <QueryClientProvider client={queryClient}>
          <div className="dot-website">
            <App />
          </div>
        </QueryClientProvider>
      </HashRouter>
    </>
  )
}

export default DotWebsite;