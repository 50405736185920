import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import HorizontalRule from "../../../common/HorizontalRule";
import Mail from "../../../common/Unions/Mail";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import ContentTitle from "../utils/ContentTitle";
import WeiterButton from "../utils/WeiterButton";

//HINT* REGISTRATION
const ActivatedAccountPage = () => {
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: 100,
        title: t("pageTitle.registrationPages"),
      };
    });
  }, []);

  return (
    <div className="registration-middle-container registration-middle-container--se">
      <MetaDecorator title="pageTitle.registrationPages" />
      <ContentTitle
        id="emaild-verified"
        style={{ marginTop: "0.6rem", marginBottom: "1.6rem" }}
        text="pages.activateAccount.title"
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Mail type="success" className="global-flex-center" style={{ marginTop: "4rem" }} />
      <HorizontalRule className="horizontal-rule-desktop" />
      <WeiterButton className="register-verify-email" text="pages.activateAccount.next" path={ApplicationRoute.home} />
    </div>
  );
};

export default ActivatedAccountPage;