import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import informationLists from "../utils/informationLists.js";

// Wird bloß in einer Testseite verwendet --> keine Übersetzung
function AllgemeineInformationen() {
  const { text } = useParams();
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: t(text),
      };
    });
  }, []);

  return (
    <div className="content-pages-container">
      <MetaDecorator title={text} />
      {informationLists
        .filter((list) => t(list.text) === t(text))
        .map((list, index) => {
          return (
            <p className="content-pages-paragraph" id={index}>
              {t(list.body)}
            </p>
          );
        })
      }
    </div>
  );
}

export default AllgemeineInformationen;