import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import {
  getCountryFlagFromCountryVB,
  getCountryNameFromUid,
} from "../../travel-registration/utils/countryInfo";
import CountryInfoHeader from "../utils/CountryInfoHeader";

function CountryInformation() {
  const { countryUid, title } = useParams();
  const { t } = useTranslation();

  const { setHeader } = useContext(HeaderContext);
  const countryName = getCountryNameFromUid(countryUid);
  const travelInfo = localStorage.getItem("travelInfo") != null ? JSON.parse(localStorage.getItem("travelInfo")) : {};

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: t(title),
      };
    });
  }, []);

  return (
    <div className="content-pages-container detail-countries">
      <MetaDecorator title={title} />
      <CountryInfoHeader
        flag={getCountryFlagFromCountryVB(countryName)}
        Country={countryName}
        className="country__flag-no-image"
      />
      {travelInfo?.data?.map((info) => {
        if (info.header === title) {
          return <div key={title} dangerouslySetInnerHTML={{ __html: info.bodytext }} />;
        }
        return null;
      })}
    </div>
  );
}

export default CountryInformation;