import React from "react";
import { useTranslation } from "react-i18next";

const AnswerTravelCancel = () => {
    const { t } = useTranslation();

    return (
        <>
            <p className="answer-text">
                {t("pages.faq.answerTravelCancel")}
            </p>
            <div className="answer-image-container">
                <div className="answer-image-box">
                    <img className="" src="./images/faqs/travel-cancel-1.png" alt="" />
                </div>
                <div className="answer-image-box">
                    <img className="" src="./images/faqs/travel-cancel-2.png" alt="" />
                </div>
            </div>
        </>
    );
};

export default AnswerTravelCancel;
