import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Cross from "../../svg/Cross";

const EditProfileHeader = ({
  title,
  onClickCloseHandler,
  onClickLogoutHandler,
  buttonId = "buttonId",
  linkId = "linkId",
}) => {
  const { t } = useTranslation();

  return (
    <header className="edit-profile-header" role="navigation">
      <a className="sr-only" href="#main" onClick={(ev) => {
        ev.preventDefault();
        document.getElementById("main").scrollIntoView();
      }}
      >
        {t("terms.jump_to")}
      </a>
      <div className="edit-profile-header__nav">
        <Link
          role="button"
          id={linkId}
          to="./"
          className="edit-profile-header__nav__close"
          ariaLabel={t("terms.close")}
          onClick={onClickCloseHandler}
        >
          <Cross className="edit-profile-header__nav__close-svg" />
        </Link>
        <button
          id={buttonId}
          onClick={onClickLogoutHandler}
          className="edit-profile-header__nav__logout"
          ariaLabel={t("pages.alertBox.logout")}
        >
          {t("pages.alertBox.logout")}
        </button>
      </div>
      <div role="heading" aria-level="1" className="edit-profile-header__title">
        {t(title)}
      </div>
    </header>
  );
};

export default EditProfileHeader;