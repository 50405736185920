import { persistentAtom } from '@nanostores/persistent';
import i18n from 'i18next';
import { action, computed } from 'nanostores';
import { initReactI18next } from 'react-i18next';

export const $language = persistentAtom<string>("language", "de");
export const defaultLanguage = computed($language, val => val);

export const setLanguage = action($language, "save", (store, language: string) => {
  store.set(language)
  i18n.changeLanguage(language)
})

i18n.use(initReactI18next).init({
  fallbackLng: defaultLanguage.get(),
  lng: defaultLanguage.get(),
  resources: {
    de: {
      translation: require('./de.json')
    },
    en: {
      translation: require('./en.json')
    },
  },
  ns: ['translation', 'dot'],
  defaultNS: 'translation'
})

export default i18n