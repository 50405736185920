import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const WhiteButton = ({
  onClick,
  text = "terms.weiter",
  path = "",
  ariaLabel = "",
}) => {
  const { t } = useTranslation();

  return (
    <Link
      role="button"
      to={path}
      aria-label={t(ariaLabel)}
      id="white-button"
      className="white-button"
      onClick={onClick}
    >
      <span className="white-button__text">{t(text)}</span>
    </Link>
  );
};

export default WhiteButton;