import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import apipath from "../../../apipath";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import LoadingPage from "../../travel-registration/LoadingPage";

function AllgemeineInformationen() {
  const { setHeader } = useContext(HeaderContext);
  const [content, setContent] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: t("pages.informationen.navigationTitle.allgemeineInformationen"),
      };
    });
    fetch(`${apipath.pathOrig}app-inhalte/informationen-zur-auslandsservice-app/type/json/`, { method: "GET" })
      .then((data) => data.json())
      .then((data) => setContent(data.data[0].bodytext));
  }, []);

  if (content === "") return <LoadingPage />;

  return (
    <div className="content-pages-container">
      <MetaDecorator title="pages.informationen.navigationTitle.allgemeineInformationen" />
      <div dangerouslySetInnerHTML={{ __html: t(content) }} />
    </div>
  );
}

export default AllgemeineInformationen;