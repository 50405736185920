import { useTranslation } from "react-i18next";

const SelectListOptions = ({
  arr, optional, changeHandler, label
}) => {
  const { t } = useTranslation();

  return (
    <>
      {arr.map((option, index) => {
        if (index > 0 || optional)
          return (
            <div
              role="option"
              key={option.id}
              id={option.id}
              className={
                "custom-selectlist__option" +
                (option.selected ? " selected" : "") +
                (index === 0 ? " not-selectable" : "")
              }
              aria-selected={option.selected}
              onClick={() => changeHandler(index, t(option.value))}
              tabIndex="0"
            >
              {!optional || (optional && t(option.value) !== t(label)) ? t(option.value) : t("input.bundesland.options.optional")}
            </div>
          );
        else
          return;
      }).filter((r) => !!r)}
    </>
  );
};

export default SelectListOptions;