const InfoCircle = ({ className }) => {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <path fill="#37783E" d="M26 0c14.36 0 26 11.64 26 26S40.36 52 26 52 0 40.36 0 26 11.64 0 26 0" transform="translate(-137 -36) translate(137 36)" />
              <path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M26.1 8.38L26.1 12.1M26.1 39.02L26.1 20.3 20.08 20.3M17.76 39.02L34.24 39.02" transform="translate(-137 -36) translate(137 36)" />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default InfoCircle;