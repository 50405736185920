import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import apipath from "../../../apipath";
import HorizontalRule from "../../../common/HorizontalRule";
import Separater from "../../../common/Separater";
import HeaderContext from "../../../context/HeaderContext";
import UserContext from "../../../context/UserContext";
import { checkIfCached } from "../../../services/caching";
import fetcher from "../../../services/fetcher";
import MetaDecorator from "../../../utils/MetaDecorator";
import { CacheKeyTravelInfo } from "../../../utils/const";
import { compare, getCountryRiskWarningTranslationKey } from "../../../utils/helpers";
import { getVB__with__loader } from "../../travel-registration/utils/GetCountries";
import Loader from "../../travel-registration/utils/Loader";
import CountryCard from "../utils/CountryCard";

const getFullName = (country, aliasArr) => {
  const a = aliasArr.length > 0 ? aliasArr[0] + " " : "";
  let b = country;

  if (aliasArr.length > 0) {
    b = "(" + b + ")";
  }
  const x = a + b;

  return x;
};

const CountryList = ({ valueC }) => {
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  const [countriesVb, setCountriesVb] = useState(null);
  const [cachedCountries, setCachedCountries] = useState([]);

  useEffect(() => {
    (async () => {
      if (!countriesVb || !countriesVb.countries) return;
      const result = await Promise.all(
        countriesVb.countries.map(async (x) => {
          const cacheData = await checkIfCached(CacheKeyTravelInfo + x.country);
          return {
            name: x.country,
            ...cacheData
          }
        })
      );
      setCachedCountries(result)
    })()
  }, [countriesVb, countriesVb?.countries, setCachedCountries, checkIfCached])

  const [favCountries, setFavCountries] = useState(
    localStorage.getItem("favoriteCountries") != null ? JSON.parse(localStorage.getItem("favoriteCountries")) : []
  );

  const [locationCountry, setLocationCountry] = useState("");

  const [data, setData] = useState(null);
  const [, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { offline } = useContext(UserContext);

  const getMiddlewareLocationObject = () => {
    fetcher(
      `${apipath.path}geocodes/${location.lat}/${location.long}`,
      "GET",
      setData,
      setError,
      setLoading,
      setSubmitting,
      true,
      "json",
      undefined,
      undefined,
      undefined,
      true
    );
  };

  const [location, setLocation] = useState({ long: "", lat: "" });

  function enableLocation() {
    if (navigator.geolocation) navigator.geolocation.getCurrentPosition(handleGetCurrentPosition, onError);
  }

  function handleGetCurrentPosition(location) {
    setLocation((curr) => {
      return {
        ...curr,
        long: location.coords.longitude,
        lat: location.coords.latitude,
      };
    });
  }

  useEffect(() => {
    //get location from getLocation
    enableLocation();
  }, []);

  useEffect(() => {
    //load middleware object
    if ((!!location.long || location.long < 0) && (!!location.lat || location.lat < 0)) {
      getMiddlewareLocationObject();
    }
  }, [location]);

  useEffect(() => {
    //use location object from middleware
    if (!loading) {
      try {
        const countryVBobject = JSON.parse(localStorage.getItem("countriesVB")).countries.filter((c) => {
          return c.countryIsoCode === data?.country;
        });
        setLocationCountry(countryVBobject[0]);
      } catch (error) {
        console.error(error)
      }
    }
  }, [loading]);

  function onError() {
    setLocation((curr) => {
      return { ...curr, long: "", lat: "" };
    });
  }

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "countriesList",
        title: t("pages.landerinformationen.title"),
      };
    });
  }, []);

  useEffect(() => {
    localStorage.setItem("favoriteCountries", JSON.stringify(favCountries));
  }, [favCountries]);

  const handleFavClick = (ev, uid) => {
    ev.preventDefault();
    if (favCountries.indexOf(uid) !== -1) {
      const index = favCountries.indexOf(uid);
      let curr = JSON.parse(JSON.stringify(favCountries));
      curr.splice(index, 1);
      setFavCountries(curr);
    } else {
      setFavCountries((curr) => [...curr, uid]);
    }
  };

  const [vbLoader, setVBLoader] = useState(false);

  useEffect(() => {
    try {
      const vbData = JSON.parse(localStorage.getItem("countriesVB"));
      if (vbData) {
        vbData.countries.sort(compare);
        setCountriesVb(vbData);
      } else {
        getVB__with__loader(setVBLoader);
      }
    } catch (error) {
      console.error(error)
    }
  }, []);

  useEffect(() => {
    if (vbLoader) {
      const vbData = JSON.parse(localStorage.getItem("countriesVB"));

      try {
        const countryVBobject = JSON.parse(localStorage.getItem("countriesVB")).countries.filter((c) => {
          return c.countryIsoCode === data?.country;
        });
        setLocationCountry(countryVBobject[0]);
      } catch (error) {
        console.error(error)
      }

      setCountriesVb(vbData);
    }
  }, [vbLoader]);

  useEffect(() => {
    if (countriesVb) {
      if (localStorage.getItem("countryOverviewPosition") !== null) {
        setTimeout(() => {
          window.scrollTo(0, parseInt(localStorage.getItem("countryOverviewPosition")));
          localStorage.removeItem("countryOverviewPosition");
        }, 700);
      }
    }
  }, [countriesVb]);

  if (countriesVb === null) return <Loader />;
  if (loading && submitting) return <Loader />;

  return (
    <section className="country-list">
      <MetaDecorator title="pages.landerinformationen.title" />
      {location.long !== "" && location.lat !== "" && !valueC &&
        <section aria-label={t("pages.countryList.location")}>
          <Separater text="pages.countryList.location" />

          <CountryCard
            key={locationCountry?.country}
            flag={`${apipath.pathBMEIAgvat_API}flags/${data ? data.country.toLowerCase() : ""}.png`}
            countryName={
              <div>
                {(offline && cachedCountries?.findIndex(x => t(x.name) === t(locationCountry?.country) && x.cached) !== -1) &&
                  <div className="countrylist__offline_available">{t("pages.countryList.offline")}</div>
                }
                {t(locationCountry?.country)}
              </div>
            }
            favouriteToggle={() => { }}
            riskLvl={getCountryRiskWarningTranslationKey(locationCountry?.riskLevel)}
            countryUid={locationCountry?.countryUid}
            hasStar={false}
          />
        </section>
      }

      {!!favCountries.length &&
        <div key={countriesVb?.countries.map((c) => c.countryUid)}>
          <Separater text="pages.countryList.favorite" className="country__separater--hidden" />
          <section className="country-list__favourites" role="list" aria-label={t("pages.countryList.favorite")}>
            {countriesVb?.countries
              .filter((c) => {
                let v = valueC ? valueC.toLowerCase() : "";
                return getFullName(c.country, c.countryAlias).toLowerCase().includes(v);
              })
              .map((item) => {
                return (
                  <Fragment key={item.countryUid}>
                    {favCountries?.indexOf(item.countryUid) !== -1 && (
                      <>
                        <CountryCard
                          id={item.countryUid}
                          key={item.countryUid}
                          flag={`${apipath.pathBMEIAgvat_API}flags/${item.countryIsoCode ? item.countryIsoCode.toLowerCase() : "no_iso_code"}.png`}
                          countryName={
                            <div>
                              {(offline && cachedCountries?.findIndex(x => t(x.name) === t(item?.country) && x.cached) !== -1) &&
                                <div className="countrylist__offline_available">{t("pages.countryList.offline")}</div>
                              }
                              {item.countryAlias.length > 0 ? t(item.countryAlias) + " (" + t(item?.country) + ")" : t(item.country)}
                            </div>
                          }
                          riskLvl={getCountryRiskWarningTranslationKey(item.riskLevel)}
                          favourite={true}
                          favouriteToggle={handleFavClick}
                          countryUid={item.countryUid}
                          role="listitem"
                        />
                        <HorizontalRule />
                      </>
                    )}
                  </Fragment>
                )
              })
            }
          </section>
        </div>
      }

      <Separater text={t(favCountries.length > 0 ? "terms.other_countries" : "terms.all_countries")} />

      <section role="list" aria-label={t("terms.other_countries")}>
        {countriesVb.countries
          .filter((c) => {
            let v = valueC ? valueC.toLowerCase() : "";
            return getFullName(c.country, c.countryAlias).toLowerCase().includes(v);
          })
          .map((item, index) => {
            return (
              <Fragment key={item.countryUid}>
                {favCountries?.indexOf(item.countryUid) === -1 && (
                  <>
                    <CountryCard
                      flag={`${apipath.pathBMEIAgvat_API}flags/${item.countryIsoCode ? item.countryIsoCode.toLowerCase() : "no_iso_code"}.png`}
                      countryName={
                        <div>
                          {(offline && cachedCountries?.findIndex(x => t(x.name) === t(item?.country) && x.cached) !== -1) &&
                            <div className="countrylist__offline_available">{t("pages.countryList.offline")}</div>
                          }
                          {item.countryAlias.length > 0 ? t(item.countryAlias) + " (" + t(item?.country) + ")" : t(item.country)}
                        </div>
                      }
                      id={item.countryUid}
                      riskLvl={getCountryRiskWarningTranslationKey(item.riskLevel)}
                      favouriteToggle={handleFavClick}
                      countryUid={item.countryUid}
                      role="listitem"
                    />
                    <HorizontalRule />
                  </>
                )}
              </Fragment>
            )
          })
        }
      </section>
    </section>
  );
};

export default CountryList;
