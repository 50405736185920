import { useTranslation } from "react-i18next";

const RegisterIcon = ({ color }) => {
  const { t } = useTranslation();

  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>{t("pages.registerIcon.title")}</title>
      <g id="Icons-/-createprofile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-8">
          <g transform="translate(-0.000000, 0.000000)">
            <path
              d="M6.57227113,6.71620636 C8.48797389,6.71620636 10.0409636,5.21273151 10.0409636,3.35809816 C10.0409636,1.50347485 8.48797389,0 6.57227113,0 C4.65657874,0 3.1035683,1.50347485 3.1035683,3.35809816 C3.1035683,5.21273151 4.65657874,6.71620636 6.57227113,6.71620636"
              id="Fill-1"
              fill={color === "transperant" ? "#9E0529" : "#FFFFFF"}
            />
            <path
              d="M12.2390248,13.4617015 C11.0264519,13.4617015 10.0399214,12.5066452 10.0399214,11.3327134 C10.0399214,10.5292263 10.5021897,9.82845541 11.1826707,9.46609261 C11.1650619,9.44932631 11.1477227,9.43230902 11.1299272,9.41574352 C9.94833052,8.31444892 8.34199578,7.63727132 6.57227632,7.63727132 C2.94251172,7.63727132 0,10.4859753 0,14 L13.1445423,14 C13.1445423,13.7599408 13.1307393,13.5229939 13.1039735,13.289852 C12.8383372,13.4003088 12.5459765,13.4617015 12.2390248,13.4617015"
              id="Fill-3"
              fill={color === "transperant" ? "#9E0529" : "#FFFFFF"}
            />
          </g>
        </g>
        <circle id="Oval" fill={color === "transperant" ? "#F0F4F5" : "#9E0529"} cx="12" cy="12"
          r="4" />
        <g id="Group-7" transform="translate(9.000000, 9.000000)">
          <polygon id="path-2" points="0 0 6 0 6 6 0 6" />
          <g id="Clip-6" />
          <path
            d="M4.6753981,3.42990404 L3.41685315,3.42990404 L3.41685315,4.67542173 L2.57993145,4.67542173 L2.57993145,3.42990404 L1.3246019,3.42990404 L1.3246019,2.5701313 L2.57993145,2.5701313 L2.57993145,1.32459594 L3.41685315,1.32459594 L3.41685315,2.5701313 L4.6753981,2.5701313 L4.6753981,3.42990404 Z M2.99998233,0 C2.48502426,0 1.99997644,0.130560373 1.57607415,0.36010989 C0.63836215,0.867934973 0,1.86077683 0,3 C0,4.65422113 1.34578465,6 2.99998233,6 C3.4485301,6 3.87396942,5.90039251 4.2564779,5.72319081 C5.28468034,5.24686629 6,4.20570473 6,3 C6,1.34579654 4.65421535,0 2.99998233,0 L2.99998233,0 Z"
            id="Fill-5"
            fill={color === "transperant" ? "#9E0529" : "#FFFFFF"}
            mask="url(#mask-3)"
          />
        </g>
      </g>
    </svg>
  );
};

export default RegisterIcon;