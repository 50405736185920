import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import {
  getEnumFromGender,
  getGenderTextFromEnum,
  getKeyByValue,
  goToNext,
} from "../../../utils/helpers";
import ControledRadioInput from "../../registration/utils/ControledRadioInput";
import Form from "../../registration/utils/Form";
import WeiterButton from "../../registration/utils/WeiterButton";
import PageTitle from "../../travel-registration/utils/PageTitle";

const initSelectValue = {
  male: false,
  female: false,
  other: false,
};

const AddRelative3 = () => {
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  const genders = useMemo(
    () => {
      return {
        male: t("input.gender.options.male"),
        female: t("input.gender.options.female"),
        other: t("input.gender.options.other")
      }
    },
    [t]
  );

  useEffect(() => {
    let title = "input.mitreisende.addTitle";

    try {
      title = localStorage.getItem("addTGMTitle");
    } catch (error) { }

    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t(title),
        progress: "no",
      };
    });
  }, []);
  const history = useHistory();

  let initgender = {};
  try {
    initgender = JSON.parse(localStorage.getItem("newTGM")).Gender;
    let g = getGenderTextFromEnum(initgender, t);
    initgender = { ...initSelectValue, [g]: true };
  } catch (error) { }

  const [selectedValue, setSelectedValue] = useState(
    initgender ? initgender : initSelectValue
  );

  const isValid = () => {
    return getKeyByValue(selectedValue, true);
  };

  const updateTGM = () => {
    let tgm = JSON.parse(localStorage.getItem("newTGM"));
    localStorage.setItem(
      "newTGM",
      JSON.stringify({
        ...tgm,
        Gender: getEnumFromGender(getKeyByValue(selectedValue, true), t),
      })
    );
  };

  const onClickHandler = (e) => {
    goToNext(e, isValid, updateTGM, ApplicationRoute.addRelative4, history);
  };

  const onChangeHandler = (e) => {
    setSelectedValue(() => {
      return { ...initSelectValue, [e.target.id]: true };
    });
  };

  const onSubmitHandler = (e) => {
    goToNext(e, isValid, updateTGM, ApplicationRoute.addRelative4, history);
  };

  return (
    <div className="add-relative-container registration-middle-container">
      <MetaDecorator title="pageTitle.editTrip" />
      <PageTitle id="pages.addRelative3.formLabel" text="pages.addRelative3.title" />

      <p className="page-paragraph page-paragraph--small">{t("input.mitreisende.one")}</p>
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        id="form"
        onSubmit={onSubmitHandler}
        ariaLabelledBy="pages.addRelative3.formLabel"
        className="max-width-380"
      >
        <ControledRadioInput
          id="male"
          value="100000000"
          name="gender"
          textInfo={genders.male}
          checked={selectedValue.male}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.male}
        />
        <ControledRadioInput
          id="female"
          value="100000001"
          name="gender"
          textInfo={genders.female}
          checked={selectedValue.female}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.female}
        />
        <ControledRadioInput
          id="other"
          value="100000002"
          name="gender"
          textInfo={genders.other}
          checked={selectedValue.other}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.other}
        />

        <HiddenSubmitInput />
      </Form>

      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content" />

      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addRelative4}
        onClick={onClickHandler}
        className="floating-weiter-buton"
      />
    </div>
  );
};

export default AddRelative3;