import dateAndTime from "date-and-time";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import DateModalDialog from "./DateModalDialog";

class InputCalendar extends Component {
  constructor(props) {
    super(props);

    let value = "";

    if (this.props.startDate && this.props.endDate)
      value =
        dateAndTime.format(this.props.startDate, "DD-MM-YYYY") +
        " - " +
        dateAndTime.format(this.props.endDate, "DD-MM-YYYY");

    this.state = {
      oldProps: props,
      showModal: false,
      noToggle: !!props.noToggle,
      srStartDate: this.props.startDate ? dateAndTime.format(this.props.startDate, "YYYY-MM-DD") : "",
      srEndDate: this.props.endDate ? dateAndTime.format(this.props.endDate, "YYYY-MM-DD") : "",
      value,
    };

    this.toggleModal = () => {
      if (!this.state.noToggle) {
        if (
          document.getElementsByTagName("body").item(0).classList.contains("overflow-hidden") &&
          this.state.showModal
        ) {
          document.getElementsByTagName("body").item(0).classList.remove("overflow-hidden");
        } else {
          document.getElementsByTagName("body").item(0).classList.add("overflow-hidden");
        }
      }
      this.setState({
        showModal: !this.state.showModal,
      });
    };

    this.receiveData = (startDate, endDate) => {
      this.toggleModal();
      this.props.onChange(startDate, endDate);
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps && nextProps !== nextState.oldProps) {
      if (nextProps.startDate && nextProps.endDate) {
        nextState.srStartDate = nextProps.startDate ? dateAndTime.format(nextProps.startDate, "YYYY-MM-DD") : "";
        nextState.srEndDate = nextProps.endDate ? dateAndTime.format(nextProps.endDate, "YYYY-MM-DD") : "";
        nextState.oldProps = nextProps;

        return true;
      }
    }

    return true;
  }

  onChangeSrDate1 = (ev) => {
    let setStateObj = {
      srStartDate: ev.currentTarget.value,
      srEndDate: this.state.srEndDate,
    };

    let bool = true;
    let counter = 0;

    for (const char of ev.currentTarget.value) {
      if (char === "-") counter++;
    }

    if (counter !== 2) bool = false;

    ev.currentTarget.value.split("-").forEach((el, index) => {
      let parsed = parseInt(el);

      if (isNaN(parsed) || parsed === 0) bool = false;
      if (index === 0 && parsed.toString().length !== 4) bool = false;
    });

    if (bool) {
      if (new Date(this.state.srEndDate) != "Invalid Date" && new Date(ev.currentTarget.value) != "Invalid Date") {
        if (new Date(this.state.srEndDate) < new Date(ev.currentTarget.value)) {
          setStateObj.srStartDate = this.state.srEndDate;
          setStateObj.srEndDate = ev.currentTarget.value;
          this.props.onChange(new Date(this.state.srEndDate), new Date(ev.currentTarget.value));
        } else {
          setStateObj.srStartDate = ev.currentTarget.value;
          setStateObj.srEndDate = this.state.srEndDate;
          this.props.onChange(new Date(ev.currentTarget.value), new Date(this.state.srEndDate));
        }
      }
    }

    this.setState(setStateObj);
  };

  onChangeSrDate2 = (ev) => {
    let setStateObj = {
      srStartDate: this.state.srStartDate,
      srEndDate: ev.currentTarget.value,
    };
    let bool = true;
    let counter = 0;

    for (const char of ev.currentTarget.value) {
      if (char === "-") counter++;
    }

    if (counter !== 2) bool = false;

    ev.currentTarget.value.split("-").forEach((el, index) => {
      let parsed = parseInt(el);

      if (isNaN(parsed) || parsed === 0) bool = false;
      if (index === 0 && parsed.toString().length !== 4) bool = false;
    });

    if (bool) {
      if (new Date(this.state.srStartDate) != "Invalid Date" && new Date(ev.currentTarget.value) != "Invalid Date") {
        if (new Date(this.state.srStartDate) > new Date(ev.currentTarget.value)) {
          setStateObj.srStartDate = ev.currentTarget.value;
          setStateObj.srEndDate = this.state.srStartDate;
          this.props.onChange(new Date(ev.currentTarget.value), new Date(this.state.srStartDate));
        } else {
          setStateObj.srStartDate = this.state.srStartDate;
          setStateObj.srEndDate = ev.currentTarget.value;
          this.props.onChange(new Date(this.state.srStartDate), new Date(ev.currentTarget.value));
        }
      }
    }
    this.setState(setStateObj);
  };

  onChange = (ev) => {
    this.setState({
      value: ev.currentTarget.value,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <div
          className={
            "custom-input__container" +
            (this.state.value ? " custom-input__container--active" : "") +
            (this.props.error ? " custom-input__container--error" : "")
          }
        >
          <div
            className={
              "custom-input__container__custom-input--button custom-input__container__custom-input" +
              (!this.state.value ? " custom-input__container__custom-input--placeholder" : "") +
              " " +
              this.props.cClassName ?? ""
            }
          >
            <label
              className={"custom-input__container__custom-label " + this.props.cLabelClass ?? ""}
              htmlFor={this.props.id}
            >
              {t(this.props.labelText)}
            </label>

            <input
              id={this.props.id}
              type="date"
              className="startdate"
              aria-label={t("input.date.startDate")}
              value={this.state.srStartDate}
              onChange={this.onChangeSrDate1}
              placeholder="YYYY-MM-DD"
              required
            />

            <input
              type="date"
              className="enddate"
              value={this.state.srEndDate}
              aria-label={t("input.date.endDate")}
              onChange={this.onChangeSrDate2}
              placeholder="YYYY-MM-DD"
              required
            />

            <button
              onClick={this.toggleModal}
              type="button"
              className={`custom-input__container__calender-icon ${this.props.cImgClassName}`}
              style={{ backgroundColor: "transparent", border: "0", display: "flex" }}
              aria-label={t("input.travelTime.edit")}
            >
              {this.props.cImg ? (
                <svg
                  width="19.6px"
                  height="19.55px"
                  viewBox="0 0 19.6 19.55"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title aria-label={t("terms.edit")}>{t("terms.edit")}</title>

                  <g
                    id="Icons-/-edit"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <g>
                      <g transform="translate(1.000000, 1.000000)">
                        <path
                          d="M18,2.34782609 C18,2.99232737 17.8163265,3.54475703 17.3571429,4.00511509 L6.06122449,15.2378517 L0,18 L2.75510204,11.9232737 L13.9591837,0.690537084 C14.877551,-0.230179028 16.3469388,-0.230179028 17.2653061,0.690537084 C17.7244898,1.15089514 18,1.70332481 18,2.34782609 Z"
                          id="Stroke-1"
                          stroke="#9e0529"
                        />
                        <path
                          d="M13,2.00820793 C13,2.00820793 13.8308257,1.84199726 14.9939817,3.00547196 C16.1579686,4.16894665 15.9918034,5 15.9918034,5"
                          id="Stroke-3"
                          stroke="#9e0529"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              ) : (
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g fill="none" fillRule="evenodd">
                    <g>
                      <g>
                        <g>
                          <path
                            d="M0 0H24V24H0z"
                            transform="translate(-314 -306) translate(23 280) translate(291 26)"
                          />
                          <path
                            fill="#9E0529"
                            fillRule="nonzero"
                            d="M19 19H5V8h14m-3-7v2H8V1H6v2H5c-1.11 0-2 .89-2 2v14c0 1.105.895 2 2 2h14c1.105 0 2-.895 2-2V5c0-1.105-.895-2-2-2h-1V1m-1 11h-5v5h5v-5z"
                            transform="translate(-314 -306) translate(23 280) translate(291 26)"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              )}
            </button>
          </div>
        </div>

        {this.state.showModal &&
          <DateModalDialog
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            close={this.toggleModal}
            sendData={this.receiveData}
            range={this.props.range}
          />
        }
      </>
    );
  }
}

export default withTranslation()(InputCalendar)