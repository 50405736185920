import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import TravelContext from "../../../context/TravelContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import {
  compareGermanWords,
  pluck,
  postalCodeFormatter,
} from "../../../utils/helpers";
import Form from "../../travel-registration/utils/Form";
import Input from "../../travel-registration/utils/Input";
import PageTitle from "../../travel-registration/utils/PageTitle";
import WeiterButton from "../../travel-registration/utils/WeiterButton";
import { getRegionsByCountryName } from "../../travel-registration/utils/countryInfo.js";
import FilteredSelectList from "../utils/FilteredSelectList";

const RegionSelection = () => {
  const { travel, setTravel } = useContext(TravelContext);
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  const history = useHistory();

  const [countries] = useState(JSON.parse(localStorage.getItem("countries")));
  const [countryNames] = useState(
    pluck(countries, "Name").filter((c) => {
      return !(c === t("terms.austria") || c === t("terms.other_countries"));
    })
  );
  const [regions, setRegions] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState(travel.CountryName ?? "");
  const [selectedRegion, setSelectedRegion] = useState(travel.RegionName ?? "");

  const [street, setStreet] = useState(travel.Street ?? "");
  const [postalCode, setPostalCode] = useState(travel.PostalCode ?? "");
  const [city, setCity] = useState(travel.City ?? "");

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t("pages.pageTitle.foreignRegistration"),
        ariaLabel: t("pages.pageTitle.foreignRegistration"),
        progress: parseInt((1 / 19) * 100),
      };
    });

    if (selectedCountry) {
      setRegions(pluck(getRegionsByCountryName(selectedCountry), "Name"));
    }
  }, []);

  const onClickHandler = (e) => {
    e.preventDefault();
    // Add Coutry and Region to the data

    if (!validation()) {
      let countryId,
        countryName = selectedCountry;
      let regionId,
        regionName = selectedRegion;

      for (const country of countries) {
        if (country.Name === selectedCountry) {
          countryId = country.Id;

          for (const region of country.Regions) {
            if (region.Name === selectedRegion) {
              regionId = region.Id;
              break;
            }
          }
          break;
        }
      }

      let travelCopy = Object.assign({}, travel);
      travelCopy.Country = countryId;
      travelCopy.Region = regionId;
      travelCopy.RegionName = regionName;
      travelCopy.CountryName = countryName;
      travelCopy.RegionName = regionName;
      travelCopy.Street = street;
      travelCopy.PostalCode = postalCode;
      travelCopy.City = city;

      setTravel(travelCopy);

      history.push(ApplicationRoute.foreignRegistrationRegistrationName);
    }
  };

  const onCountryChange = (value) => {
    setSelectedCountry(value);
    setSelectedRegion("");
    let regionsArr = pluck(getRegionsByCountryName(value), "Name");
    if (regionsArr.length === 1) {
      setSelectedRegion(regionsArr[0]);
    } else if (regionsArr) {
      regionsArr.sort(compareGermanWords);
    }

    setRegions(regionsArr);
  };

  const onRegionChange = (value) => {
    setSelectedRegion(value);
  };

  const validation = () => {
    return !(selectedCountry && selectedRegion && street && city && postalCode.toString().length >= 1);
  };

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title="pageTitle.foreignRegistration" />
      <PageTitle id="region-selection" text="pages.regionSelection.pageTitle" ariaLabel="pages.regionSelection.pageTitle" />
      <HorizontalRule className="horizontal-rule-desktop" />

      <Form id="form" className="form m-t-l max-width-380" ariaLabelledBy="region-selection" onSubmit={onClickHandler}>
        <div className="m-b-s">
          <FilteredSelectList
            id="country-select"
            options={countryNames}
            state={selectedCountry}
            label="input.select.text"
            onChangeHandler={onCountryChange}
          />
        </div>
        <FilteredSelectList
          id="region-select"
          options={regions}
          label="input.select.text"
          state={selectedRegion}
          onChangeHandler={onRegionChange}
          isRegion={true}
        />
        <Input
          labelText="input.street.text"
          id="street"
          name="street"
          type="text"
          htmlFor="street"
          placeholder="input.street.text"
          value={street}
          onChange={(e) => {
            setStreet(e.target.value);
          }}
        />
        <div className="plz-city-flex">
          <Input
            labelText="input.plz.text"
            id="plz"
            name="plz"
            type="text"
            htmlFor="plz"
            placeholder="input.plz.text"
            value={postalCode}
            onChange={(e) => {
              setPostalCode(postalCodeFormatter(postalCode, e.target.value));
            }}
          />
          <Input
            labelText="input.city.text"
            id="city"
            name="city"
            htmlFor="city"
            placeholder="input.city.text"
            type="text"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
          />
        </div>
        <HiddenSubmitInput />
      </Form>

      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content" />

      <WeiterButton
        disabled={validation()}
        path={ApplicationRoute.foreignRegistrationRegistrationName}
        onClick={onClickHandler}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </section>
  );
};

export default RegionSelection;