import { useTranslation } from "react-i18next";
import WeiterButton from "../../registration/utils/WeiterButton";
import ChoiceBox from "./ChoiceBox";

const FirstPushStepComponentReceivingPush = ({
  onSubmit,
  onChoiceHandler,
  choices,
  checked,
  onCheckedHandler,
  isValid,
  className,
  weiterText,
  showTopPart,
  city,
  reason,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`push__container ${className ?? ""}`}>
      {showTopPart &&
        <div>
          <div className="push__container__top--container">
            <img
              src="./images/common/redcrisis.png"
              className="push__container__top--container__alert-triangle"
              alt=""
            />
          </div>
          <p className="push__container__message">{t(reason)}</p>
        </div>
      }

      <p className="push__container__assistance-text">
        {t("pages.pushStep1.assistanceText")}
      </p>

      <ChoiceBox
        id="needHelp"
        onClick={onChoiceHandler}
        text="pages.pushStep1.yes"
        active={choices.needHelp}
      />
      <ChoiceBox
        id="noHelp"
        onClick={onChoiceHandler}
        text="pages.pushStep1.no"
        active={choices.noHelp}
      />

      <div className="push__container__toggle-container">
        <div className="push__container__toggle-container__toggle">
          <div className="slider-checkbox">
            <input
              id="sliderInput"
              value={checked}
              onChange={onCheckedHandler}
              type="checkbox"
            />
          </div>
        </div>

        <label
          htmlFor="sliderInput"
          className="push__container__toggle-container__text"
        >
          {t("pages.pushStep1.slider")}
        </label>
      </div>

      <WeiterButton
        id="pushNextButtonPushStep"
        style={{ marginTop: "2.6rem", display: isValid() ? "block" : "none" }}
        text={weiterText}
        positioned="static"
        path="./"
        onClick={onSubmit}
      />
    </div>
  );
};

export default FirstPushStepComponentReceivingPush;