import { useTranslation } from "react-i18next";

const ValidationRow = ({ isValid, validationText, tooltipText, id }) => {
  const { t } = useTranslation();

  return (
    <div className="validation-row__container">
      {isValid ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          className="validation-row__container__ico"
          aria-label={t("terms.erfuellt")}
          role="img"
        >
          <title>{t("terms.erfuellt")}</title>
          <g fill="none" fillRule="evenodd">
            <g fill="#37783E" fillRule="nonzero">
              <g>
                <path
                  d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8M6.4 12l-4-4 1.128-1.128L6.4 9.736l6.072-6.072L13.6 4.8 6.4 12z"
                  transform="translate(-829 -470) translate(829 470)"
                />
              </g>
            </g>
          </g>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          className="validation-row__container__ico"
          aria-label={t("terms.nicht_erfuellt")}
          role="img"
        >
          <title>{t("terms.nicht_erfuellt")}</title>
          <g fill="none" fillRule="evenodd">
            <g fill="#CCD9DE" fillRule="nonzero">
              <g>
                <path
                  d="M8 0C3.582 0 0 3.582 0 8c0 2.122.843 4.157 2.343 5.657S5.878 16 8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8z"
                  transform="translate(-829 -470) translate(829 470)"
                />
              </g>
            </g>
          </g>
        </svg>
      )}
      <span id={id} aria-relevant="all" className="validation-row__container__text">
        {t(validationText)}
        {tooltipText ? (<span className="tooltiptext">{t(tooltipText)}</span>) : ("")}
      </span>
    </div>
  );
};

export default ValidationRow;