//use example
//
// const example = () => {
// fetcher(
//   "URL",
//   "POST",
//   setData,
//   setError,
//   setLoading,
//   setSubmitting,
//   false,
//   "application/json",
//   JSON.stringify(body)
// );
//};

import ApplicationRoute from "../config/routes";
import { isMobileEnv, removeIsLoggedStorage } from "../utils/helpers";

const contentTypes = {
  json: "application/json",
  urlencoded: "application/x-www-form-urlencoded;charset=UTF-8",
};

const fetcher = async (
  url,
  method,
  setData,
  setError,
  setLoading,
  setSubmitting,
  hasResponse,
  contentType,
  body,
  redirect = true,
  isLogin = false,
  cacheRequest = false,
  autoCacheOnLoad = false
) => {
  if (contentType) {
    if (contentType === contentTypes.json) {
      contentType = contentTypes.json;
    } else if (contentType === contentTypes.urlencoded) {
      contentType = contentTypes.urlencoded;
    }
  }

  let response;
  try {
    setSubmitting(true);
    setLoading(true);

    response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": contentType,
      },
      credentials: "include",
      body,
    }, cacheRequest, autoCacheOnLoad, hasResponse ? "json" : "text");
    if (response?.ok) {
      let json;
      if (hasResponse) {
        json = await response.json();
      } else {
        json = await response.text();
      }

      setData(json);
      setError(false);
    } else {
      throw response;
    }
  } catch (e) {
    setError(e);
  } finally {
    setSubmitting(false);
    setLoading(false);

    // if (response?.status === 302) {
    //   return;
    // }

    if (
      !isMobileEnv() &&
      !isLogin &&
      (method === "PUT" || method === "POST") &&
      (response?.status === 401 || response?.status === 403 || response?.status === 405)
    ) {
      localStorage.setItem("autoLoggedOut", "y");
      removeIsLoggedStorage();
      window.location = "/#" + ApplicationRoute.home;
    } else {
      if (response?.status === 405) {
        removeIsLoggedStorage();
      }

      if (redirect && (response?.status === 401 || response?.status === 403)) {
        removeIsLoggedStorage();
        window.location = "/#" + ApplicationRoute.home;
      } else if (!response?.ok && redirect) {
        if (isMobileEnv()) {
          if (response?.status === 405) {
            localStorage.setItem("autoLoggedOut", "y");

            window.location = window.location.href.substring(0, window.location.href.lastIndexOf("#") + 1) + "/";
          } else {
            window.location = window.location.href.substring(0, window.location.href.lastIndexOf("#") + 1) + "/error";
          }
        } else {
          window.location = "/#" + ApplicationRoute.errorPage;
        }
      }
    }
  }
};

export default fetcher;