import { useTranslation } from "react-i18next";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput.jsx";
import HorizontalRule from "../../../common/HorizontalRule.jsx";
import MetaDecorator from "../../../utils/MetaDecorator.jsx";
import CheckBox from "./CheckBox.jsx";
import Form from "./Form.jsx";
import WeiterButton from "./WeiterButton.jsx";

const DataConfirmationForm = ({
  checked,
  submittingNow,
  onSubmitHandler,
  onChangeHandler,
  onClickHandler,
  route,
  pageTitle
}) => {
  const { t } = useTranslation();

  return (
    <div className="login-middle-container">
      <MetaDecorator title={pageTitle} />
      <p className="content-title" style={{ margin: "3.2rem auto" }}>{t("pages.dsgvo.confirmTitle")}</p>
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form className="" id="form" onSubmit={onSubmitHandler} ariaLabelledBy="confirmitation">
        <div>
          <CheckBox
            htmlFor="confirmBox"
            id="confirmBox"
            state={checked}
            jsx={t("pages.dsgvo.checkbox").replace("%link%", `<a href="https://www.bmeia.gv.at/reise-aufenthalt/auslandsservice/datenschutz/" target="_blank" rel="noopener noreferrer" style="color: #000;">${t("pages.dsgvo.title")}</a>`)}
            onChangeHandler={onChangeHandler}
          />
        </div>
        <HiddenSubmitInput />
        {/* <Link
            className="data-protection-link"
            to={ApplicationRoute.dataProtection}
            aria-label={t("pages.dsgvo.title2")}
            >
            {t("pages.dsgvo.title2")}
            </Link> */}
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <WeiterButton
        disabled={!checked || submittingNow()}
        onClick={onClickHandler}
        path={route}
        positioned="static"
      />
    </div>
  )
};

export default DataConfirmationForm;