import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { loginRedirectsChecked } from "../components/registration/utils/loginHelper.js";
import ApplicationRoute from "../config/routes";
import { loggedInRoutes, loggedOutRoutes, mobileOnlyRoutes } from "../config/routesRole";
import UserContext from "../context/UserContext.js";
import { getIsLogged, isMobileEnv, removeAllSlashes } from "../utils/helpers";

const BodyContainer = ({ children }) => {
  const location = useLocation();

  const [theme, setTheme] = useState("");
  const history = useHistory();

  const { user } = useContext(UserContext);

  const setBackgroundTheme = () => {
    setTheme(() => {
      const currPath = removeAllSlashes(location.pathname);

      const toChange = (checkPath) => {
        return currPath.includes(removeAllSlashes(checkPath));
      };

      if (toChange(ApplicationRoute.dot)) {
        return "body-container-dot"
      }

      if (
        toChange(ApplicationRoute.registerPassword) ||
        toChange(ApplicationRoute.editTravel.replace(":id", "")) ||
        toChange(ApplicationRoute.home)
      ) {
        return "body-container-24";
      }

      if (
        currPath === "" ||
        currPath === removeAllSlashes(ApplicationRoute.home) ||
        currPath === removeAllSlashes(ApplicationRoute.register) ||
        currPath === removeAllSlashes(ApplicationRoute.travelDashboard)
      ) {
        return "loginsignin-body-background body-container-24--special";
      }

      return "";
    });
  };

  const setMainClass = () => {
    const root = document.documentElement;

    const currPath = removeAllSlashes(location.pathname);

    const toChange = (checkPath) => {
      return currPath.includes(removeAllSlashes(checkPath));
    };

    if (toChange(ApplicationRoute.secondPushStep)) {
      root.classList.add("main-root-background--lightgreen");
    } else {
      root.classList.remove("main-root-background--lightgreen");
    }

    if (toChange(ApplicationRoute.pushEditFromDashboard)) {
      root.classList.add("main-root-background--brightblue");
    } else {
      root.classList.remove("main-root-background--brightblue");
    }

    if (toChange(ApplicationRoute.vertretungen)) {
      return "map-style-fix";
    }

    //Change backround here!
    if (
      location.pathname === "/" ||
      removeAllSlashes(location.pathname) === removeAllSlashes(ApplicationRoute.register) ||
      ApplicationRoute.travelDashboard.includes(currPath) ||
      location.pathname.includes("/travel-dashboard") ||
      toChange(ApplicationRoute.registerForgottenPassword) ||
      toChange(ApplicationRoute.errorPage) ||
      toChange(ApplicationRoute.renewPassword) ||
      toChange(ApplicationRoute.addRelative1.substring(0, ApplicationRoute.addRelative1.length - 1))
    ) {
      root.classList.add("main-root-background--grey");

      return "body-container-24";
    } else {
      root.classList.remove("main-root-background--grey");
    }

    if (
      currPath.includes(removeAllSlashes(ApplicationRoute.register)) ||
      currPath.includes(removeAllSlashes(ApplicationRoute.idAustria)) ||
      currPath === removeAllSlashes(ApplicationRoute.editProfile) ||
      currPath === removeAllSlashes(ApplicationRoute.addTravel) ||
      currPath === removeAllSlashes(ApplicationRoute.addTravelEmail) ||
      currPath === removeAllSlashes(ApplicationRoute.addTravelNationality) ||
      currPath === removeAllSlashes(ApplicationRoute.addTravelEdit) ||
      currPath === removeAllSlashes(ApplicationRoute.addTravelTourOperator) ||
      currPath === removeAllSlashes(ApplicationRoute.addTravelCompanion) ||
      currPath === removeAllSlashes(ApplicationRoute.addTravelGender) ||
      currPath === removeAllSlashes(ApplicationRoute.addTravelMobilePhone) ||
      currPath === removeAllSlashes(ApplicationRoute.addTravelCompanionName) ||
      currPath === removeAllSlashes(ApplicationRoute.addTravelCompanionDateOfBirth) ||
      currPath === removeAllSlashes(ApplicationRoute.addTravelCompanionAddedSuccess) ||
      currPath === removeAllSlashes(ApplicationRoute.foreignRegistrationAddRelatives) ||
      currPath === removeAllSlashes(ApplicationRoute.foreignRegistrationRegionSelection) ||
      currPath === removeAllSlashes(ApplicationRoute.foreignRegistrationAddRelativesBirthdate) ||
      currPath === removeAllSlashes(ApplicationRoute.foreignRegistrationAddRelativesEmail) ||
      currPath === removeAllSlashes(ApplicationRoute.foreignRegistrationAddRelativesGender) ||
      currPath === removeAllSlashes(ApplicationRoute.foreignRegistrationAddRelativesName) ||
      currPath === removeAllSlashes(ApplicationRoute.foreignRegistrationAddRelativesNationality) ||
      currPath === removeAllSlashes(ApplicationRoute.foreignRegistrationAddRelativesNumber) ||
      currPath === removeAllSlashes(ApplicationRoute.foreignRegistrationAddRelativesSuccess)
    ) {
      return "body-container-24";
    } else {
      return "body-container";
    }
  };

  const setLastVisitedPathLocalStorage = () => {
    localStorage.setItem("lastVisitedPath", location.pathname);
  };

  const redirect = () => {
    const toCheck = removeAllSlashes(location.pathname);
    let redirected = false;

    if (!isMobileEnv()) {
      Object.values(mobileOnlyRoutes).forEach((r) => {
        if (toCheck.includes(removeAllSlashes(r))) {
          redirected = true;

          if (!getIsLogged()) {
            history.push(ApplicationRoute.home);
          } else {
            history.push(ApplicationRoute.TravelDashboardNoPermissions);
          }
        }
      });
    }

    if (!redirected) {
      Object.values(loggedInRoutes).forEach((r) => {
        if (toCheck.includes(removeAllSlashes(r))) {
          if (!getIsLogged()) {
            history.push(ApplicationRoute.home);
          } else {
            loginRedirectsChecked(user, history, toCheck);
          }
        }
      });

      Object.values(loggedOutRoutes).forEach((r) => {
        if (toCheck.includes(removeAllSlashes(r)) || location.pathname === ApplicationRoute.home) {
          if (getIsLogged()) {
            history.push(ApplicationRoute.TravelDashboardNoPermissions);
          }
        }
      });
    }
  };

  useEffect(() => {
    setBackgroundTheme();

    window.scrollTo(0, 0);
    activateBodyScrolling();

    const root = document.documentElement;
    root.classList.remove("error-page-background");

    setLastVisitedPathLocalStorage();

    redirect();
  }, [location]);

  function activateBodyScrolling() {
    if (document.getElementsByTagName("body").item(0).classList.contains("overflow-hidden"))
      document.getElementsByTagName("body").item(0).classList.remove("overflow-hidden");
  }

  const mc = setMainClass();
  if (mc === "map-style-fix") {
    return (
      <div id="main" className={`body-container-24-2 ${mc}`} role="main">
        {children}
      </div>
    );
  }

  return (
    <div id="main" className={`${setMainClass()} ${theme}`} role="main">
      {children}
    </div>
  );
};

export default BodyContainer;