import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import userSuccess from "../../../assets/icons/user-added-success.PNG";
import HorizontalRule from "../../../common/HorizontalRule";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import TravelContext from "../../../context/TravelContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import PageTitle from "../utils/PageTitle";
import WeiterButton from "../utils/WeiterButton";

const AddTravelCompanionAddedSuccess = () => {
  const { t } = useTranslation();
  const { setHeader } = useContext(HeaderContext);
  const [name, setName] = useState("");
  const [ready, setReady] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t("pageTitle.createTripPages"),
        progress: parseInt((18 / 19) * 100),
      };
    });

    const groupMemberIndex =
      travel.TravelGroupMember.length > 0
        ? travel.TravelGroupMember.length - 1
        : 0;

    setName(
      travel.TravelGroupMember[groupMemberIndex].FirstName +
      " " +
      travel.TravelGroupMember[groupMemberIndex].LastName
    );
  }, []);

  const { travel, setTravel } = useContext(TravelContext);

  const updateTravel = () => {
    setTravel((curr) => {
      return {
        ...curr,
        TravelGroupMember: curr.TravelGroupMember.concat([{}]),
      };
    });
  };

  const onClickHandler = (e) => {
    e.preventDefault();
    setReady(() => true);
    updateTravel();
  };

  useEffect(() => {
    if (ready) {
      history.push(ApplicationRoute.addTravelCompanionName);
    }
  }, [travel]);

  const onClickNext = (e) => {
    e.preventDefault();
    setTravel((curr) => {
      let newArr = curr.TravelGroupMember.filter((member) => {
        return Object.keys(member).length > 4;
      });
      return { ...curr, TravelGroupMember: newArr };
    });

    history.push(ApplicationRoute.addTravelEdit);
  };

  return (
    <div className="registration-middle-container relative-success-page">
      <MetaDecorator title="pageTitle.createTripPages" />
      <PageTitle
        id="success-page-title"
        ariaLabel={`${name} ${t("input.mitreisende.addedAsOne")}`}
        text={`${name} ${t("input.mitreisende.addedAsOne")}`}
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <div
        role="img"
        className="picture__container picture__container--mtb-60 overlapping-pic-fix"
      >
        <img alt="user-success" src={userSuccess} />
      </div>
      <HorizontalRule className="horizontal-rule-desktop" />
      <WeiterButton
        disabled={false}
        path={ApplicationRoute.addTravelCompanionName}
        ariaLabel="pages.relSuccess.text"
        text="pages.relSuccess.text"
        positioned="fixed"
        color="transperant"
        onClick={onClickHandler}
        className="fix1"
      //81px + 16px
      />

      <WeiterButton
        disabled={false}
        path={ApplicationRoute.addTravelEdit}
        onClick={onClickNext}
        positioned="fixed"
        className="fix2"
      />
    </div>
  );
};

export default AddTravelCompanionAddedSuccess;