import { atom, computed } from "nanostores";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import apipath from "../../apipath";
import UserContext from "../../context/UserContext";
import { autoCacheStoredCalls } from "../../services/caching";
import style from "./OfflineManager.module.scss";

const $onlineStatus = atom(true)
export const onlineStatus = computed($onlineStatus, v => v);
export const offlineStatus = computed($onlineStatus, v => !v)

async function checkOnlineStatus() {
  const url = `${apipath.path}healthcheck`
  const result = await new Promise((resolve) => {
    setTimeout(() => {
      resolve(false)
    }, 2000)
    fetch(url).then(() => {
      resolve(true)
    })
  });
  $onlineStatus.set(result)
}

const OfflineManager = () => {
  const { t } = useTranslation();

  const { offline, setOffline } = useContext(UserContext);
  useEffect(() => {
    offlineStatus.subscribe(x => {
      setOffline(x)
    })
  }, [setOffline])

  const [retry, setRetry] = useState();

  useEffect(() => {
    clearInterval(retry);
    setRetry(setInterval(checkOnlineStatus, 3000));
    checkOnlineStatus();
    autoCacheStoredCalls();

    return () => clearInterval(retry);
  }, [])

  if (!offline) return <></>

  return <>
    <div className={style.OfflineManager}>
      <div>
        <svg version="1.1" id="Layer_1" x="0px" y="0px" width={47} viewBox="0 0 30 30" style={{ enableBackground: "new 0 0 30 30" }}>
          <polygon className={style.IconStroke} points="27.92,27 2.08,27 15,3 " />
          <line className={style.IconStroke} x1="15" y1="9.93" x2="15" y2="17.77" />
          <line className={style.IconStroke} x1="15" y1="21.46" x2="15" y2="23.31" />
        </svg>
      </div>
      <div>
        <p>{t("pages.offlineManager.info1")}</p>
        <p>{t("pages.offlineManager.info2")}</p>
      </div>
    </div>
  </>
};

export default OfflineManager;