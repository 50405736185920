import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../../common/HorizontalRule";
import ApplicationRoute from "../../../../config/routes";
import HeaderContext from "../../../../context/HeaderContext";
import UserContext from "../../../../context/UserContext";
import MetaDecorator from "../../../../utils/MetaDecorator";
import {
  getEnumFromGender, getGenderFromText, getGenderTextFromEnum,
  getKeyByValue, prefilledUserData
} from "../../../../utils/helpers";
import ContentTitle from "../../../registration/utils/ContentTitle";
import ControledRadioInput from "../../../registration/utils/ControledRadioInput";
import Form from "../../../registration/utils/Form";
import WeiterButton from "../../../registration/utils/WeiterButton";

const initSelectValue = {
  male: false,
  female: false,
  other: false,
};

//HINT* NOT REGISTRATION
export function ForeignGender() {
  const history = useHistory();
  const { user, setUser } = useContext(UserContext);
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  const genders = useMemo(
    () => {
      return {
        male: t("input.gender.options.male"),
        female: t("input.gender.options.female"),
        other: t("input.gender.options.other")
      }
    },
    [t]
  );

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((5 / 19) * 100),
        title: t("pageTitle.foreignRegistration"),
        ariaLabel: t("pageTitle.foreignRegistration"),
      };
    });
  }, []);

  const [selectedValue, setSelectedValue] = useState(initSelectValue);

  const isValid = () => {
    return getKeyByValue(selectedValue, true);
  };

  const updateUser = () => {
    setUser((curr) => {
      return {
        ...curr,
        Gender: getEnumFromGender(getKeyByValue(selectedValue, true), t),
      };
    });
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onChangeHandler = (e) => {
    setSelectedValue(() => {
      return { ...initSelectValue, [e.target.id]: true };
    });
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history.push(ApplicationRoute.foreignRegistrationRegistrationNumber);
    }
  };

  useEffect(() => {
    if (user && user.Gender) {
      setSelectedValue(() => {
        return { ...initSelectValue, [getGenderFromText(getGenderTextFromEnum(user.Gender, t), t)]: true };
      });
    }
  }, [user]);

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title="pageTitle.foreignRegistration" />
      <ContentTitle
        id="foreign-registration-gender"
        text={prefilledUserData("prefilledUserData.geschlecht")}
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        id="form"
        onSubmit={onsubmitHandler}
        ariaLabelledBy="foreign-registration-gender"
        className="max-width-380"
      >
        <ControledRadioInput
          role="radio"
          id="male"
          value="100000000"
          name="gender"
          textInfo={genders.male}
          ariaLabel={genders.male}
          checked={selectedValue.male}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.male}
        />
        <ControledRadioInput
          role="radio"
          id="female"
          value="100000001"
          name="gender"
          textInfo={genders.female}
          ariaLabel={genders.female}
          checked={selectedValue.female}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.female}
        />
        <ControledRadioInput
          role="radio"
          id="other"
          value="100000002"
          name="gender"
          textInfo={genders.other}
          ariaLabel={genders.other}
          checked={selectedValue.other}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.other}
        />

        <HiddenSubmitInput />
      </Form>

      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content" />

      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationRegistrationNumber}
        onClick={onClickHandler}
        className=" floating-weiter-buton"
      />
    </section>
  );
}