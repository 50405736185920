const CrisisPhone = ({ id = "phone", phone, style, className }) => {
  const hrefTel = phone.replaceAll(' ', '')
  const newId = `${id}-${hrefTel}`;
  const hrefId = `${id}-${hrefTel}-href`;

  return (
    <a
      id={newId}
      href={`tel:${hrefTel}`}
      style={style}
      className={`crisis-phone-container ${className ?? ""}`}
    >
      <img
        className="crisis-phone-container__telephone-img"
        src="images/common/telephone.svg"
        alt=""
      />
      <span
        id={hrefId}
        className="crisis-phone-container__telephone"
        href={`tel:${hrefTel}`}
      >
        {phone}
      </span>
    </a>
  );
};

export default CrisisPhone;