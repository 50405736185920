import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import TravelContext from "../../../context/TravelContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import { getKeyByValue } from "../../../utils/helpers";
import ContentTitle from "../../registration/utils/ContentTitle";
import ControledRadioInput from "../../registration/utils/ControledRadioInput";
import Info from "../../registration/utils/Info";
import WeiterButton from "../../registration/utils/WeiterButton";
import Form from "../utils/Form";

let initSelectValue = {
  yes: false,
  no: false,
};

const AddTravelCompanion = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { setTravel } = useContext(TravelContext);

  const [weiterLink, setWeiterLink] = useState("");

  const compaion = useMemo(
    () => {
      return {
        yes: t("pages.step1Companion.select1Yes"),
        no: t("pages.step1Companion.select1No"),
      }
    }, [t]);

  const { setHeader } = useContext(HeaderContext);
  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t("pageTitle.createTripPages"),
        progress: parseInt((11 / 19) * 100),
      };
    });
  }, []);

  const [selectedValue, setSelectedValue] = useState(initSelectValue);

  const isValid = () => {
    return getKeyByValue(selectedValue, true);
  };

  const updateTravel = () => {
    const isGroup = getKeyByValue(selectedValue, true) === "no";

    setTravel((curr) => {
      return {
        ...curr,
        IsAlone: !isGroup,
        TravelGroupMember: [],
      };
    });
  };

  const onClickHandler = () => {
    updateTravel();
  };

  const onChangeHandler = (e) => {
    setSelectedValue(() => {
      return { ...initSelectValue, [e.target.id]: true };
    });

    if (e.target.id === "yes") {
      setWeiterLink(ApplicationRoute.addTravelEdit);
    } else {
      setWeiterLink(ApplicationRoute.addTravelCompanionName);
    }
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();
    if (isValid()) {
      updateTravel();

      history.push(weiterLink);
    }
  };

  return (
    <div className="registration-middle-container">
      <MetaDecorator title="pageTitle.createTripPages" />
      <ContentTitle
        id="pages.step1Companion.formLabel"
        style={{ marginTop: "1.6rem", marginBottom: "0px" }}
        text="pages.step1Companion.contentTitle"
      />
      <Info
        style={{ marginTop: "0.8rem", marginBottom: "2.4rem" }}
        text="input.mitreisende.info1"
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form id="form" onSubmit={onsubmitHandler} ariaLabelledBy="pages.step1Companion.formLabel">
        <ControledRadioInput
          id="yes"
          value="Yes"
          name="compainion"
          textInfo={compaion.yes}
          checked={selectedValue.yes}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.yes}
        />
        <ControledRadioInput
          id="no"
          value="No"
          name="compainion"
          textInfo={compaion.no}
          checked={selectedValue.no}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.no}
          style={{ marginTop: "18px" }}
        />

        <HiddenSubmitInput />
        <HorizontalRule className="horizontal-rule-desktop" />
        <WeiterButton
          disabled={!isValid()}
          path={weiterLink}
          onClick={onClickHandler}
          className="floating-weiter-buton"
        />
      </Form>
    </div>
  );
};

export default AddTravelCompanion;