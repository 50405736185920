// Nicht übersetzen, da nicht verwendet wird
const informationLists = [
  {
    id: 1,
    text: "Allgemeine Informationen",
    body: `Text allgemeine Informationen. In Notfällen ist der Bereitschaftsdienst
      des österreichischen Außenministeriums rund um die Uhr für Sie unter +43
      1 901154411 bzw. +43 5011504411 erreichbar.
    
    
      Darüber hinaus sind zu Ihrer Sicherheit bei Notfällen im Ausland für Sie
      auch die österreichischen Botschaften und Konsulate direkt unter den in
      den Länderinformationen angeführten Telefonnummern erreichbar.`,
  },
  {
    id: 2,
    text: "Info Reiseregistrierung",
    body: `Die Reiseregistrierung ist eine Serviceleistung des Außenministeriums, die 
      allen Österreicherinnen und Österreichern kostenfrei zur Verfügung steht. Durch die
       Registrierung können wir Sie in einer Krisensituation, zum Beispiel bei politischen
        Unruhen oder bei einer Naturkatastrophe, rascher kontaktieren, informieren und 
        gegebenenfalls unterstützen.
  
      Die Reiseregistrierung ist für jede Auslandsreise möglich. Wir empfehlen Ihnen jedoch,
       sich insbesondere dann zu registrieren, wenn Sie planen, Länder mit „Hoher 
       Sicherheitsgefährdung“ zu bereisen.
      
      Nützen Sie die Serviceleistung der Reiseregistrierung und registrieren Sie Ihre 
      Reise online oder über die Auslandsservice App! 
      
      Wir weisen ausdrücklich darauf hin, dass eine Reiseregistrierung die Eigenverantwortung 
      nicht ersetzt!`,
  },
  {
    id: 3,
    text: "Datenschutz Außenministerium",
    body: "",
  },
  {
    id: 4,
    text: "Bildquellen",
    body: "",
  },
  {
    id: 5,
    text: "Kontakt",
    body: "",
  },
  {
    id: 6,
    text: "Datenschutzerklärung",
    body: "",
  },
  {
    id: 7,
    text: "Barrierefreiheit",
    body: "",
  },
  {
    id: 8,
    text: "Impressum",
    body: "",
  },
];

export default informationLists;