import { useTranslation } from "react-i18next";

const InputValidation = ({ text, isHidden, style, alert = true }) => {
  const { t } = useTranslation();

  return (
    <div style={style} aria-relevant="all" className="input-validation" aria-hidden={isHidden}>
      <div className="input-validation__text" id="hint" hidden={isHidden}>
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns-xlink="http://www.w3.org/1999/xlink"
        >
          <title>{t("terms.info")}</title>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Registration_Flow_15/23_Passwort-4-Copy" transform="translate(-20.000000, -476.000000)">
              <g id="Input-/-textfield-+-icon-Copy" transform="translate(20.000000, 411.000000)">
                <g id="Icons/Error-Message" transform="translate(-0.000000, 65.000000)">
                  <g
                    id="Group"
                    transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                  >
                    <circle id="Oval" fill="#B9131B" cx="8" cy="8" r="8" />
                    <path
                      d="M8.00303951,5 C8.55015198,5 9,4.55692308 9,4.00307692 C9,3.44307692 8.55015198,3 8.00303951,3 C7.45592705,3 7,3.44307692 7,4.00307692 C7,4.55692308 7.45592705,5 8.00303951,5 Z M9,12.4511719 L9,6 L7,6 L7,12.4511719 L9,12.4511719 Z"
                      id="i"
                      fill="#FFFFFF"
                      fillRule="nonzero"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <span role={alert ? "alert" : null}>{t(text)}</span>
      </div>
    </div>
  );
};

export default InputValidation;