import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import WeiterButton from "../components/registration/utils/WeiterButton";
import ApplicationRoute from "../config/routes";
import HeaderContext from "../context/HeaderContext";
import MetaDecorator from "../utils/MetaDecorator";
import { getIsLogged } from "../utils/helpers";
import SadFace from "./sadFace";

const PageNotFound = () => {
  const { t } = useTranslation();
  const { setHeader } = useContext(HeaderContext);

  const history = useHistory();

  useEffect(() => {
    const root = document.documentElement;
    root.classList.add("error-page-background");

    setHeader((curr) => {
      return {
        ...curr,
        inUse: "main",
        title: t("pages.login.title"),
        description: t("pages.login.description"),
      };
    });
  }, []);

  const navigate = (e) => {
    e.preventDefault();
    history.push(getIsLogged() ? ApplicationRoute.TravelDashboardNoPermissions : ApplicationRoute.home);
  };

  return (
    <div className="page-not-found registration-middle-container">
      <MetaDecorator title="pages.notFound.header" />
      <div className="page-not-found__content-wrapper">
        <SadFace />
        <h1 className="page-not-found__header">{t("pages.notFound.header")}</h1>
        <p className="page-not-found__p">{t("pages.notFound.text")}</p>
      </div>

      <WeiterButton
        onClick={navigate}
        disabled={false}
        path={ApplicationRoute.TravelDashboardNoPermissions}
        ariaLabel={`${t("pages.contactPerson.weiter")} ${ApplicationRoute.TravelDashboardNoPermissions}`}
        text="pages.error.nextText"
        style={{ marginBottom: "0.6rem" }}
        positioned="fixed"
      />
    </div>
  );
};

export default PageNotFound;