import { useTranslation } from "react-i18next";

const PageTitle = ({ text, ariaLabel, id }) => {
  const { t } = useTranslation();

  return (
    <p
      id={id}
      className="page-title"
      role="heading"
      aria-label={t(ariaLabel)}
    >
      {t(text)}
    </p>
  );
};

export default PageTitle;