import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import apipath from "../../../apipath";
import HorizontalRule from "../../../common/HorizontalRule";
import Mail from "../../../common/Unions/Mail";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import UserContext from "../../../context/UserContext";
import fetcher from "../../../services/fetcher";
import MetaDecorator from "../../../utils/MetaDecorator";
import Info from "../../registration/utils/Info";
import ContentTitle from "../utils/ContentTitle";
import WeiterButton from "../utils/WeiterButton";

//HINT* REGISTRATION
const SentEmailPage = () => {
  const { setHeader } = useContext(HeaderContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const [, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [timerDissable, setTimerDissable] = useState(false);

  const resendInvitation = () => {
    fetcher(
      `${apipath.path}users/resendinvitation`,
      "POST",
      setData,
      setError,
      setLoading,
      setSubmitting,
      false,
      "application/json",
      JSON.stringify({ Email: user.Email }),
      false
    );
  };

  const timerDissabling = () => {
    setTimeout(() => {
      setTimerDissable(() => false);
    }, 30000);
  };

  useEffect(() => {
    if (!loading && !error) {
      timerDissabling();
    }
  }, [loading, error]);

  const onClickHandler = (e) => {
    setTimerDissable(() => true);

    e.preventDefault();
    resendInvitation();
  };

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((5 / 6) * 100),
        title: t("pageTitle.registrationPages"),
        ariaLabel: t("pages.register5.ariaTitle"),
      };
    });
  }, []);

  const submittingNow = () => {
    return submitting && loading;
  };

  return (
    <div className="registration-middle-container registration-middle-container--se">
      <MetaDecorator title="pageTitle.registrationPages" />
      <ContentTitle
        id="sent-email"
        style={{ marginTop: "1.6rem", marginBottom: "1.6rem" }}
        text="pages.register5.contentTitle"
      />
      <Info
        style={{ lineHeight: "normal", marginBottom: "0px" }}
        id="textInfo"
        text={t("pages.register5.info1").replace(" %1 ", user.Email ? " (" + user.Email + ") " : " ")}
        role=" "
      />
      <Info
        style={{ lineHeight: "normal", marginBottom: "0px" }}
        id="textInfo"
        text="pages.register5.info2"
        role=" "
      />

      <HorizontalRule className="horizontal-rule-desktop" />

      <Mail type="notification" className="global-flex-center" style={{ marginTop: "4rem" }} />
      <HorizontalRule className="horizontal-rule-desktop" />

      <WeiterButton
        color="transperant"
        style={{ bottom: "12.5rem" }}
        text="pages.register5.home"
        ariaLabel="pages.register5.home"
        path={ApplicationRoute.home}
      />

      <WeiterButton
        id="sentemailPage"
        text="pages.register5.senden"
        ariaLabel="pages.register5.senden"
        path={ApplicationRoute.registerVerifiedEmail}
        onClick={onClickHandler}
        disabled={submittingNow() || timerDissable}
      />
    </div>
  );
};

export default SentEmailPage;