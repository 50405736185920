import DOMPurify from 'dompurify'
import { useTranslation } from "react-i18next"

const useCleanTranslation = (ns?: string | undefined) => {
    const { t } = useTranslation(ns ?? "dot")
    const ct = (key: string) => {
        return DOMPurify(window).sanitize(t(key), {
            ADD_ATTR: ["target"]
        })
    }
    return { ct }
}

export default useCleanTranslation