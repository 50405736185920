import { useTranslation } from "react-i18next";

const Mail = ({ type, style, className }) => {
  const { t } = useTranslation();

  return (
    <div role="img" alt="" style={style} className={className}>
      <div className="email-container">
        {type === "success" ? (
          <svg
            width="119px"
            height="84px"
            viewBox="0 0 119 84"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>{t("pages.mailIcon.success")}</title>
            <defs>
              <filter id="filter-1">
                <feColorMatrix
                  in="SourceGraphic"
                  type="matrix"
                  values="0 0 0 0 0.619608 0 0 0 0 0.019608 0 0 0 0 0.160784 0 0 0 1.000000 0"
                />
              </filter>
            </defs>
            <g
              id="Icons/Verification-mail-successfull"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Icons/Mail-2"
                transform="translate(2.000000, 2.000000)"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <g
                  id="Group-4"
                  transform="translate(-0.000000, 0.000000)"
                  filter="url(#filter-1)"
                >
                  <g transform="translate(0.000000, -0.000000)">
                    <polygon
                      id="Stroke-1"
                      stroke="#000000"
                      strokeWidth="3"
                      points="0 66 101.294702 66 101.294702 0 0 0"
                    />
                    <polyline
                      id="Stroke-3"
                      stroke="#000000"
                      strokeWidth="3"
                      points="0 12.014404 50.7019868 39.1137417 101.294702 12.014404"
                    />
                  </g>
                </g>
              </g>
              <g
                id="Icons-/-checkbox-success"
                transform="translate(87.000000, 51.948718)"
              >
                <circle
                  id="Oval"
                  fill="#FFFFFF"
                  cx="16"
                  cy="16"
                  r="14"
                />
                <path
                  d="M16,0 C7.2,0 0,7.2 0,16 C0,24.8 7.2,32 16,32 C24.8,32 32,24.8 32,16 C32,7.2 24.8,0 16,0 M12.8,24 L4.8,16 L7.056,13.744 L12.8,19.472 L24.944,7.328 L27.2,9.6 L12.8,24 Z"
                  id="Shape"
                  fill="#37783E"
                  fillRule="nonzero"
                />
              </g>
            </g>
          </svg>
        ) : (
          <svg
            width="119px"
            height="84px"
            viewBox="0 0 119 84"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>{t("pages.mailIcon.notification")}</title>
            <defs>
              <filter id="filter-1">
                <feColorMatrix
                  in="SourceGraphic"
                  type="matrix"
                  values="0 0 0 0 0.619608 0 0 0 0 0.019608 0 0 0 0 0.160784 0 0 0 1.000000 0"
                />
              </filter>
            </defs>
            <g
              id="Icons/Got-verification-mail"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Icons/Mail-2"
                transform="translate(2.000000, 16.000000)"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <g
                  id="Group-4"
                  transform="translate(-0.000000, 0.000000)"
                  filter="url(#filter-1)"
                >
                  <g transform="translate(0.000000, -0.000000)">
                    <polygon
                      id="Stroke-1"
                      stroke="#000000"
                      strokeWidth="3"
                      points="0 66 101.294702 66 101.294702 0 0 0"
                    />
                    <polyline
                      id="Stroke-3"
                      stroke="#000000"
                      strokeWidth="3"
                      points="0 12.014404 50.7019868 39.1137417 101.294702 12.014404"
                    />
                  </g>
                </g>
              </g>
              <g id="Group" transform="translate(87.000000, 0.000000)">
                <g id="Group-11">
                  <path
                    d="M16,0 C24.8369231,0 32,7.16307692 32,16 C32,24.8369231 24.8369231,32 16,32 C7.16307692,32 0,24.8369231 0,16 C0,7.16307692 7.16307692,0 16,0"
                    id="Fill-1"
                    fill="#C92C0C"
                  />
                  <path
                    d="M16,0 C24.8369231,0 32,7.16307692 32,16 C32,24.8369231 24.8369231,32 16,32 C7.16307692,32 0,24.8369231 0,16 C0,7.16307692 7.16307692,0 16,0 Z"
                    id="Stroke-3"
                  />
                </g>
                <path
                  d="M21.8666667,25.3333333 L21.8666667,23.3437827 L17.76,23.3437827 L17.76,6.66666667 L15.8,6.66666667 C15.2607407,6.97875305 14.6540741,7.24695228 13.98,7.47126437 C13.3059259,7.69557645 12.5125926,7.88575409 11.6,8.04179728 L11.6,8.04179728 L11.6,9.59247649 L15.2088889,9.59247649 L15.2088889,23.3437827 L10.6666667,23.3437827 L10.6666667,25.3333333 L21.8666667,25.3333333 Z"
                  id="1"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                />
              </g>
            </g>
          </svg>
        )}
      </div>
    </div>
  );
};

export default Mail;