import { useTranslation } from "react-i18next";
import Cross from "../svg/Cross";

const CountryListInput = ({ hidden, value, setValue, onCrossClick, id = "mapInputId", labelText = "terms.search2" }) => {
  const { t } = useTranslation();

  const isHidden = hidden ? "none" : "block";
  const isHiddenLabel = value ? "block" : "none";

  return (
    <div style={{ display: isHidden }} className="cl-input">
      <div className="cl-input-wrap">
        <label style={{ display: isHiddenLabel }} className="search-input__label" htmlFor={id}>
          {t(labelText)}
        </label>
        <input
          id={id}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          className="search-input__input"
          placeholder={t("terms.search2")}
          autoComplete="off"
        />
        <Cross onClick={onCrossClick} className="search-input__cross" color="black" ariaLabel="terms.close" />
      </div>
    </div>
  );
};

export default CountryListInput;