import React from "react";
import { useTranslation } from "react-i18next";
import useCleanTranslation from "../DotWebsite/Hooks/UseCleanTranslation";

const AnswerRegister = () => {
    const { t } = useTranslation();
    const { ct } = useCleanTranslation("");

    return (
        <div className="faq-answer">
            <p className="answer-text" dangerouslySetInnerHTML={{ __html: ct("pages.faq.answerRegister_1") }}></p>
            <div className="answer-image-container">
                <div className="answer-image-box">
                    <img className="" src="./images/faqs/register-1.png" alt="" />
                </div>
            </div>
            <p className="answer-text">
                {t("pages.faq.answerRegister_2")}
            </p>
            <ul>
                <li>{t("errors.passwordNotInRange")}</li>
                <li>{t("errors.passwordNoSpaces")}</li>
                <li>{t("errors.paswwordNoLowerLetter")}</li>
                <li>{t("errors.passwordNoCapitalLetter")}</li>
                <li>{t("errors.passwordNoDigit")}</li>
                <li>{t("errors.passwordNoSpecialLetter")}</li>
            </ul>
            <p className="answer-text">
                {t("pages.faq.answerRegister_3")}
            </p>
        </div>
    );
};

export default AnswerRegister;
