import { memo } from "react";
import { useTranslation } from "react-i18next";

const Pin = ({ pin, id, countryObject, representation, tag, infoText, setState, lat, lng, onClick }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="react-pin-text" id={tag} style={{ display: "none" }} />
      <div
        onClick={() => {
          onClick(tag, countryObject, representation, infoText, lat, lng, t);
          setState();
        }}
      >
        {pin}
      </div>
    </div>
  );
};

export const MemoPin = memo(Pin);