import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { isMobile } from "../../utils/helpers";
import MainHeaderDesktop from "./MainHeaderDesktop";

const EditAOHeader = ({ title, currentProgress, onBackButtonClickHandler, ariaLabel }) => {
  const { t } = useTranslation();

  const onClick = (e) => {
    e.preventDefault();
    onBackButtonClickHandler();
  };

  const newTitle = title?.replace("%noarrow%", "");
  const hasArrow = !title?.includes("%noarrow%");

  return (
    <div className="mobile-sticky">
      {!isMobile() && <MainHeaderDesktop className="mobile-hidden" />}
      <header className="register-header" role="navigation">
        <a
          className="sr-only"
          href="#main"
          onClick={(ev) => {
            ev.preventDefault();
            document.getElementById("main").scrollIntoView();
          }}
        >
          {t("terms.jump_to")}
        </a>
        {hasArrow &&
          <Link className="register-header__back-button" to="./" onClick={onClick} aria-label={t("terms.back")} role="button">
            <img className="register-header__back-button__img" src="./images/common/headerback.png" alt="" />
          </Link>
        }

        <h1 className="register-header__title" aria-label={t(ariaLabel)} tabIndex="0">
          {t(newTitle)}
        </h1>
      </header>
      {!currentProgress.includes("no") &&
        <div className="progress-bar">
          <div className="current-progress" style={{ width: currentProgress }} />
        </div>
      }
    </div>
  );
};

export default EditAOHeader;