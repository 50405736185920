import { Button, Modal } from "@mantine/core"
import React, { FC, useContext } from "react"
import { useTranslation } from "react-i18next"
import GlobalContext from "../../Contexts/GlobalContext"
import style from "./index.module.scss"

const ErrorModal: FC = () => {
    const { t } = useTranslation("dot")
    const { data, setData } = useContext(GlobalContext)

    const closeModal = () => {
        data.modal = false
        setData(data => {
            data.modal = false
            return Object.assign({}, data)
        })
    }

    return (
        <Modal
            opened={data?.modal}
            onClose={closeModal}
            radius={0}
        >
            <div>
                {t(data.modalMessage)}
            </div>
            <div className={style.closeButton}>
                <Button onClick={closeModal} radius={0}>Ok</Button>
            </div>
        </Modal>
    )
}

export default ErrorModal