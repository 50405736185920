import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import InputValidation from "../../../common/InputValidaiton";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import TravelContext from "../../../context/TravelContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import {
  getKeyByValue,
  getTravelGroupMemberLastIndex_Array,
  hasMiddleName,
} from "../../../utils/helpers";
import { hasNumber, isValidFirstAndLastName } from "../../../utils/validation";
import CustomSelectList from "../../foreign-registration/utils/CustomSelectList";
import Input from "../../registration/utils/Input";
import WeiterButton from "../../registration/utils/WeiterButton";
import Form from "../utils/Form";
import PageTitle from "../utils/PageTitle";

const AddTravelCompanionName = () => {
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  const [active, setActive] = useState(false);

  const { travel, setTravel } = useContext(TravelContext);

  const relationships = useMemo(
    () => [
      t("input.relative.options.parent"),
      t("input.relative.options.sibling"),
      t("input.relative.options.child"),
      t("input.relative.options.partner"),
      t("input.relative.options.etc"),
    ],
    [t]
  );

  const relationshipEnums = useMemo(
    () => {
      const rel = {};

      rel[t("input.relative.options.parent")] = 100000000;
      rel[t("input.relative.options.sibling")] = 100000001;
      rel[t("input.relative.options.child")] = 100000002;
      rel[t("input.relative.options.partner")] = 100000003;
      rel[t("input.relative.options.etc")] = 100000004;

      return rel;
    },
    [t]
  );

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t("pageTitle.createTripPages"),
        progress: parseInt((12 / 19) * 100),
      };
    });
  }, []);

  const history = useHistory();

  const groupMemberIndex = getTravelGroupMemberLastIndex_Array(travel);

  const initName = travel?.TravelGroupMember[groupMemberIndex]?.FirstLastName;

  const initRelationship = getKeyByValue(
    relationshipEnums,
    travel?.TravelGroupMember[groupMemberIndex]?.DegreeOfRelationship
  );

  const [relationship, setRelationship] = useState(initRelationship ?? "");

  const [fullName, setFullName] = useState(initName ?? "");

  const isValidRelationShip = () => {
    return relationships.includes(relationship);
  };

  const isValid = () => {
    return (
      fullName.length > 0 && isValidFirstAndLastName(fullName) && isValidRelationShip() && !hasMiddleName(fullName)
    );
  };

  const updateTravel = () => {
    const names = fullName.trim().split(" ");

    const groupMemberIndex = travel.TravelGroupMember.length > 0 ? travel.TravelGroupMember.length - 1 : 0;
    let newArray = [...travel.TravelGroupMember];
    newArray[groupMemberIndex] = {
      ...newArray[groupMemberIndex],

      FirstName: names[0],
      LastName: names[1],
      FirstLastName: fullName,
      DegreeOfRelationship: relationshipEnums[relationship],
    };

    setTravel((curr) => {
      return {
        ...curr,
        TravelGroupMember: newArray,
      };
    });
  };

  const onChangeHandler = (e) => {
    setActive(true);
    setFullName(e.target.value);
  };

  const onChangeHandlerCountries = (value) => {
    setRelationship(value);
  };

  const onClickHandler = () => {
    updateTravel();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateTravel();
      history.push(ApplicationRoute.addTravelCompanionDateOfBirth);
    }
  };

  return (
    <section className="add-travel registration-middle-container">
      <MetaDecorator title="pageTitle.createTripPages" />
      <PageTitle id="pages.addRelative1.formLabel" text="pages.step2CompanionDetails.pageTitle" />

      {/* <p className="page-paragraph page-paragraph--small">{t("input.mitreisende.one")}</p> */}
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form ariaLabelledBy="pages.addRelative1.formLabel" className="max-width-380" id="form" onSubmit={onSubmitHandler}>
        <Input
          labelText="input.vorNachname.text"
          id="firstName"
          name="firstName"
          type="text"
          htmlFor="firstName"
          placeholder="input.vorNachname.text"
          value={fullName}
          onChange={onChangeHandler}
        />

        <InputValidation
          style={{ marginBottom: "1.5rem" }}
          isHidden={!active || isValidFirstAndLastName(fullName) || hasMiddleName(fullName)}
          text={
            hasNumber(fullName)
              ? "errors.nameHasNumber"
              : "errors.notValidContactPersonFirstAndLastName"
          }
          ariaLabel="errors.notValidContactPersonFirstAndLastName"
        />
        <InputValidation
          isHidden={!hasMiddleName(fullName)}
          text="errors.middleName"
          ariaLabel="errors.middleName"
        />

        <CustomSelectList
          id="relationship"
          label="input.relative.text"
          state={relationship}
          onChangeHandler={onChangeHandlerCountries}
          options={relationships}
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />

      <div className="hide-weiter-on-keyboard-content" />
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addTravelCompanionDateOfBirth}
        onClick={onClickHandler}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </section>
  );
};

export default AddTravelCompanionName;