import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import InputValidation from "../../../common/InputValidaiton";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import UserContext from "../../../context/UserContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import {
  arePasswordsValid,
  isPasswordValid,
} from "../../../utils/validation";
import ContentTitle from "../utils/ContentTitle";
import Form from "../utils/Form";
import Info from "../utils/Info";
import Input from "../utils/Input";
import ValidationChecks from "../utils/ValidationChecks.jsx";
import WeiterButton from "../utils/WeiterButton";

//HINT* REGISTRATION
const PasswordPage = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
  });

  const { user, setUser } = useContext(UserContext);
  const { setHeader } = useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((3 / 6) * 100),
        title: t("pageTitle.registrationPages"),
        ariaLabel: t("pages.register3.ariaTitle"),
      };
    });
  }, []);

  const updateUser = () => {
    setUser((curr) => {
      return { ...curr, Password: passwords.password };
    });
  };

  const onChangeHandler = (e) => {
    setPasswords((curr) => {
      return { ...curr, [e.target.id]: e.target.value };
    });
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (arePasswordsValid(passwords)) {
      updateUser();
      history.push(ApplicationRoute.registerConfirmationPage);
    }
  };

  useEffect(() => {
    setPasswords((curr) => {
      let psw = user.Password ?? "";
      return { ...curr, password: psw, confirmPassword: psw };
    });
  }, [user]);

  return (
    <div className="registration-middle-container">
      <MetaDecorator title="pageTitle.registrationPages" />
      <ContentTitle style={{ marginBottom: "32px" }} text="pages.register3.contentTitle" id="enter-password" />
      <HorizontalRule className="horizontal-rule-desktop" />
      <p className="sr-only">{t("pages.register3.info1")}</p>
      <div className="mobile-hidden half-col" style={{ float: "right" }} aria-hidden="true">
        <p
          style={{
            marginBottom: "1.6rem",
            marginTop: "-1.6rem",
            marginRight: "16rem",
            wordWrap: "break-word",
            width: "200px",
          }}
        >
          {t("pages.register3.info1")}
        </p>
        <div id="hint" style={{ marginBottom: "0" }} aria-relevant="all">
          <ValidationChecks passwords={passwords} />
        </div>
      </div>
      <Form className="half-col max-width-380" id="form" onSubmit={onSubmitHandler} ariaLabelledBy="contentTitleId">
        <Input
          id="password"
          value={passwords.password}
          onChange={onChangeHandler}
          placeholder="input.passwort.text"
          type="password"
          labelText="input.passwort.text"
          showValidationBorder={!isPasswordValid(passwords.password)}
        />

        <div className="desktop-hidden">
          <Info
            style={{ marginTop: "32px", marginBottom: "16px" }}
            text="pages.register3.info1"
          />

          <div style={{ marginBottom: "32px" }} role="alert" aria-relevant="all">
            <ValidationChecks passwords={passwords} />
          </div>
        </div>

        <Input
          id="confirmPassword"
          type="password"
          htmlFor="confirmPassword"
          value={passwords.confirmPassword}
          onChange={onChangeHandler}
          placeholder="input.passwort.confirmText"
          ariaLabelledBy="enter-password"
          ariaDescribedby="hint"
          labelText="input.passwort.confirmText"
          showValidationBorder={
            passwords.confirmPassword.length > 0 && passwords.confirmPassword !== passwords.password
          }
          error={passwords.confirmPassword.length > 0 && passwords.confirmPassword !== passwords.password}
        />
        <InputValidation
          alert={true}
          isHidden={!(passwords.confirmPassword.length > 0 && passwords.confirmPassword !== passwords.password)}
          text="errors.passwordAndConfirmationPasswordNotEqual"
        />

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />

      <WeiterButton
        disabled={!arePasswordsValid(passwords)}
        path={ApplicationRoute.registerConfirmationPage}
        onClick={onClickHandler}
        positioned="fixed"
        className="hide-weiter-on-keyboard"
      />

      <div className="button-space-on-keyboard" />
    </div>
  );
};

export default PasswordPage;