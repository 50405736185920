import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import apipath from "../../../apipath.js";
import ApplicationRoute from "../../../config/routes.js";
import HeaderContext from "../../../context/HeaderContext.js";
import UserContext from "../../../context/UserContext.js";
import fetcher from "../../../services/fetcher.js";
import DataConfirmationForm from "../../registration/utils/DataConfirmationForm.jsx";

//HINT* REGISTRATION
const DseConfirmationPage = () => {
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();

  const { user, setUser } = useContext(UserContext);
  const { setHeader } = useContext(HeaderContext);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const confirmUser = () => {
    fetcher(
      // Change api call when backend/middleware are modified
      `${apipath.path}users/CURRENT/confirm`,
      "POST",
      setData,
      setError,
      setLoading,
      setSubmitting,
      false,
      "application/json",
      JSON.stringify("") // add content
    );
  };

  const submittedWithNoErrors = useCallback(
    () => {
      return !loading && !error;
    }, [loading, error]);

  useEffect(() => {
    if (user.GDPRAccountLatestVersion) {
      history.push(ApplicationRoute.TravelDashboardNoPermissions);
    }
  }, [user, history]);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "dse",
        title: t("pageTitle.completeAccount") + "%noarrow%",
        ariaLabel: t("terms.consent"),
        progress: "no",
      };
    });
  }, [setHeader]);

  //After submit
  useEffect(() => {
    if (submittedWithNoErrors()) {
      setUser((curr) => {
        return {
          ...curr,
          GDPRAccountLatestVersion: true
        };
      });
      history.push(ApplicationRoute.TravelDashboardNoPermissions);
    }
  }, [loading, error, data, history, submittedWithNoErrors, setUser]);

  const submittingNow = () => {
    return submitting && loading;
  };

  const onChangeHandler = () => {
    setChecked(!checked);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    confirmUser();
  };

  return (
    <DataConfirmationForm
      onChangeHandler={onChangeHandler}
      onClickHandler={onSubmitHandler}
      submittingNow={submittingNow}
      checked={checked}
      onSubmitHandler={onSubmitHandler}
      pageTitle="pageTitle.completeAccount"
      route={ApplicationRoute.TravelDashboardNoPermissions}
    />
  );
};

export default DseConfirmationPage;