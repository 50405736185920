import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { isMobile } from "../../utils/helpers";

const RegistrationSubHeader = ({ title, currentProgress, onBackButtonClickHandler, ariaLabel }) => {
  const { t } = useTranslation();

  const newTitle = title?.replace("%noarrow%", "");
  const hasArrow = !title?.includes("%noarrow%");

  return (
    <>
      <header className="register-header" role="navigation">
        {!isMobile() &&
          <a
            className="sr-only"
            href="#main"
            onClick={(ev) => {
              ev.preventDefault();
              document.getElementById("main").scrollIntoView();
            }}
          >
            {t("terms.jump_to")}
          </a>
        }
        {hasArrow &&
          <Link
            className="register-header__back-button"
            to="./"
            onClick={onBackButtonClickHandler}
            aria-label={t("terms.back")}
            role="button"
          >
            <img className="register-header__back-button__img" src="./images/common/headerback.png" alt="" />
          </Link>
        }

        <h1 className="register-header__title" aria-label={t(ariaLabel)} tabIndex="0">
          {t(newTitle)}
        </h1>
      </header>
      {!currentProgress.includes("no") &&
        <div className="progress-bar">
          <div className="current-progress" style={{ width: currentProgress }} />
        </div>
      }
    </>
  );
};

export default RegistrationSubHeader;