import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import InputValidation from "../../../common/InputValidaiton";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import UserContext from "../../../context/UserContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import {
  hasAtSymbolEmailValidation,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isEmailValidation,
} from "../../../utils/validation";
import ContentTitle from "../utils/ContentTitle";
import Form from "../utils/Form";
import Input from "../utils/Input";
import WeiterButton from "../utils/WeiterButton";

//HINT* REGISTRATION
const EmailAdressRegistration = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [email, setEmail] = useState("");

  const { user, setUser } = useContext(UserContext);
  const { setHeader } = useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((2 / 6) * 100),
        title: t("pageTitle.registrationPages"),
        ariaLabel: t("pages.register2.ariaTitle"),
      };
    });
  }, []);

  const isValid = () => {
    return isEmailValidation(email) && hasTwoCharactersAfterAtSymbolEmailValidation(email);
  };

  const updateUser = () => {
    setUser((curr) => {
      return { ...curr, Email: email };
    });
  };

  const onChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history.push(ApplicationRoute.registerPassword);
    }
  };

  //
  //
  const activate = () => {
    setActive(() => true);
  };

  const [active, setActive] = useState(false);

  useEffect(() => {
    document.documentElement.addEventListener("click", activate);

    return () => document.documentElement.removeEventListener("click", activate);
  }, []);
  //
  //

  useEffect(() => {
    setEmail(user.Email ?? "");
  }, [user]);

  return (
    <>
      <div className="registration-middle-container">
        <MetaDecorator title="pageTitle.registrationPages" />
        <ContentTitle
          id="register-email"
          text="prefilledUserData.email"
          style={{ marginBottom: "32px" }}
        />
        <HorizontalRule className="horizontal-rule-desktop" />
        <Form
          className="max-width-380"
          id="register-email-form"
          onSubmit={onsubmitHandler}
          ariaLabelledBy="register-email"
        >
          <Input
            id="email"
            value={email}
            onChange={onChangeHandler}
            placeholder="input.email.text"
            type="email"
            labelText="input.email.text"
            showValidationBorder={
              email.length > 0 && (!isValid() || !hasTwoCharactersAfterAtSymbolEmailValidation(email))
            }
          />

          <InputValidation
            isHidden={
              !active ||
              !email.length > 0 ||
              !hasAtSymbolEmailValidation(email) ||
              !hasTwoCharactersAfterAtSymbolEmailValidation(email) ||
              isValid()
            }
            text="errors.notValidEmail"
          />

          <InputValidation
            isHidden={!active || !email.length > 0 || hasAtSymbolEmailValidation(email)}
            text="errors.missingAtSymbolEmail"
          />

          <InputValidation
            isHidden={
              !active ||
              !email.length > 0 ||
              !hasAtSymbolEmailValidation(email) ||
              hasTwoCharactersAfterAtSymbolEmailValidation(email)
            }
            text="errors.charactersAfterAtSymbolLessThanTwoEmail"
          />

          <HiddenSubmitInput />
        </Form>
        <HorizontalRule className="horizontal-rule-desktop" />
        <WeiterButton
          disabled={!isValid()}
          path={ApplicationRoute.registerPassword}
          onClick={onClickHandler}
          className="floating-weiter-buton"
        />
      </div>
    </>
  );
};

export default EmailAdressRegistration;