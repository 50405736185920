import { useTranslation } from "react-i18next";

const Info = ({ id, text, style, ariaLabel, role = "alert" }) => {
  const { t } = useTranslation();

  return (
    <p style={style} className="info__container" id={id} role={role} aria-relevant="all" aria-label={t(ariaLabel)}>
      {t(text)}
    </p>
  );
};

export default Info;