import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import ContentTitle from "../utils/ContentTitle";
import ControledRadioInput from "../utils/ControledRadioInput";
import Form from "../utils/Form";
import Info from "../utils/Info";
import WeiterButton from "../utils/WeiterButton";

//HINT* REGISTRATION
const initSelectValue = {
  hasCitizenship: false,
  noCitizenship: false,
};

const AustrianCitizenshipPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState({
    ...initSelectValue,
    hasCitizenship: true,
  });

  const { setHeader } = useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((1 / 6) * 100),
        title: t("pageTitle.registrationPages"),
        ariaLabel: t("pages.register1.ariaTitle"),
      };
    });
  }, []);
  const isValid = () => {
    return selectedValue.hasCitizenship;
  };

  const onChangeHandler = (e) => {
    setSelectedValue(() => {
      return { ...initSelectValue, [e.target.id]: true };
    });
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      history.push(ApplicationRoute.registrationEmailAdress);
    }
  };

  return (
    <div className="registration-middle-container">
      <MetaDecorator title="pageTitle.registrationPages" />
      <ContentTitle id="citizenship-title" text="pages.register1.contentTitle" />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form id="citizenship-form" ariaLabelledBy="citizenship-title" onSubmit={onsubmitHandler}>
        <ControledRadioInput
          id="hasCitizenship"
          value="input.jaNein.ja"
          name="citizenship"
          textInfo="input.jaNein.ja"
          checked={selectedValue.hasCitizenship}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.hasCitizenship}
        />
        <ControledRadioInput
          id="noCitizenship"
          value="input.jaNein.nein"
          name="citizenship"
          textInfo="input.jaNein.nein"
          checked={selectedValue.noCitizenship}
          onChange={onChangeHandler}
          ariaChecked={selectedValue.noCitizenship}
        />

        <div style={{ height: "20px" }} />
        {selectedValue.noCitizenship &&
          <Info
            style={{ marginTop: "0px" }}
            text="pages.register1.info1"
          />
        }

        <HiddenSubmitInput />
      </Form>
      <HorizontalRule className="horizontal-rule-desktop" />
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.registrationEmailAdress}
        className="floating-weiter-buton"
      />
    </div>
  );
};

export default AustrianCitizenshipPage;