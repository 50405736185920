import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import HeaderContext from "../../context/HeaderContext";
import MetaDecorator from "../../utils/MetaDecorator";

const DataProtection = () => {
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t("pages.dsgvo.title"),
        progress: "no",
      };
    });
  }, []);

  return (
    <>
      <MetaDecorator title="pages.dsgvo.title" />
      <p className="context-text-dp-mt-24 ">{t("pages.dsgvo.test")}</p>
    </>
  );
};

export default DataProtection;