import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FilteredSelectList from "../components/foreign-registration/utils/FilteredSelectList";
import Input from "../components/travel-registration/utils/Input";
import WeiterButton from "../components/travel-registration/utils/WeiterButton";
import { getCountryNameFromId, getRegionsByCountryName } from "../components/travel-registration/utils/countryInfo";
import { compareGermanWords, pluck, postalCodeFormatter } from "../utils/helpers";

const CountryModal = ({ country, county, close, save }) => {
  const { t } = useTranslation();

  const [countries] = useState(JSON.parse(localStorage.getItem("countries")));
  const [countryNames] = useState(
    pluck(countries, "Name").filter((c) => {
      return !(t(c) === t("terms.austria") || t(c) === t("terms.other_countries"));
    })
  );

  const [selectedCountry, setSelectedCountry] = useState(country ? getCountryNameFromId(country) : "");
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");

  const [street, setStreet] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");

  const [update, setUpdate] = useState(0);

  const startRef = useRef();

  const onCountryChange = (value) => {
    setSelectedRegion(() => "");
    setSelectedCountry(value);
    const regionsArr = pluck(getRegionsByCountryName(value), "Name");
    if (regionsArr.length === 1) {
      setSelectedRegion(() => regionsArr[0]);
    }
    setRegions(regionsArr.sort(compareGermanWords));
  };
  const onRegionChange = (value) => {
    setSelectedRegion(() => value);
  };

  const validation = () => {
    return !(selectedCountry && selectedRegion && street && city && postalCode.toString().length >= 1);
  };

  const countryOnClickHandler = (e) => {
    e.preventDefault();

    let countryId;
    let regionId;

    for (let country of countries) {
      if (country.Name === selectedCountry) {
        countryId = country.Id;

        for (let region of country.Regions) {
          if (region.Name === selectedRegion) {
            regionId = region.Id;
            break;
          }
        }
        break;
      }
    }
    save(countryId, regionId, street, postalCode, city);
  };

  useEffect(() => {
    if (selectedCountry) {
      const regionsArr = getRegionsByCountryName(selectedCountry);
      setRegions(pluck(regionsArr, "Name"));

      if (county) {
        setSelectedRegion(() => regionsArr.find((element) => element.Id === county)?.Name ?? "");
      }
    }
  }, []);

  useEffect(() => {
    if (update <= 1) {
      setUpdate(update + 1);
      startRef.current.focus();
    }
  }, [update]);

  return (
    <div
      className="modal"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          if (!validation()) {
            countryOnClickHandler(e);
          } else {
            e.preventDefault();
          }
        }
      }}
    >
      <button className="modal__close-button" onClick={close}>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
          <g fill="none" fillRule="evenodd">
            <g fill="#000">
              <g>
                <path
                  d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                  transform="translate(-24 -21) translate(19 16)"
                />
              </g>
            </g>
          </g>
        </svg>
      </button>
      <h2 className="modal__heading">{t("pages.countryModal.header")}</h2>
      <div className="modal__form">
        <FilteredSelectList
          id="countryModal"
          label="input.select.text"
          state={selectedCountry}
          options={countryNames}
          onChangeHandler={onCountryChange}
          className="grey-background"
          ref={startRef}
        />
        <FilteredSelectList
          id="regionModal"
          options={regions}
          label="input.select.text"
          state={selectedRegion}
          onChangeHandler={onRegionChange}
          className="grey-background"
          isRegion={true}
        />
        <Input
          labelText="input.street.text"
          id="street"
          placeholder="input.street.text"
          type="text"
          value={street}
          onChange={(e) => {
            setStreet(e.target.value);
          }}
        />
        <div className="plz-city-flex">
          <Input
            labelText="input.plz.text"
            id="plz"
            placeholder="input.plz.text"
            type="text"
            value={postalCode}
            onChange={(e) => {
              setPostalCode(postalCodeFormatter(postalCode, e.target.value));
            }}
          />
          <Input
            labelText="input.city.text"
            id="city"
            placeholder="input.city.text"
            type="text"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
          />
        </div>
        <WeiterButton
          disabled={validation()}
          path=""
          text="terms.save_quit"
          onClick={countryOnClickHandler}
          ariaLabel="pages.contactPerson.weiter"
        />
      </div>
    </div>
  );
};

export default CountryModal;