import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ApplicationRoute from "../../config/routes";

const DesktopFooter = ({ path }) => {
  const { t } = useTranslation();
  const toShow = path === "/" || path.includes(ApplicationRoute.TravelDashboardNoPermissions);
  const style = toShow ? { display: "block" } : undefined;

  return (
    <footer style={style} role="contentinfo" className="web-footer">
      <div className="desktop-footer-flex">
        <div className="desktop-footer-item">
          <a href="http://auslandsservice.at/" rel="noopener noreferrer" target="_blank">
            {t("pages.informationen.navigationTitle.auslandsservice")}
          </a>
        </div>

        <div className="desktop-footer-item">
          <a href="https://www.bmeia.gv.at/reise-services/auslandsservice/datenschutz/" rel="noopener noreferrer" target="_blank">
            {t("pages.dsgvo.title")}
          </a>
        </div>

        <div className="desktop-footer-item">
          <a href="https://www.bmeia.gv.at/app-inhalte/bildquellen/" rel="noopener noreferrer" target="_blank">
            {t("pages.informationen.navigationTitle.bildquellen")}
          </a>
        </div>

        <div className="desktop-footer-item">
          <a href="https://www.bmeia.gv.at/barrierefreiheitserklaerung/" rel="noopener noreferrer" target="_blank">
            {t("pages.informationen.navigationTitle.barrierefreiheit")}
          </a>
        </div>

        <div className="desktop-footer-item">
          <a href="https://www.bmeia.gv.at/app-inhalte/impressum-app/" rel="noopener noreferrer" target="_blank">
            {t("pages.informationen.navigationTitle.impressum")}
          </a>
        </div>

        <div className="desktop-footer-item">
          <Link to={ApplicationRoute.faqs}>
            {t("pages.informationen.navigationTitle.faqs")}
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default DesktopFooter;