import axios from 'axios'

const httpClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        "Content-type": "application/json",
        "Cache-Control": "no-cache, no-store",
        "Expires": "0",
        "Pragma": "no-cache"
    }
})

const isDemo = process.env.NODE_ENV === 'development' && process.env.REACT_APP_REAL_TESTING === 'false'

export { httpClient, isDemo }
