import { memo } from "react";
import { useTranslation } from "react-i18next";

const Card = (id, onClick, city, ArrowRight, type, style) => {
  const { t } = useTranslation();

  return (
    <li className="info-cards" id={id}>
      <div className="upper-text">
        {t(type)}
        <div className="info-card__main-text" style={style}>
          {t(city)}
        </div>
      </div>
      <div className="arrow__right" onClick={onClick}>
        {ArrowRight}
      </div>
    </li>
  );
};

const MemoCard = memo(Card);

function InfoCard({ id, onClick, city, ArrowRight, type, isHidden, style, isH1 = false }) {
  return isH1 ? (
    <h1 hidden={isHidden} aria-hidden={isHidden} style={{ fontWeight: "normal" }}>
      <MemoCard id={id} onClick={onClick} city={city} ArrowRight={ArrowRight} type={type} style={style} />
    </h1>
  ) : (
    <div hidden={isHidden} aria-hidden={isHidden}>
      <MemoCard id={id} onClick={onClick} city={city} ArrowRight={ArrowRight} type={type} style={style} />
    </div>
  );
}

export default InfoCard;