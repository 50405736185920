import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CountryListInput from "../CountryListInput";

const CountryListHeader = ({ title, onBackButtonClickHandler, value, setValue, hidden, setHidden }) => {
  const { t } = useTranslation();

  return (
    <div className="mobile-sticky">
      <header className="register-header" role="navigation">
        <a
          className="sr-only"
          href="#main"
          onClick={(ev) => {
            ev.preventDefault();
            document.getElementById("main").scrollIntoView();
          }}
        >
          {t("terms.jump_to")}
        </a>
        <Link className="register-header__back-button" to="./" onClick={onBackButtonClickHandler} aria-label={t("terms.back")}>
          <img className="register-header__back-button__img" src="./images/common/headerback.png" alt="" />
        </Link>
        <h1 className="register-header__title">{t(title)}</h1>
        <button
          role="search"
          className="register-header__search button-abs"
          onClick={() => {
            setHidden((curr) => !curr);
          }}
          aria-label={t("terms.search")}
        >
          <img
            aria-label={t("terms.search")}
            className="register-header__search-img"
            src="./images/common/iconsSearch.png"
            alt={t("terms.search")}
          />
        </button>
      </header>
      <CountryListInput
        hidden={hidden}
        onCrossClick={() => {
          setValue("");
          setHidden(true);
        }}
        value={value}
        setValue={setValue}
      />
    </div>
  );
};

export default CountryListHeader;