import { useTranslation } from "react-i18next";
import ClearContactDiv from "../../../common/ClearContactDiv";
import EditInput from "../../../common/EditInput";
import HorizontalRule from "../../../common/HorizontalRule";
import InputValidation from "../../../common/InputValidaiton";
import { getEnumFromGenderText, getGenderTextFromEnum, hasMiddleName } from "../../../utils/helpers";
import {
  IsDateBiggerThanTodayValidation,
  IsDateSmallerThan_1900_1_1_Validation,
  hasAtSymbolEmailValidation,
  hasMinLettersValidation,
  hasNumber,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isDateValidation,
  isEmailValidation,
  isOlderOrIsEighteenYearsOlds,
  isPassnumberValidation,
  isPersonalNumberValidation,
  isPhoneValidation,
  isValidFirstAndLastName,
} from "../../../utils/validation";

const countriesSelectOptions = [
  "input.bundesland.options.burgenland",
  "input.bundesland.options.kaernten",
  "input.bundesland.options.niederoesterreich",
  "input.bundesland.options.oberoesterreich",
  "input.bundesland.options.salzburg",
  "input.bundesland.options.steiermark",
  "input.bundesland.options.tirol",
  "input.bundesland.options.vorarlberg",
  "input.bundesland.options.wien",
];

const ProfileComponentStateless = ({
  userInfoState,
  onUserInfoChangeHandler,
  contactPersonInfoState,
  onContactPersonInfoChangeHandler,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <EditInput
        id="FirstName"
        placeholder="input.vorname.text"
        value={userInfoState.FirstName}
        labelText="input.vorname.text"
        ariaRequired={true}
        onChange={onUserInfoChangeHandler}
      />
      <InputValidation
        text={
          hasNumber(userInfoState.FirstName)
            ? "errors.nameHasNumber"
            : "errors.firstNameLessCharacters"
        }
        isHidden={hasMinLettersValidation(userInfoState.FirstName)}
      />

      <HorizontalRule />
      <EditInput
        id="LastName"
        placeholder="input.nachname.text"
        value={userInfoState.LastName}
        labelText="input.nachname.text"
        ariaRequired={true}
        onChange={onUserInfoChangeHandler}
      />
      <InputValidation
        text={
          hasNumber(userInfoState.LastName)
            ? "errors.nameHasNumber"
            : "errors.lastNameLessCharacters"
        }
        isHidden={hasMinLettersValidation(userInfoState.LastName)}
      />
      <HorizontalRule />
      <EditInput
        id="Gender"
        placeholder="input.gender.text"
        value={getGenderTextFromEnum(userInfoState.Gender, t)}
        labelText="input.gender.text"
        ariaRequired={true}
        onChange={(value) =>
          onUserInfoChangeHandler({
            target: { id: "Gender", value: getEnumFromGenderText(value, t) },
          })
        }
        options={[t("input.gender.options.male"), t("input.gender.options.female"), t("input.gender.options.other")]}
      />

      <HorizontalRule />
      <EditInput
        id="BirthDate"
        placeholder="input.date.birthdate"
        value={userInfoState.BirthDate}
        labelText="input.date.birthdate"
        ariaRequired={true}
        onChange={onUserInfoChangeHandler}
      />

      <InputValidation
        text="errors.notValidDate"
        isHidden={isDateValidation(userInfoState.BirthDate)}
      />
      <InputValidation
        text="errors.ageLessThan_18"
        isHidden={
          !IsDateBiggerThanTodayValidation(userInfoState.BirthDate) ||
          !isDateValidation(userInfoState.BirthDate) ||
          isOlderOrIsEighteenYearsOlds(userInfoState.BirthDate)
        }
      />

      <InputValidation
        text="errors.dateBiggerThanToday"
        isHidden={
          !isDateValidation(userInfoState.BirthDate) || IsDateBiggerThanTodayValidation(userInfoState.BirthDate)
        }
      />
      <InputValidation
        text="errors.dateOfBirthLessThan_01_01_1900"
        isHidden={
          !isDateValidation(userInfoState.BirthDate) || !IsDateSmallerThan_1900_1_1_Validation(userInfoState.BirthDate)
        }
      />

      <HorizontalRule />

      <EditInput
        id="CountyHomeRegionName"
        placeholder="input.bundesland.text"
        value={userInfoState.CountyHomeRegionName}
        labelText="input.bundesland.text"
        ariaRequired={true}
        onChange={(value) =>
          onUserInfoChangeHandler({
            target: { id: "CountyHomeRegionName", value: value },
          })
        }
        options={countriesSelectOptions}
        optional={true}
      />

      <HorizontalRule />
      <EditInput
        id="PhoneNumber"
        placeholder="input.mobilenum.placeholder"
        value={userInfoState.PhoneNumber}
        labelText="input.mobilenum.text"
        ariaRequired={true}
        onChange={onUserInfoChangeHandler}
      />

      <InputValidation
        text="errors.notValidPhoneNumber"
        isHidden={isPhoneValidation(userInfoState.PhoneNumber)}
      />

      <HorizontalRule />
      <EditInput
        id="Email"
        placeholder="input.email.text"
        value={userInfoState.Email}
        labelText="input.email.text"
        ariaRequired={true}
        onChange={onUserInfoChangeHandler}
      />
      <InputValidation
        isHidden={
          !hasAtSymbolEmailValidation(userInfoState.Email) ||
          !hasTwoCharactersAfterAtSymbolEmailValidation(userInfoState.Email) ||
          isEmailValidation(userInfoState.Email)
        }
        text="errors.notValidEmail"
      />
      <InputValidation
        isHidden={hasAtSymbolEmailValidation(userInfoState.Email)}
        text="errors.missingAtSymbolEmail"
      />
      <InputValidation
        isHidden={
          !hasAtSymbolEmailValidation(userInfoState.Email) ||
          hasTwoCharactersAfterAtSymbolEmailValidation(userInfoState.Email)
        }
        text="errors.charactersAfterAtSymbolLessThanTwoEmail"
      />

      <HorizontalRule />
      <EditInput
        id="PassNumber"
        placeholder="input.passport.text"
        value={userInfoState.PassNumber}
        labelText="input.passport.text"
        ariaRequired={false}
        onChange={onUserInfoChangeHandler}
      />

      <InputValidation
        text="errors.notValidAustrianPassportOrIdentityCardNumberFormat"
        isHidden={isPassnumberValidation(userInfoState.PassNumber)}
      />

      <HorizontalRule />
      <EditInput
        id="IdNumber"
        value={userInfoState.IdNumber}
        onChange={onUserInfoChangeHandler}
        placeholder="input.personalIdCard.text"
        ariaRequired={false}
        labelText="input.personalIdCard.text"
        showValidationBorder={!isPersonalNumberValidation(userInfoState.IdNumber)}
      />

      <InputValidation
        isHidden={isPersonalNumberValidation(userInfoState.IdNumber)}
        text="errors.notValidPersonalNumber"
      />

      <ClearContactDiv
        text={`${t("terms.crisisContact")} (${contactPersonInfoState.FirstLastName ? contactPersonInfoState.FirstLastName : t("text.no_content")})`}
      />
      {/* <HorizontalRule /> */}
      <EditInput
        id="NotfalkontaktFirstLastName"
        placeholder="input.vorNachname.text"
        value={contactPersonInfoState.FirstLastName}
        labelText="input.vorNachname.text"
        ariaRequired={true}
        onChange={onContactPersonInfoChangeHandler}
      />
      <InputValidation
        text={
          hasNumber(contactPersonInfoState.FirstLastName)
            ? "errors.nameHasNumber"
            : "errors.notValidContactPersonFirstAndLastName"
        }
        isHidden={
          isValidFirstAndLastName(contactPersonInfoState.FirstLastName) ||
          hasMiddleName(contactPersonInfoState.FirstLastName)
        }
      />
      <InputValidation
        isHidden={!hasMiddleName(contactPersonInfoState.FirstLastName)}
        text="errors.middleName"
        ariaLabel="errors.middleName"
      />
      <HorizontalRule />
      <EditInput
        id="NotfalkontaktPhoneNumber"
        placeholder="input.contact.placeholder"
        value={contactPersonInfoState.PhoneNumber}
        labelText="input.mobilenum.text"
        ariaRequired={true}
        onChange={onContactPersonInfoChangeHandler}
      />
      <InputValidation
        text="errors.notValidPhoneNumber"
        isHidden={
          isPhoneValidation(contactPersonInfoState.PhoneNumber, true) || contactPersonInfoState.PhoneNumber.length === 0
        }
      />
      <HorizontalRule />
      <EditInput
        id="NotfalkontaktEmail"
        placeholder="input.email.text"
        value={contactPersonInfoState.Email}
        labelText="input.email.text"
        ariaRequired={false}
        onChange={onContactPersonInfoChangeHandler}
      />
      <InputValidation
        isHidden={
          contactPersonInfoState.Email.length === 0 ||
          !hasAtSymbolEmailValidation(contactPersonInfoState.Email) ||
          !hasTwoCharactersAfterAtSymbolEmailValidation(contactPersonInfoState.Email) ||
          isEmailValidation(contactPersonInfoState.Email)
        }
        text="errors.notValidEmail"
      />
      <InputValidation
        isHidden={contactPersonInfoState.Email.length === 0 || hasAtSymbolEmailValidation(contactPersonInfoState.Email)}
        text="errors.missingAtSymbolEmail"
      />
      <InputValidation
        isHidden={
          contactPersonInfoState.Email.length === 0 ||
          !hasAtSymbolEmailValidation(contactPersonInfoState.Email) ||
          hasTwoCharactersAfterAtSymbolEmailValidation(contactPersonInfoState.Email)
        }
        text="errors.charactersAfterAtSymbolLessThanTwoEmail"
      />
    </div>
  );
};

export default ProfileComponentStateless;