import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import apipath from "../../../apipath";
import EditInput from "../../../common/EditInput";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import Separater from "../../../common/Separater";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import SuccessContext from "../../../context/SuccessContext";
import TravelContext from "../../../context/TravelContext";
import UserContext from "../../../context/UserContext";
import fetcher from "../../../services/fetcher";
import MetaDecorator from "../../../utils/MetaDecorator";
import {
  editOnChangeGroupMembers,
  effectOptionals,
  optionalDefault
} from "../../../utils/editUtils.js";
import {
  GetBundeslandIdFromName,
  convertAppDateToMiddlewareDateFormat,
  convertMiddlewareDateToAppDateFormat,
  dateFormatter,
  getGenderTextFromEnum,
  getUIID,
  getYMDfromMiddleware,
  getYearMonthDateFromNewDate,
  getYearMonthDateFromNewDateMiddlewareFormat,
  isValidContactPerson,
  lengthForTravelName,
  phoneFormatter,
  setApiObjectAddTravel,
  setApiUserObjectUpdate
} from "../../../utils/helpers";
import {
  IsDateBiggerThanTodayValidation,
  IsDateSmallerThan_1900_1_1_Validation,
  hasMinLettersValidation,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isDateValidation,
  isEmailValidation,
  isPassnumberValidation,
  isPersonalNumberValidation,
  isPhoneValidation,
  isValidFirstAndLastName,
  travelGroupMemberValidation,
} from "../../../utils/validation";
import CheckBox from "../../registration/utils/CheckBox";
import ContentTitle from "../../registration/utils/ContentTitle";
import ControledRadioInput from "../../registration/utils/ControledRadioInput";
import Form from "../../registration/utils/Form";
import WeiterButton from "../../registration/utils/WeiterButton";
import InputCalendar from "../utils/InputCalendar";
import Loader from "../utils/Loader";
import TravelMember from "../utils/TravelMember";
import ProfileComponentStateless from "./profile-component-stateless";

const EditTravel = () => {
  const { t } = useTranslation();

  const { travel, setTravel } = useContext(TravelContext);
  const { setHeader } = useContext(HeaderContext);
  const { user, setUser } = useContext(UserContext);
  const { setSuccessBox } = useContext(SuccessContext);

  const { ContactPerson: contactPersonInfo, ...userInfo } = user;

  const [userInfoState, setUserInfoState] = useState({
    ...userInfo,
  });
  const [contactPersonInfoState, setContactPersonInfoState] = useState({
    ...contactPersonInfo,
  });
  const history = useHistory();
  const [checked, setChecked] = useState(false);

  const [, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [, setSubmitting] = useState(false);

  const [, setUpdateProfile] = useState(null);
  const [updateProfileError, setUpdateProfileError] = useState(null);
  const [updateProfileLoading, setUpdateProfileLoading] = useState(true);
  const [submttingUpdateProfile, setSubmttingUpdateProfile] = useState(false);

  const [GDPRDataRetention, setGDPRDataRetention] = useState(null);

  //Travelstops
  let [destinations, setDestinations] = useState(
    travel.TravelStops.map((ts) => {
      let se = getYMDfromMiddleware(ts.StopEnd);
      let ss = getYMDfromMiddleware(ts.StopStart);
      return {
        ...ts,
        StopEnd: new Date(se.year, se.month, se.day),
        StopStart: new Date(ss.year, ss.month, ss.day),
      };
    })
  );

  const [travelStartStop, setTravelStartStop] = useState("");

  const receiveDataForDestination = (index, StopStart, StopEnd) => {
    let newarr = [...destinations];
    newarr[index].StopStart = StopStart;
    newarr[index].StopEnd = StopEnd;

    setDestinations([...newarr]);
  };

  const onChangeCommentTravelStops = (e, index) => {
    let newarr = [...destinations];
    newarr[index].Comment = e.target.value;

    setDestinations([...newarr]);
  };

  useEffect(() => {
    let dates = [];
    destinations.forEach((d) => {
      dates.push(d.StopEnd);
      dates.push(d.StopStart);
    });
    let maxDate = new Date(Math.max(...dates));
    maxDate = getYearMonthDateFromNewDate(maxDate);

    let minDate = new Date(Math.min(...dates));
    minDate = getYearMonthDateFromNewDate(minDate);

    setTravelStartStop(minDate + " - " + maxDate);
  }, [destinations]);

  const submittingNow = () => {
    return updateProfileLoading && submttingUpdateProfile;
  };

  const updateUserMiddleware = () => {
    let toSend = { ...user, ContactPerson: contactPersonInfoState };
    toSend = Object.assign(toSend, userInfoState);
    toSend.GDPRDataRetention = GDPRDataRetention;

    fetcher(
      `${apipath.path}users/CURRENT`,
      "PUT",
      setUpdateProfile,
      setUpdateProfileError,
      setUpdateProfileLoading,
      setSubmttingUpdateProfile,
      false,
      "application/json",
      JSON.stringify(setApiUserObjectUpdate(toSend, t))
    );

    let toSetUser = {
      ...toSend,
      EmergencyEmail: contactPersonInfoState.Email ?? "",
      EmergencyFirstLastName: contactPersonInfoState.FirstLastName ?? "",
      EmergencyPhoneNumber: contactPersonInfoState.PhoneNumber ?? "",
    };

    setUser(() => toSetUser);
  };

  const getTravelName = () => {
    let title = "";
    travel.TravelStops.forEach((ts) => {
      title += ` ${ts.Name},`;
    });

    title = title.substring(0, title.length - 1).trim();

    if (title.length > 400) {
      title = title.substring(0, 396).concat(" ...");
    }

    return title;
  };

  const addTravelApi = () => {
    let toSend = travel;
    let newTravelStopArray = destinations.map((ts) => {
      return {
        ...ts,
        StopEnd: getYearMonthDateFromNewDateMiddlewareFormat(new Date(ts.StopEnd)),
        StopStart: getYearMonthDateFromNewDateMiddlewareFormat(new Date(ts.StopStart)),
      };
    });
    let dates = [];
    destinations.forEach((d) => {
      dates.push(d.StopEnd);
      dates.push(d.StopStart);
    });

    const maxDate = getYearMonthDateFromNewDateMiddlewareFormat(new Date(Math.max(...dates)));
    const minDate = getYearMonthDateFromNewDateMiddlewareFormat(new Date(Math.min(...dates)));

    let tgmembers = [...travel.TravelGroupMember].map((tgm) => {
      return {
        ...tgm,
        Name: tgm.FirstLastName.trim(),
      };
    });

    toSend = {
      ...toSend,
      StartDate: minDate,
      EndDate: maxDate,
      Name: getTravelName(),
      TravelStops: newTravelStopArray,
      TravelGroupMember: tgmembers,
    };

    fetcher(
      `${apipath.path}users/CURRENT/travels`,
      "POST",
      setData,
      setError,
      setLoading,
      setSubmitting,
      false,
      "application/json",
      JSON.stringify(setApiObjectAddTravel(toSend, t))
    );
  };

  const [stopOvers,] = useState(
    [...travel.TravelStops].map((ts) => {
      return {
        ...ts,
        StopEnd: ts.StopEnd ? convertMiddlewareDateToAppDateFormat(ts.StopEnd) : "",
        StopStart: ts.StopStart ? convertMiddlewareDateToAppDateFormat(ts.StopStart) : "",
      };
    })
  );
  const [groupMembers, setGroupMembers] = useState(
    travel.TravelGroupMember.map((tg) => {
      return {
        ...tg,
        Birthdate: tg.Birthdate ? convertMiddlewareDateToAppDateFormat(tg.Birthdate) : "",
      };
    })
  );

  const [validGroupMembers, setValidGroupMembers] = useState(true);

  useEffect(() => {
    if (groupMembers.length === 0) {
      setValidGroupMembers(true);
    } else {
      let valid = true;
      groupMembers.forEach((tgm) => {
        if (!travelGroupMemberValidation(tgm)) valid = false;
      });

      setValidGroupMembers(valid);
    }
  }, [groupMembers]);

  const getDestinationDateInit = () => {
    let first = travel.StartDate ? convertMiddlewareDateToAppDateFormat(travel.StartDate) : "";

    let second = travel.EndDate ? convertMiddlewareDateToAppDateFormat(travel.EndDate) : "";

    return first + "-" + second;
  };

  const [destination, setDestionation] = useState({
    Reisezeitraum: getDestinationDateInit(),
    OrganisierteReiseveranstaltung: travel.IsTourGroup ? t("input.jaNein.ja") : t("input.jaNein.nein"),
    Reiseveranstalter: travel.TourOperator,
  });

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t("pageTitle.createTripPages"),
        progress: 100,
      };
    });
  }, []);

  const onDestinationChangeHandler = (e) => {
    setDestionation((curr) => {
      return { ...curr, [e.target.id]: e.target.value };
    });
  };

  const onChangeHandler = () => {
    setChecked(!checked);
  };

  const [optionals, setOptionals] = useState(optionalDefault);
  useEffect(() => {
    effectOptionals(setOptionals, contactPersonInfoState)
  }, [contactPersonInfoState]);

  const updateTravel = () => {
    let newGroupMembers = groupMembers.map((gm) => {
      return {
        ...gm,
        Birthdate: convertAppDateToMiddlewareDateFormat(gm.Birthdate),
      };
    });

    let newTravelStops = stopOvers.map((st) => {
      return {
        ...st,
        StopStart: convertAppDateToMiddlewareDateFormat(st.StopStart),
        StopEnd: convertAppDateToMiddlewareDateFormat(st.StopEnd),
      };
    });

    setTravel((curr) => {
      return {
        ...curr,
        IsTourGroup: destination.OrganisierteReiseveranstaltung === t("input.jaNein.ja"),
        TourOperator: destination.Reiseveranstalter,
        TravelGroupMember: newGroupMembers,
        TravelStops: newTravelStops,
      };
    });
  };

  const submit = (e) => {
    e.preventDefault();
    if (isValid()) {
      updateUserMiddleware();
    }
  };

  //HINT* POST TRAVELL
  useEffect(() => {
    if (!updateProfileLoading && !updateProfileError) {
      updateTravel();

      addTravelApi();
    }
  }, [updateProfileLoading]);

  useEffect(() => {
    if (!loading && !error) {
      setSuccessBox((curr) => {
        return {
          ...curr,
          hidden: false,
          text: t("pages.step9Edit.success"),
          trigger: getUIID(),
        };
      });
      history.push(ApplicationRoute.TravelDashboardNoPermissions);
    }
  }, [loading, error]);

  const onClickHandler = (e) => {
    submit(e);
  };

  const onSubmitHandler = (e) => {
    submit(e);
  };

  const onChangeGroupMembers = (e) => {
    setGroupMembers((curr) => {
      return editOnChangeGroupMembers(e, curr);
    });
  };

  const onGDPRDataRetentionChange = (e) => {
    setGDPRDataRetention(e.target.value === t("input.jaNein.ja"));
  };

  useEffect(() => {
    setUser((curr) => {
      return {
        ...curr,
        GDPRDataRetention: GDPRDataRetention,
      };
    });
  }, [GDPRDataRetention]);

  let countryNames = t("pages.step9Edit.travelTo") + " " + travel.TravelStops[0].Country;

  if (travel.TravelStops.length > 1) {
    travel.TravelStops.forEach((el, index) => {
      if (index > 0) countryNames += ", " + el.Country;
    });
  }

  countryNames = lengthForTravelName(countryNames);

  const onContactPersonInfoChangeHandler = (e) => {
    setContactPersonInfoState((curr) => {
      let key = e.target.id.replace("Notfalkontakt", "");

      if (key === "PhoneNumber") {
        return {
          ...curr,
          [key]: phoneFormatter(contactPersonInfoState.PhoneNumber, e.target.value),
        };
      }

      return { ...curr, [key]: e.target.value };
    });
  };

  const onUserInfoChangeHandler = (e) => {
    setUserInfoState((curr) => {
      if (e.target.id === "CountyHomeRegionName") {
        curr.CountyHomeRegionName = e.target.value;
        curr.CountyHomeRegionId = GetBundeslandIdFromName(e.target.value, t);
      }

      if (e.target.id === "HomeRegion") {
        return {
          ...curr,
          [e.target.id]: GetBundeslandIdFromName(e.target.value, t),
        };
      }

      if (e.target.id === "BirthDate") {
        return {
          ...curr,
          [e.target.id]: dateFormatter(userInfoState.BirthDate, e.target.value),
        };
      }

      if (e.target.id === "PhoneNumber") {
        return {
          ...curr,
          [e.target.id]: phoneFormatter(userInfoState.PhoneNumber, e.target.value),
        };
      }

      return { ...curr, [e.target.id]: e.target.value };
    });
  };

  const isValid = () => {
    const validDate1 = IsDateBiggerThanTodayValidation(userInfoState.BirthDate);
    const validDate2 = !IsDateSmallerThan_1900_1_1_Validation(userInfoState.BirthDate);
    const validDate3 = isDateValidation(userInfoState.BirthDate);

    const validDate = validDate3 && validDate2 && validDate1;

    return (
      GDPRDataRetention != null &&
      validDate &&
      validGroupMembers &&
      !!checked &&
      (hasTwoCharactersAfterAtSymbolEmailValidation(contactPersonInfoState.Email) ||
        contactPersonInfoState.Email.length === 0) &&
      hasTwoCharactersAfterAtSymbolEmailValidation(userInfoState.Email) &&
      isValidContactPerson(optionals) &&
      hasMinLettersValidation(userInfoState.FirstName) &&
      hasMinLettersValidation(userInfoState.LastName) &&
      isPassnumberValidation(userInfoState.PassNumber) &&
      isPersonalNumberValidation(userInfoState.IdNumber) &&
      isEmailValidation(userInfoState.Email) &&
      isPhoneValidation(userInfoState.PhoneNumber) &&
      isValidFirstAndLastName(contactPersonInfoState.FirstLastName) &&
      (isPhoneValidation(contactPersonInfoState.PhoneNumber, true) ||
        contactPersonInfoState.PhoneNumber.length === 0) &&
      (isEmailValidation(contactPersonInfoState.Email) || contactPersonInfoState.Email.length === 0)
    );
  };

  if ((!updateProfileLoading || !loading) && !error && !updateProfileError) return <Loader />;
  if (submittingNow()) return <Loader />;

  return (
    <>
      <MetaDecorator title="pageTitle.createTripPages" />

      <Form onSubmit={onSubmitHandler} className="max-width-780">
        <div className="specialAlertContainer">
          <ContentTitle text="pages.step9Edit.contentTitle" />
          {/* Change to Country not Id */}
          <Separater text={countryNames} />
          <EditInput
            id="Reisezeitraum"
            placeholder="input.travelTime.text"
            value={travelStartStop}
            labelText="input.travelTime.text"
            ariaRequired={true}
            onChange={onDestinationChangeHandler}
            readonly={true}
          />

          <HorizontalRule />
          <EditInput
            id="OrganisierteReiseveranstaltung"
            placeholder="pages.editTravel.travelOrg"
            value={destination.OrganisierteReiseveranstaltung}
            labelText="pages.editTravel.travelOrg"
            onChange={(value) =>
              onDestinationChangeHandler({
                target: { id: "OrganisierteReiseveranstaltung", value: value },
              })
            }
            options={[t("input.jaNein.ja"), t("input.jaNein.nein")]}
          />
          {destination.OrganisierteReiseveranstaltung === t("input.jaNein.ja") &&
            <>
              <HorizontalRule />
              <EditInput
                id="Reiseveranstalter"
                placeholder="input.travelOrg.text"
                value={destination.Reiseveranstalter}
                labelText="input.travelOrg.text"
                ariaRequired={true}
                onChange={onDestinationChangeHandler}
              />
            </>
          }

          <HorizontalRule />
          <ProfileComponentStateless
            userInfoState={userInfoState}
            onUserInfoChangeHandler={onUserInfoChangeHandler}
            contactPersonInfoState={contactPersonInfoState}
            onContactPersonInfoChangeHandler={onContactPersonInfoChangeHandler}
          />

          {/* Change to Country not Id */}
          {travel.TravelStops.length > 0 &&
            travel.TravelStops.map((ts, index) => (
              <>
                <div>
                  <Separater text={`${t("input.destination.oneText")} ${ts.Name}`} />
                  <InputCalendar
                    labelText="input.travelTime.text"
                    id={index + 1 + "-reisezeitram"}
                    placeholder="input.travelTime.text"
                    startDate={destinations[index].StopStart}
                    endDate={destinations[index].StopEnd}
                    onChange={(startDate, endDate) => receiveDataForDestination(index, startDate, endDate)}
                    cImg="images/common/editpen.png"
                    cClassName="edit-travel-container__calendar-container"
                    cImgClassName="edit-travel-container__custom-img"
                  />

                  <HorizontalRule />
                  <EditInput
                    id={`Stadt${index}`}
                    placeholder="input.city.text2"
                    value={destinations[index].Comment}
                    labelText="input.city.text2"
                    ariaRequired={true}
                    onChange={(e) => onChangeCommentTravelStops(e, index)}
                  />
                </div>
              </>
            ))}

          {!travel.IsAlone &&
            groupMembers.map((tg, i) => {
              return (
                <TravelMember
                  key={i}
                  id={i}
                  FirstLastName={tg.FirstLastName ?? ""}
                  Birthdate={tg.Birthdate ?? ""}
                  MobilePhone={tg.MobilePhone ?? ""}
                  Email={tg.Email ?? ""}
                  Gender={getGenderTextFromEnum(tg.Gender, t)}
                  Nationality={tg.Nationality}
                  DegreeOfRelationship={tg.DegreeOfRelationship}
                  onChange={onChangeGroupMembers}
                />
              );
            })}
          <HorizontalRule />
          <div style={{ marginTop: "1.7rem" }}>
            <CheckBox
              id="confirmBox"
              state={checked}
              onChangeHandler={onChangeHandler}
              text="pages.step9Edit.checkbox1"
            />
          </div>
          <p style={{ marginTop: "1.2rem" }}>
            {t("pages.dsgvo.infoText")}{" "}
            <a
              href="https://www.bmeia.gv.at/reise-aufenthalt/auslandsservice/datenschutz"
              target="_blank"
              style={{ color: "black" }}
              aria-label={t("pages.dsgvo.title2")}
              rel="noopener noreferrer"
            >
              {t("pages.dsgvo.title")}
            </a>
            .
          </p>
          <p style={{ marginTop: "2.4rem" }}>
            {t("pages.editTravel.saveFuture")}
          </p>
          <ControledRadioInput
            role="radio"
            id="gdprdataretention_yes"
            value={t("input.jaNein.ja")}
            name="gdprdataretention"
            textInfo="input.jaNein.ja"
            ariaLabel="input.jaNein.gdprLabel"
            checked={GDPRDataRetention}
            onChange={onGDPRDataRetentionChange}
            ariaChecked={GDPRDataRetention}
          />
          <ControledRadioInput
            role="radio"
            id="gdprdataretention_no"
            value={t("input.jaNein.nein")}
            name="gdprdataretention"
            textInfo="input.jaNein.nein"
            ariaLabel="input.jaNein.gdprLabel"
            checked={GDPRDataRetention != null && !GDPRDataRetention}
            onChange={onGDPRDataRetentionChange}
            ariaChecked={GDPRDataRetention != null && !GDPRDataRetention}
          />

          <p style={{ marginTop: "2.4rem" }}>
            {t("pages.step9Edit.dsgvo2")}{" "}
            <a
              href="https://www.bmeia.gv.at/reise-aufenthalt/auslandsservice/datenschutz"
              target="_blank"
              style={{ color: "black" }}
              aria-label={t("pages.dsgvo.title2")}
              rel="noopener noreferrer"
            >
              {t("pages.dsgvo.title")}
            </a>
            .
          </p>
          <HiddenSubmitInput />

          <WeiterButton
            disabled={!isValid() || submittingNow()}
            onClick={onClickHandler}
            path={ApplicationRoute.registerUserInfo}
            positioned="static"
            text="pages.step9Edit.next"
            style={{ marginTop: "3.2rem", marginBottom: "5.6rem" }}
          />
        </div>
      </Form>
    </>
  );
};

export default EditTravel;