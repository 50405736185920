import { useTranslation } from "react-i18next";

const InfoContainer = ({ title, date, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="info-container">
      <h2 className="info-container__title">{t(title)}</h2>
      <div className="info-container__date">{t(date)}</div>
      <button
        className="info-container__button"
        type="button"
        onClick={onClick}
      >
        {t("pageTitle.eraseTrip")}
      </button>
    </div>
  );
};

export default InfoContainer;