import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../../common/HorizontalRule";
import InputValidation from "../../../../common/InputValidaiton";
import ApplicationRoute from "../../../../config/routes";
import HeaderContext from "../../../../context/HeaderContext";
import UserContext from "../../../../context/UserContext";
import MetaDecorator from "../../../../utils/MetaDecorator";
import { phoneFormatter, prefilledUserData } from "../../../../utils/helpers";
import { isPhoneValidation } from "../../../../utils/validation";
import ContentTitle from "../../../registration/utils/ContentTitle";
import Form from "../../../registration/utils/Form";
import Input from "../../../registration/utils/Input";
import WeiterButton from "../../../registration/utils/WeiterButton";

//HINT* NOT REGISTRATION
export function ForeignNumber() {
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState("");
  const { t } = useTranslation();

  const { user, setUser } = useContext(UserContext);
  const { setHeader } = useContext(HeaderContext);

  const activate = () => {
    setActive(() => true);
  };

  const [active, setActive] = useState(false);

  useEffect(() => {
    document.documentElement.addEventListener("click", activate);

    return () => document.documentElement.removeEventListener("click", activate);
  }, []);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((6 / 19) * 100),
        title: t("pageTitle.foreignRegistration"),
      };
    });
  }, []);

  const isValid = () => {
    return isPhoneValidation(phoneNumber);
  };

  const updateUser = () => {
    setUser((curr) => {
      return { ...curr, PhoneNumber: phoneNumber || null };
    });
  };

  const onChangeHandler = (e) => {
    let phone = phoneFormatter(phoneNumber, e.target.value);

    setPhoneNumber(phone);
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history.push(ApplicationRoute.foreignRegistrationRegistrationEmail);
    }
  };

  useEffect(() => {
    setPhoneNumber(user.PhoneNumber ?? "");
  }, [user]);

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title="pageTitle.foreignRegistration" />
      <ContentTitle
        id="foreign-registration-phone"
        text={prefilledUserData("prefilledUserData.mobilenum")}
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form id="form" onSubmit={onSubmitHandler} ariaLabelledBy="foreign-registration-phone" className="max-width-380">
        <Input
          id="phoneNumber"
          htmlFor="phoneNumber"
          name="phoneNumber"
          value={phoneNumber}
          labelText="input.mobilenum.text"
          onChange={onChangeHandler}
          placeholder="input.mobilenum.placeholder"
          type="tel"
          labelStyle={{ display: "inline-block" }}
        />

        <InputValidation
          isHidden={!active || isValid()}
          text="errors.notValidPhoneNumber"
        />

        <HiddenSubmitInput />
      </Form>

      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content" />

      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationRegistrationEmail}
        onClick={onClickHandler}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </section>
  );
}