import React from "react";
import { useTranslation } from "react-i18next";

const AnswerNewTravel = () => {
    const { t } = useTranslation();

    return (
        <div className="faq-answer">
            <p className="answer-text">
                {t("pages.faq.answerNewTravel_1")}
            </p>
            <div className="answer-image-container">
                <div className="answer-image-box">
                    <img className="" src="./images/faqs/new-travel-1.png" alt="" />
                </div>
                <div className="answer-image-box">
                    <img className="" src="./images/faqs/new-travel-2.png" alt="" />
                </div>
            </div>
            <p className="answer-text">
                {t("pages.faq.answerNewTravel_2")}
            </p>
            <p className="answer-text">
                {t("pages.faq.answerNewTravel_3")}
            </p>
            <p className="answer-text">
                {t("pages.faq.answerNewTravel_4")}
            </p>

        </div>
    );
};

export default AnswerNewTravel;
