import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import HiddenSubmitInput from "../common/HiddenSubmitInput";
import CustomSelectList from "../components/foreign-registration/utils/CustomSelectList";
import FilteredSelectList from "../components/foreign-registration/utils/FilteredSelectList";
import Input from "../components/registration/utils/Input";
import WeiterButton from "../components/travel-registration/utils/WeiterButton";
import SuccessContext from "../context/SuccessContext";
import {
  dateFormatter,
  getEnumFromGender,
  getUIID,
  hasMiddleName,
  isIn,
  phoneFormatter,
  pluck,
} from "../utils/helpers";
import {
  IsDateBiggerThanTodayValidation,
  IsDateSmallerThan_1900_1_1_Validation,
  hasAtSymbolEmailValidation,
  hasNumber,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isDateValidation,
  isEmailValidation,
  isPhoneValidation,
  isValidFirstAndLastName,
} from "../utils/validation";
import InputValidation from "./InputValidaiton";

const RelativeModal = ({ close, save }) => {
  const { t } = useTranslation();

  const relationships = useMemo(
    () => [
      t("input.relative.options.parent"),
      t("input.relative.options.sibling"),
      t("input.relative.options.child"),
      t("input.relative.options.partner"),
      t("input.relative.options.etc"),
    ],
    [t]
  );

  const relationshipEnums = useMemo(
    () => {
      const rel = {};

      rel[t("input.relative.options.parent")] = 100000000;
      rel[t("input.relative.options.sibling")] = 100000001;
      rel[t("input.relative.options.child")] = 100000002;
      rel[t("input.relative.options.partner")] = 100000003;
      rel[t("input.relative.options.etc")] = 100000004;

      return rel;
    },
    [t]
  );

  const [active, setActive] = useState(false);
  const [fullName, setFullName] = useState("");
  const [relationship, setRelationship] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [nationality, setNationaliy] = useState("");

  const { setSuccessBox } = useContext(SuccessContext);

  const startRef = useRef();

  const isValidRelationShip = () => {
    return relationships.includes(relationship);
  };
  const isPhoneNumberValid = () => {
    return isPhoneValidation(phoneNumber, true);
  };

  const isValid = () => {
    return (
      fullName.length > 0 &&
      isValidFirstAndLastName(fullName) &&
      isValidRelationShip() &&
      ((isEmailValidation(email) && hasTwoCharactersAfterAtSymbolEmailValidation(email)) || email.length === 0) &&
      isPhoneNumberValid() &&
      gender !== "" &&
      isDateValidation(birthdate) &&
      IsDateBiggerThanTodayValidation(birthdate) &&
      !IsDateSmallerThan_1900_1_1_Validation(birthdate) &&
      nationality &&
      !hasMiddleName(fullName)
    );
  };

  function onSave(e) {
    e.preventDefault();

    setSuccessBox((curr) => {
      return {
        ...curr,
        hidden: false,
        text: t("pages.addPerson.addPerson"),
        trigger: getUIID(),
      };
    });

    const { Id } = JSON.parse(localStorage.getItem("countries")).find((el) => {
      return t(el.Name) === t(nationality);
    });

    save(fullName, relationshipEnums[relationship], getEnumFromGender(gender, t), Id, birthdate, email, phoneNumber);
  }

  useEffect(() => {
    startRef.current.focus();
  }, []);

  return (
    <div
      className="modal"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();

          if (isValid()) {
            onSave(e);
          }
        }
      }}
    >
      <button className="modal__close-button" onClick={close} type="button">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
          <g fill="none" fillRule="evenodd">
            <g fill="#000">
              <g>
                <path
                  d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                  transform="translate(-24 -21) translate(19 16)"
                />
              </g>
            </g>
          </g>
        </svg>
      </button>
      <h2 className="modal__heading">{t("pages.addPerson.personTitle")}</h2>
      <div className="modal__form">
        <Input
          labelText="input.vorNachname.text"
          id="relative_firstName"
          placeholder="input.vorNachname.text"
          type="text"
          value={fullName}
          onChange={(e) => {
            setActive(true);
            setFullName(e.target.value);
          }}
          ref={startRef}
        />
        <InputValidation
          style={{ marginBottom: "1.5rem" }}
          isHidden={!active || isValidFirstAndLastName(fullName) || hasMiddleName(fullName)}
          text={
            hasNumber(fullName)
              ? "errors.nameHasNumber"
              : "errors.notValidContactPersonFirstAndLastName"
          }
        />
        <InputValidation
          isHidden={!hasMiddleName(fullName)}
          text="errors.middleName"
          ariaLabel="errors.middleName"
        />

        <CustomSelectList
          id="relative_verwandschaftsgrad"
          label="input.relative.text"
          state={relationship}
          options={relationships}
          onChangeHandler={(value) => setRelationship(value)}
          className="grey-background"
        />

        <CustomSelectList
          id="relative_geschlecht"
          label="input.gender.text"
          state={gender}
          options={[t("input.gender.options.male"), t("input.gender.options.female"), t("input.gender.options.other")]}
          onChangeHandler={(value) => setGender(value)}
          className="grey-background"
        />

        <Input
          id="relatives_birthdate"
          value={birthdate}
          onChange={(e) => setBirthdate(dateFormatter(birthdate, e.target.value))}
          placeholder="input.date.placeholder"
          labelText="input.date.birthdate"
          showValidationBorder={birthdate.length > 0 && !isValid()}
        />
        <InputValidation
          isHidden={!birthdate.length > 0 || isDateValidation(birthdate)}
          text="errors.notValidDate"
        />
        <InputValidation
          isHidden={!birthdate.length > 0 || !IsDateSmallerThan_1900_1_1_Validation(birthdate) || !isDateValidation(birthdate)}
          text="errors.dateOfBirthLessThan_01_01_1900"
        />
        <InputValidation
          isHidden={!birthdate.length > 0 || IsDateBiggerThanTodayValidation(birthdate) || !isDateValidation(birthdate)}
          text="errors.dateBiggerThanToday"
        />

        <FilteredSelectList
          id="relative_nationality"
          label="input.nationality.text"
          state={nationality}
          options={pluck(JSON.parse(localStorage.getItem("countries")), "Name").filter((n) => !isIn(n))}
          onChangeHandler={(value) => setNationaliy(value)}
          className="grey-background"
        />

        <Input
          id="relative_email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="input.email.textOptional"
          type="email"
          labelText="input.email.text"
          showValidationBorder={email.length > 0 && (!isValid() || !hasTwoCharactersAfterAtSymbolEmailValidation(email))}
        />
        <InputValidation
          isHidden={
            !email.length > 0 ||
            !hasAtSymbolEmailValidation(email) ||
            !hasTwoCharactersAfterAtSymbolEmailValidation(email) ||
            isEmailValidation(email)
          }
          text="errors.notValidEmail"
        />
        <InputValidation
          isHidden={!email.length > 0 || hasAtSymbolEmailValidation(email)}
          text="errors.missingAtSymbolEmail"
        />
        <InputValidation
          isHidden={
            !email.length > 0 ||
            !hasAtSymbolEmailValidation(email) ||
            hasTwoCharactersAfterAtSymbolEmailValidation(email)
          }
          text="errors.charactersAfterAtSymbolLessThanTwoEmail"
        />

        <Input
          id="relative_phoneNumber"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(phoneFormatter(phoneNumber, e.target.value))}
          placeholder="input.mobilenum.placeholder"
          type="tel"
          labelText="input.mobilenum.textOptional"
          labelStyle={{ display: "inline-block" }}
        />
        <InputValidation
          isHidden={isPhoneNumberValid()}
          text="errors.notValidPhoneNumber"
        />

        <HiddenSubmitInput />

        <WeiterButton
          disabled={!isValid()}
          path=""
          text="terms.save_quit"
          positioned="static"
          onClick={onSave}
          ariaLabel="pages.contactPerson.weiter"
        />
      </div>
    </div>
  );
};

export default RelativeModal;