import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import apipath from "../../../apipath";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import LoadingPage from "../../travel-registration/LoadingPage";

function InfoPass() {
  const { setHeader } = useContext(HeaderContext);
  const [content, setContent] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: t("pageTitle.infoPass"),
      };
    });

    fetch(`${apipath.pathOrig}app-inhalte/info-zum-gruenen-pass/type/json/`, { method: "GET" })
      .then(data => data.json())
      .then(data => setContent(data.data))
      .catch((err) => console.error(err));
  }, []);

  if (!content || content?.length === 0) {
    return <LoadingPage />
  }

  return (
    <div className="content-pages-container">
      <MetaDecorator title="pages.auslandsregistrierung.info" />
      {content.map(headerBody =>
        <>
          <div className="content-pages-heading" dangerouslySetInnerHTML={{ __html: t(headerBody.header) }} />
          <div className="content-pages-paragraph" dangerouslySetInnerHTML={{ __html: t(headerBody.bodytext) }} />
        </>
      )}
    </div>
  );
}

export default InfoPass;