import { useTranslation } from "react-i18next";

function MapInputResultComponent({ id, country, info, goTo, long, lat, hide }) {
  const { t } = useTranslation();

  const onClickHandler = () => {
    hide();
    goTo((curr) => {
      return { ...curr, lat, lng: long, zoom: 11 };
    });
  };

  const onKeyDownHandler = (e) => {
    if (e.key === "Enter") {
      hide();
      goTo((curr) => {
        return { ...curr, lat, lng: long, zoom: 11 };
      });
    }
  };

  return (
    <div role="listitem">
      <div className="map-input-hr__containter">
        <div className="map-input-hr" />
      </div>

      <div className="map-input-result" onClick={onClickHandler} tabIndex="0" onKeyDown={onKeyDownHandler}>
        <div>
          <div className="map-input-result__country__name">{t(country)}</div>
          <div className="map-input-result__info">{t(info)}</div>
        </div>
        <svg
          className="map-input-result__svg"
          xmlns="http://www.w3.org/2000/svg"
          width="37"
          height="37"
          viewBox="0 0 32 32"
        >
          <g fill="none" fillRule="evenodd" opacity=".9">
            <g>
              <g>
                <path d="M0 0H24V24H0z" transform="translate(-309 -34) matrix(0 -1 1 0 309 58)" />
                <path
                  fill="#9E0529"
                  fillRule="nonzero"
                  d="M7.41 8.58L12 13.17 16.59 8.58 18 10 12 16 6 10z"
                  transform="translate(-309 -34) matrix(0 -1 1 0 309 58)"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default MapInputResultComponent;