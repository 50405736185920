import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import TravelContext from "../../../context/TravelContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import ControledRadioInput from "../../registration/utils/ControledRadioInput";
import Form from "../utils/Form";
import Input from "../utils/Input";
import PageTitle from "../utils/PageTitle";
import WeiterButton from "../utils/WeiterButton";

const TourOperator = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { travel, setTravel } = useContext(TravelContext);

  const { setHeader } = useContext(HeaderContext);

  const [isTourGroup, setIsTourGroup] = useState(travel.IsTourGroup);
  const [tourOperator, setTourOperator] = useState(travel.TourOperator);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t("pageTitle.createTripPages"),
        progress: parseInt((10 / 19) * 100),
      };
    });
  }, []);

  const onClickHandler = (e) => {
    e.preventDefault();

    history.push(ApplicationRoute.addTravelCompanion);

    let travelCopy = Object.assign({}, travel);
    travelCopy.IsTourGroup = isTourGroup;

    if (travelCopy.IsTourGroup) travelCopy.TourOperator = tourOperator;
    else travelCopy.TourOperator = "";

    setTravel(travelCopy);
  };

  const changeRadioHandler = (bool) => {
    setIsTourGroup(bool);
  };

  const changeTourOperator = (e) => {
    setTourOperator(e.target.value);
  };

  return (
    <div className="registration-middle-container">
      <MetaDecorator title="pageTitle.createTripPages" />
      <section className="add-travel ">
        <PageTitle
          id="pages.step1Companion.formLabel"
          text="pages.tourOperator.pageTitle"
        />
        <HorizontalRule className="horizontal-rule-desktop" />
        <Form
          id="form"
          ariaDescribedBy="pages.step1Companion.formLabel"
          className="m-t-l max-width-380"
          onSubmit={onClickHandler}
        >
          <ControledRadioInput
            id="yes"
            value="Yes"
            name="tour-operator"
            textInfo="input.jaNein.ja"
            checked={isTourGroup === true}
            onChange={() => changeRadioHandler(true)}
            ariaChecked={isTourGroup > 0}
          />
          <ControledRadioInput
            id="no"
            value="No"
            name="tour-operator"
            textInfo="input.jaNein.nein"
            checked={isTourGroup === false}
            onChange={() => changeRadioHandler(false)}
            ariaChecked={isTourGroup == 0}
          />

          <HiddenSubmitInput />

          {isTourGroup === true &&
            <Input
              labelText="input.travelOrg.text"
              id="tour-operator-name"
              onChange={changeTourOperator}
              placeholder="input.travelOrg.textOptional"
              value={tourOperator}
              className="m-t-l"
            />
          }
        </Form>
        <HorizontalRule className="horizontal-rule-desktop" />

        <div className="hide-weiter-on-keyboard-content" />
        <WeiterButton
          disabled={isTourGroup === null || typeof isTourGroup === "undefined"}
          path={ApplicationRoute.addTravelCompanion}
          onClick={onClickHandler}
          className="hide-weiter-on-keyboard floating-weiter-buton"
        />
      </section>
    </div>
  );
};

export default TourOperator;