const SelectListSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path d="M0 0H24V24H0z"
                transform="translate(-309 -230) translate(23 214) translate(286 16)" />
              <path
                fill="#9E0529"
                fillRule="nonzero"
                d="M7.41 8.58L12 13.17 16.59 8.58 18 10 12 16 6 10z"
                transform="translate(-309 -230) translate(23 214) translate(286 16)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SelectListSvg;