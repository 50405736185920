import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { isMobile } from "../../utils/helpers";
import MapInput from "../MapInput";
import MapInputResultComponent from "../MapInputResultComponent";
import MainHeaderDesktop from "./MainHeaderDesktop";

const checkForRepresentationBType = (country, value) => {
  let bool = false;
  if (!country.representations || country.representations.length === 0) {
    return bool;
  }

  country.representations.forEach((r) => {
    if (r.type?.toLowerCase().startsWith(value)) {
      bool = true;
    }
  });

  return bool;
};

const checkForRepresentation = (representations, value) => {
  let bool = false;
  if (representations.length === 0) {
    return bool;
  }
  representations.forEach((r) => {
    if (r.city?.toLowerCase().startsWith(value)) {
      bool = true;
    }
  });

  return bool;
};

const getFullName = (country, aliasArr) => {
  const a = aliasArr.length > 0 ? aliasArr[0] + " " : "";
  let b = country;

  if (aliasArr.length > 0) {
    b = "(" + b + ")";
  }

  return a + b;
};

const MapHeader = ({
  title,
  currentProgress,
  onBackButtonClickHandler,
  ariaLabel,
  setMapCenter,
  data,
  value,
  setValue,
  hidden,
  setHidden,
  appHeight,
}) => {
  const { t } = useTranslation();

  const newTitle = title?.replace("%noarrow%", "");
  const hasArrow = !title?.includes("%noarrow%");

  const setMapCenterHandler = () => {
    if (!hidden) {
      setValue("");
    }
    setHidden((curr) => !curr);
  };

  return (
    <>
      {" "}
      <div id="registration-header-id" className="mobile-sticky">
        {!isMobile() && <MainHeaderDesktop className="mobile-hidden" />}
        <header className="register-header fixed-height-header" role="navigation">
          <a
            className="sr-only"
            href="#main"
            onClick={(ev) => {
              ev.preventDefault();
              document.getElementById("main").scrollIntoView();
            }}
          >
            {t("terms.jump_to")}
          </a>
          {hasArrow &&
            <Link
              className="register-header__back-button"
              to="./"
              onClick={onBackButtonClickHandler}
              aria-label={t("terms.back")}
              role="button"
            >
              <img className="register-header__back-button__img" src="./images/common/headerback.png" alt="" />
            </Link>
          }

          <h1 className="register-header__title" aria-label={t(ariaLabel)}>
            {t(newTitle)}
          </h1>

          <button
            role="search"
            onClick={setMapCenterHandler}
            className="register-header__search button-abs"
            aria-label={t("terms.search")}
          >
            <img
              className="register-header__search-img"
              src="./images/common/iconsSearch.png"
              alt={t("terms.search")}
              aria-label={t("terms.search")}
            />
          </button>
        </header>
        {!currentProgress.includes("no") &&
          <div className="progress-bar">
            <div className="current-progress" style={{ width: currentProgress }} />
          </div>
        }
      </div>
      <MapInput
        hidden={hidden}
        onCrossClick={() => {
          setValue("");
          setHidden(true);
        }}
        value={value}
        setValue={setValue}
      />
      <div
        style={{ display: hidden ? "none" : "block", maxHeight: appHeight }}
        className="map-input-result-container"
        role="listbox"
      >
        {data
          .filter((c) => {
            const v = value ? value.toLowerCase() : "";
            return (
              getFullName(c.country, c.countryAlias).toLowerCase().startsWith(v) ||
              checkForRepresentationBType(c, v) ||
              checkForRepresentation(c.representations, v)
            );
          })
          .map((country) => {
            return country.representations
              .filter((r) => r.latitude !== null)
              .map((r) => {
                const v = value?.toLowerCase() ?? "";
                if (
                  (r.type?.toLowerCase().startsWith(v)) ||
                  getFullName(country.country, country.countryAlias).toLowerCase().startsWith(v) ||
                  (r.city?.toLowerCase().startsWith(v))
                ) {
                  return (
                    <MapInputResultComponent
                      key={`${r.orgaUnitUid}-${r.locationUid}`}
                      country={
                        country.countryAlias.length > 0
                          ? country.countryAlias + " (" + country.country + ")"
                          : country.country
                      }
                      info={`${r.city ?? "-"}, ${r.type ?? "-"}`}
                      goTo={setMapCenter}
                      long={r.longitude}
                      lat={r.latitude}
                      hide={() => setHidden(() => true)}
                    />
                  );
                } else {
                  return;
                }
              })
              .filter((r) => !!r);
          })}
      </div>
    </>
  );
};

export default MapHeader;