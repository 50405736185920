export function getClassName(active, reverse, className) {
  return "custom-selectlist" +
    (active ? " custom-selectlist--active" : "") +
    (reverse ? " reverse" : "") +
    (className ? " " + className : "")
}

export function effectInterval(intervalId, setIntervalId, active, setActive, id) {
  clearInterval(intervalId);
  setIntervalId(
    setInterval(() => {
      try {
        if (document.querySelector(`.custom-selectlist--active #${id}`)?.parentElement) {
          const arr = [...document.querySelector(`.custom-selectlist--active #${id}`).parentElement.querySelectorAll("[tabindex], input")].filter((a) => a === document.activeElement);
          if (active && (document.querySelector(`.custom-selectlist--active #${id}`) == null || arr.length === 0)) {
            setActive(false);
          }
        }
      } catch (e) {
        clearInterval(intervalId);
      }
    }, 200)
  );
}

export function listClickHandler(active, setActive, highRef, setReverse) {
  if (!active) {
    document.querySelectorAll(".custom-selectlist--active").forEach((el) => {
      el.classList.remove("custom-selectlist--active");
    });

    if (
      highRef.current.offsetTop + highRef.current.offsetHeight + 200 >
      document.documentElement.offsetHeight
    ) {
      setReverse(true);
    }
  } else setReverse(false);

  setActive(!active);
}

export function activeKeyDownHandler(e, active, setActive, setActiveAlert) {
  if (!active && e.key === "Enter") {
    setActive(() => true);
    setActiveAlert(() => true);
  }
  if (active && e.key === "Enter" && e.target.classList.contains("custom-selectlist__option")) {
    e.target.click();
  }
  if (!active && e.key === "ArrowDown") {
    e.preventDefault();
    setActiveAlert(() => true);
    e.target.click();
    return true;
  }
  if (active && e.key === "Tab") {
    setActive(() => false);
  }

  return false;
}

export function optionClassKeyDownHandler(e) {
  if (e.target.classList.contains("custom-selectlist__option")) {
    if (e.key === "ArrowUp") {
      let el = e.target.previousSibling;
      if (!el.classList.contains("not-selectable")) el.focus();
      else e.target.parentElement.parentElement.focus();
    } else if (e.key === "ArrowDown") {
      let el = e.target.nextSibling;
      if (el) el.focus();
    }

    return true;
  }

  return false;
}

export function getAriaLabel(type, value, t) {
  let transValue = t(value);

  if (type === "countries") {
    return transValue === t("input.select.text") ? setText(value, t) : `${t("input.destination.selected")} ${value}`;
  }

  if (
    transValue === t("input.select.text") ||
    transValue === t("input.bundesland.placeholder") ||
    transValue === t("input.region.placeholder") ||
    transValue === t("input.regionCountry.placeholder") ||
    transValue === t("input.destination.placeholder")
  ) {
    return transValue;
  } else {
    return `${t("input.destination.selected")} ${value}`;
  }
}


export function getAlertChoseDestionation(value, t) {
  if (t(value) === t("input.select.text") || t(value) === t("input.regionCountry.placeholder")) return "";

  return t("input.select.selected") + " " + t(value);
}

export function setText(value, t, isRegion, isTravel) {
  if (!value) return t(value);

  if (t(value) === t("input.select.text")) {
    if (isRegion) return t("input.region.placeholder");
    if (!isTravel) {
      return t("input.regionCountry.placeholder");
    } else {
      return t("input.destination.placeholder");
    }
  }

  return t(value);
}