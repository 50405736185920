//if is isDisabled = true, the first option will be not able to be choosed

import { useTranslation } from "react-i18next";

const SelectList = ({
  id,
  state,
  onChangeHandler,
  options,
  placeHolder,
  ariaLabelledBy,
  isDisabled = false,
  label,
}) => {
  const { t } = useTranslation();

  return (
    <select
      className="select-list"
      id={id}
      value={state}
      onChange={onChangeHandler}
      aria-labelledby={ariaLabelledBy}
      label={t(label)}
    >
      {!!placeHolder &&
        (isDisabled ? (
          <option value="" disabled>
            {t(placeHolder)}
          </option>
        ) : (
          <option value="">{t(placeHolder)}</option>
        ))}
      {options.map((o) => (
        <option key={o} value={o}>
          {t(o)}
        </option>
      ))}
    </select>
  );
};

export default SelectList;