import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../../common/HorizontalRule";
import InputValidation from "../../../../common/InputValidaiton";
import ApplicationRoute from "../../../../config/routes";
import HeaderContext from "../../../../context/HeaderContext";
import UserContext from "../../../../context/UserContext";
import MetaDecorator from "../../../../utils/MetaDecorator";
import { hasMiddleName, isValidContactPerson, phoneFormatter } from "../../../../utils/helpers";
import {
  hasAtSymbolEmailValidation,
  hasNumber,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isEmailValidation,
  isPhoneValidation,
  isValidFirstAndLastName,
} from "../../../../utils/validation";
import ContentTitle from "../../../registration/utils/ContentTitle";
import Form from "../../../registration/utils/Form";
import Input from "../../../registration/utils/Input";
import WeiterButton from "../../../registration/utils/WeiterButton";

const optional = {
  FirstLastName: false,
  PhoneNumber: false,
  Email: false,
};

//HINT* NOT REGISTRATION
export function ForeignEmergencyContact() {
  const history = useHistory();
  const { t } = useTranslation();

  const [optionals, setOptionals] = useState(optional);

  const { user, setUser } = useContext(UserContext);
  const { setHeader } = useContext(HeaderContext);
  const [active, setActive] = useState(false);

  const [contact, setContact] = useState({
    FirstLastName: user.EmergencyFirstLastName ?? "",
    PhoneNumber: user.EmergencyPhoneNumber ?? "",
    Email: user.EmergencyEmail ?? "",
  });

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((9 / 19) * 100),
        title: t("pageTitle.foreignRegistration"),
        ariaLabel: t("pageTitle.foreignRegistration"),
      };
    });
  }, []);

  const isValidPhone = () => {
    return contact.PhoneNumber ? isPhoneValidation(contact.PhoneNumber, true) : true;
  };

  const isValidEmail = () => {
    return contact.Email ? isEmailValidation(contact.Email) : true;
  };

  const isValid = () => {
    return (
      (hasTwoCharactersAfterAtSymbolEmailValidation(contact.Email) || contact.Email.length === 0) &&
      isValidFirstAndLastName(contact.FirstLastName) &&
      (isValidPhone() || contact.PhoneNumber.length === 0) &&
      (isValidEmail() || contact.Email.length === 0) &&
      isValidContactPerson(optionals)
    );
  };

  const updateUser = () => {
    // setUser((curr) => {
    //
    //   return { ...curr, ContactPerson: { ...contact } };
    // });
  };

  const onChangeHandler = (e) => {
    setActive(true);
    setContact((curr) => {
      if (e.target.id === "PhoneNumber") {
        return {
          ...curr,
          [e.target.id]: phoneFormatter(contact.PhoneNumber, e.target.value),
        };
      }

      return { ...curr, [e.target.id]: e.target.value };
    });
  };

  const setOptionalPhoneToTrue = (phone, email) => {
    if (email) {
      return true;
    }
    return isPhoneValidation(phone);
  };

  useEffect(() => {
    setOptionals((curr) => {
      return {
        ...curr,
        FirstLastName: !!contact.FirstLastName,
        PhoneNumber: setOptionalPhoneToTrue(contact.PhoneNumber, contact.Email),
        Email: !!contact.Email,
      };
    });
  }, [contact]);

  const onClickHandler = () => {
    let userCopy = Object.assign({}, user);
    userCopy.ContactPerson = { ...contact };
    userCopy.EmergencyFirstLastName = contact.FirstLastName;
    userCopy.EmergencyPhoneNumber = contact.PhoneNumber;
    userCopy.EmergencyEmail = contact.Email;
    setUser(userCopy);
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history.push(ApplicationRoute.foreignRegistrationAddRelatives);
    }
  };

  useEffect(() => {
    if (user && user.ContactPerson) {
      setContact((curr) => {
        return {
          ...curr,
          FirstLastName: user.ContactPerson.FirstLastName ?? "",
          PhoneNumber: user.ContactPerson.PhoneNumber ?? "",
          Email: user.ContactPerson.Email ?? "",
        };
      });
    }
  }, [user]);

  return (
    <section
      className="foreign-registration registration-middle-container"
      style={{
        paddingBottom: "120px",
      }}
    >
      <MetaDecorator title="pageTitle.foreignRegistration" />
      <ContentTitle
        id="emergency-contact"
        text="pages.emergencyContact.contentTitle"
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form id="form" onSubmit={onsubmitHandler} ariaLabelledBy="emergency-contact" className="max-width-380">
        <Input
          id="FirstLastName"
          value={contact.FirstLastName}
          onChange={onChangeHandler}
          placeholder="input.vorNachname.text"
          labelText="input.vorNachname.text"
          showValidationBorder={active && !isValidFirstAndLastName(contact.FirstLastName)}
        />

        <InputValidation
          isHidden={!active || isValidFirstAndLastName(contact.FirstLastName) || hasMiddleName(contact.FirstLastName)}
          text={
            hasNumber(contact.FirstLastName)
              ? "errors.nameHasNumber"
              : "errors.notValidContactPersonFirstAndLastName"
          }
        />
        <InputValidation
          isHidden={!hasMiddleName(contact.FirstLastName)}
          text="errors.middleName"
          ariaLabel="errors.middleName"
        />

        <Input
          id="PhoneNumber"
          value={contact.PhoneNumber}
          onChange={onChangeHandler}
          placeholder="input.mobilenum.placeholder"
          ariaRequired={false}
          labelText="input.mobilenum.text"
          labelStyle={{ display: "inline-block" }}
        />

        <InputValidation isHidden={isValidPhone()} text="errors.notValidPhoneNumber" />

        <Input
          id="Email"
          value={contact.Email}
          onChange={onChangeHandler}
          placeholder="input.email.text"
          ariaRequired={false}
          labelText="input.email.text"
          showValidationBorder={
            contact.Email.length !== 0 &&
            (!hasAtSymbolEmailValidation(contact.Email) ||
              !hasTwoCharactersAfterAtSymbolEmailValidation(contact.Email) ||
              !isEmailValidation(contact.Email))
          }
        />

        <InputValidation
          isHidden={
            contact.Email.length === 0 ||
            !hasAtSymbolEmailValidation(contact.Email) ||
            !hasTwoCharactersAfterAtSymbolEmailValidation(contact.Email) ||
            isEmailValidation(contact.Email)
          }
          text="errors.notValidEmail"
        />

        <InputValidation
          isHidden={contact.Email.length === 0 || hasAtSymbolEmailValidation(contact.Email)}
          text="errors.missingAtSymbolEmail"
        />

        <InputValidation
          isHidden={
            contact.Email.length === 0 ||
            !hasAtSymbolEmailValidation(contact.Email) ||
            hasTwoCharactersAfterAtSymbolEmailValidation(contact.Email)
          }
          text="errors.charactersAfterAtSymbolLessThanTwoEmail"
        />

        <HiddenSubmitInput />
      </Form>

      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content" />

      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationAddRelatives}
        onClick={onClickHandler}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </section>
  );
}