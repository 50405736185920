import { useTranslation } from "react-i18next";

const ContentTitle = ({ id, text, style, tabIndex }) => {
  const { t } = useTranslation();

  return (
    <p style={style} className="content-title" id={id} tabIndex={tabIndex}>
      {t(text)}
    </p>
  );
};

export default ContentTitle;