import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ArrowRight from "../../../svg/ArrowRight.js";

function NavigationCard({ id, text, to, href }) {
  const { t } = useTranslation();

  return (
    <div>
      <Link className="navigation-info-link" style={{ textDecoration: "none", color: "#000000" }} id={id} to={to}>
        <div className="navigation-info-cards">
          <div className="country__name">{t(text)}</div>

          <ArrowRight />
        </div>
      </Link>
    </div>
  );
}

export default NavigationCard;