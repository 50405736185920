import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HiddenSubmitInput from "../../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../../common/HorizontalRule";
import InputValidation from "../../../../common/InputValidaiton";
import ApplicationRoute from "../../../../config/routes";
import HeaderContext from "../../../../context/HeaderContext";
import UserContext from "../../../../context/UserContext";
import MetaDecorator from "../../../../utils/MetaDecorator";
import { getKeyByValue, prefilledUserData } from "../../../../utils/helpers";
import {
  hasMinLettersValidation,
  hasNumber,
  hasOnlyLettersValidation,
} from "../../../../utils/validation";
import ContentTitle from "../../../registration/utils/ContentTitle";
import Form from "../../../registration/utils/Form";
import Input from "../../../registration/utils/Input";
import WeiterButton from "../../../registration/utils/WeiterButton";

//HINT* NOT REGISTRATION
export function ForeignName() {
  const history = useHistory();
  const { user, setUser } = useContext(UserContext);
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((2 / 19) * 100),
        title: t("pageTitle.foreignRegistration"),
        ariaLabel: t("pageTitle.foreignRegistration"),
      };
    });
  }, []);

  const [fullName, setFullName] = useState({
    firstName: "",
    lastName: "",
  });

  const [errors, setErrors] = useState({
    firstName: { hasOnlyLetters: false, hasMinTwoLetters: false },
    lastName: { hasOnlyLetters: false, hasMinTwoLetters: false },
  });

  const isValid = () => {
    return !(
      getKeyByValue(errors.firstName, false) ||
      getKeyByValue(errors.lastName, false)
    );
  };

  const updateUser = () => {
    setUser((curr) => {
      return {
        ...curr,
        FirstName: fullName.firstName,
        LastName: fullName.lastName,
      };
    });
  };

  useEffect(() => {
    setFullName((curr) => {
      return {
        ...curr,
        firstName: user.FirstName ?? "",
        lastName: user.LastName ?? "",
      };
    });
  }, [user]);

  useEffect(() => {
    Object.keys(fullName).forEach((key) => {
      setErrors((currErrs) => {
        return {
          ...currErrs,
          [key]: {
            ...currErrs[key],
            hasOnlyLetters: hasOnlyLettersValidation(fullName[key]) || true,
            hasMinTwoLetters: hasMinLettersValidation(fullName[key], 2),
          },
        };
      });
    });
  }, [fullName]);

  const onChangeHandler = (e) => {
    setFullName((curr) => {
      return { ...curr, [e.target.id]: e.target.value };
    });
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history.push(ApplicationRoute.foreignRegistrationRegistrationBirthdate);
    }
  };

  return (
    <section className="foreign-registration registration-middle-container">
      <MetaDecorator title="pageTitle.permanentOtsideCountry" />
      <ContentTitle
        id="foreign-registration-name"
        text={prefilledUserData("prefilledUserData.name")}
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        className="max-width-380"
        id="form"
        onSubmit={onSubmitHandler}
        ariaLabelledBy="foreign-registration-name"
      >
        <Input
          id="firstName"
          placeholder="input.vorname.text"
          type="text"
          value={fullName.firstName}
          onChange={onChangeHandler}
          labelText="input.vorname.text"
          showValidationBorder={
            fullName.firstName.length > 0 &&
            !hasMinLettersValidation(fullName.firstName)
          }
        />
        <InputValidation
          text={
            hasNumber(fullName.firstName)
              ? "errors.nameHasNumber"
              : "errors.firstNameLessCharacters"
          }
          isHidden={
            !fullName.firstName.length > 0 ||
            hasMinLettersValidation(fullName.firstName)
          }
        />

        <Input
          id="lastName"
          placeholder="input.nachname.text"
          type="text"
          value={fullName.lastName}
          onChange={onChangeHandler}
          labelText="input.nachname.text"
          showValidationBorder={
            fullName.lastName.length > 0 &&
            !hasMinLettersValidation(fullName.lastName)
          }
        />
        <InputValidation
          alert="errors.lastNameLessCharacters"
          text={
            hasNumber(fullName.lastName)
              ? "errors.nameHasNumber"
              : "errors.lastNameLessCharacters"
          }
          isHidden={
            !fullName.lastName.length > 0 ||
            hasMinLettersValidation(fullName.lastName)
          }
        />

        <HiddenSubmitInput />
      </Form>

      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content" />

      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.foreignRegistrationRegistrationBirthdate}
        onClick={onClickHandler}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </section>
  );
}