import { useTranslation } from "react-i18next";
import { getGenderTextFromEnum } from "../../../utils/helpers.js";
import TravelMember from "./TravelMember.jsx";

const TravelGroup = (
  {
    groupMembers,
    onChange,
    foreign = false,
    deleteHandler,
    buttonText = 'terms.delete',
  }) => {
  const { t } = useTranslation();
  return (
    <>
      {groupMembers.map((tg, i) => {
        return (
          <TravelMember
            key={i}
            id={i}
            FirstLastName={tg.FirstLastName ?? ""}
            Birthdate={tg.Birthdate ?? ""}
            MobilePhone={tg.MobilePhone ?? ""}
            Email={tg.Email ?? ""}
            Gender={getGenderTextFromEnum(tg.Gender, t)}
            Nationality={tg.Nationality}
            DegreeOfRelationship={tg.DegreeOfRelationship}
            onChange={onChange}
            foreign={foreign}
            delete={deleteHandler}
            buttonText={buttonText}
            buttonClickHandler={deleteHandler ? () => deleteHandler(i) : null}
          />
        );
      })}
    </>
  );
};

export default TravelGroup;