import { useTranslation } from "react-i18next";

const ClearContanctDiv = ({ id = "clearContactId", text, onClick, description = "terms.delete" }) => {
  const { t } = useTranslation();

  return (
    <h2 className="clear-contact-div">
      <div className="clear-contact-div__title">{t(text)}</div>
      {/* <button
        id={id}
        type="button"
        className="clear-contact-div__clear-link"
        onClick={onClick}
      >
        {t(description)}
      </button> */}
    </h2>
  );
};

export default ClearContanctDiv;