import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import InputValidation from "../../../common/InputValidaiton";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import {
  dateFormatter,
  goToNext
} from "../../../utils/helpers";
import {
  IsDateBiggerThanTodayValidation,
  IsDateSmallerThan_1900_1_1_Validation,
  isDateValidation
} from "../../../utils/validation";
import Form from "../../registration/utils/Form";
import Input from "../../registration/utils/Input";
import WeiterButton from "../../registration/utils/WeiterButton";
import PageTitle from "../../travel-registration/utils/PageTitle";

const AddRelative2 = () => {
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  const history = useHistory();

  let initdate = "";
  try {
    initdate = JSON.parse(localStorage.getItem("newTGM")).Birthdate ?? "";
  } catch (error) { }

  const [date, setDate] = useState(initdate);
  const [placeHolder, setPlaceHolder] = useState("input.date.birthdate");
  const [active, setActive] = useState(false);

  useEffect(() => {
    let title = "input.mitreisende.addTitle";

    try {
      title = localStorage.getItem("addTGMTitle");
    } catch (error) { }

    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        title: t(title),
        progress: "no",
      };
    });
  }, []);

  const isValidDate = () => {
    return isDateValidation(date);
  };

  const IsDateSmallerThan1900 = () => {
    return IsDateSmallerThan_1900_1_1_Validation(date);
  };

  const IsDateBiggerThanToday = () => {
    return IsDateBiggerThanTodayValidation(date);
  };

  const isValid = () => {
    return isValidDate() && IsDateBiggerThanToday() && !IsDateSmallerThan1900();
  };

  const onChangeHandler = (e) => {
    let newDate = dateFormatter(date, e.target.value);
    setActive(true);
    setDate(newDate);
  };

  const onClickInputHandler = () => {
    setPlaceHolder("input.date.placeholder");
  };

  const updateTGM = () => {
    let tgm = JSON.parse(localStorage.getItem("newTGM"));
    localStorage.setItem("newTGM", JSON.stringify({ ...tgm, Birthdate: date }));
  };

  const onClickHandler = (e) => {
    goToNext(e, isValid, updateTGM, ApplicationRoute.addRelative3, history);
  };

  const onsubmitHandler = (e) => {
    goToNext(e, isValid, updateTGM, ApplicationRoute.addRelative3, history);
  };

  return (
    <div className="add-relative-container registration-middle-container">
      <MetaDecorator title="pageTitle.editTrip" />
      <PageTitle id="input.date.birthdateInfo" text="input.date.birthdateInfo" />

      <p className="page-paragraph page-paragraph--small">{t("input.mitreisende.one")}</p>

      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        id="form"
        onSubmit={onsubmitHandler}
        ariaLabelledBy="input.date.birthdateInfo"
        className="max-width-380"
      >
        <Input
          id="date"
          value={date}
          onChange={onChangeHandler}
          onClick={onClickInputHandler}
          placeholder={placeHolder}
          labelText="input.date.birthdate"
          showValidationBorder={!isValid()}
        />
        <InputValidation
          isHidden={!active || isValidDate()}
          text="errors.notValidDate"
        />

        <InputValidation
          isHidden={!active || !IsDateSmallerThan1900() || !isValidDate()}
          text="errors.dateOfBirthLessThan_01_01_1900"
        />

        <InputValidation
          isHidden={!active || IsDateBiggerThanToday() || !isValidDate()}
          text="errors.dateBiggerThanToday"
        />

        <HiddenSubmitInput />
      </Form>

      <HorizontalRule className="horizontal-rule-desktop" />
      <div className="hide-weiter-on-keyboard-content" />

      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.addRelative3}
        onClick={onClickHandler}
        className="floating-weiter-buton"
      />
    </div>
  );
};

export default AddRelative2;