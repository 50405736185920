import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import HorizontalRule from "../../../common/HorizontalRule";
import InputValidation from "../../../common/InputValidaiton";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import UserContext from "../../../context/UserContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import { prefilledUserData } from "../../../utils/helpers";
import {
  isPassnumberValidation,
  isPersonalNumberValidation,
} from "../../../utils/validation";
import ContentTitle from "../utils/ContentTitle";
import Form from "../utils/Form";
import Input from "../utils/Input";
import WeiterButton from "../utils/WeiterButton";

const PassNumberPage = () => {
  const history = useHistory();
  const [passNumber, setPassNumber] = useState("");
  const [IdNumber, setIdNumber] = useState("");
  const { t } = useTranslation();

  const { user, setUser } = useContext(UserContext);
  const { setHeader } = useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: parseInt((8 / 19) * 100),
        title: t("pageTitle.createTripPages"),
      };
    });
  }, []);

  const isValid = () => {
    return (
      isPassnumberValidation(passNumber) && isPersonalNumberValidation(IdNumber)
    );
  };

  const updateUser = () => {
    setUser((curr) => {
      return { ...curr, PassNumber: passNumber, IdNumber: IdNumber };
    });
  };

  const onChangeHandler = (e) => {
    setPassNumber(e.target.value);
  };

  const onChangeHandlerPersonalId = (e) => {
    setIdNumber(e.target.value);
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history.push(ApplicationRoute.registerContactPerson);
    }
  };

  useEffect(() => {
    setPassNumber(user.PassNumber ?? "");
    setIdNumber(user.IdNumber ?? "");
  }, [user]);

  return (
    <div className="registration-middle-container">
      <MetaDecorator title="pageTitle.createTripPages" />
      <ContentTitle
        text={
          passNumber || IdNumber
            ? prefilledUserData("prefilledUserData.passport")
            : "prefilledUserData.passport"
        }
      />
      <HorizontalRule className="horizontal-rule-desktop" />
      <Form
        className="max-width-380"
        id="form"
        onSubmit={onSubmitHandler}
        ariaLabelledBy="contentTitleId"
      >
        <Input
          id="passNumber"
          value={passNumber}
          onChange={onChangeHandler}
          placeholder="input.passport.text"
          ariaRequired={false}
          labelText="input.passport.text"
          showValidationBorder={!isPassnumberValidation(passNumber)}
        />

        <InputValidation
          isHidden={isPassnumberValidation(passNumber)}
          text="errors.notValidPassnumber"
        />

        <Input
          id="IdNumber"
          value={IdNumber}
          onChange={onChangeHandlerPersonalId}
          placeholder="input.personalIdCard.text"
          ariaRequired={false}
          labelText="input.personalIdCard.text"
          showValidationBorder={!isPersonalNumberValidation(IdNumber)}
        />

        <InputValidation
          isHidden={isPersonalNumberValidation(IdNumber)}
          text="errors.notValidPersonalNumber"
        />

        <HiddenSubmitInput />
      </Form>

      <HorizontalRule className="horizontal-rule-desktop" />

      <div className="hide-weiter-on-keyboard-content" />
      <WeiterButton
        disabled={!isValid()}
        path={ApplicationRoute.registerContactPerson}
        onClick={onClickHandler}
        className="hide-weiter-on-keyboard floating-weiter-buton"
      />
    </div>
  );
};

export default PassNumberPage;