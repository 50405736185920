import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HiddenSubmitInput from "../../../common/HiddenSubmitInput";
import ApplicationRoute from "../../../config/routes";
import HeaderContext from "../../../context/HeaderContext";
import UserContext from "../../../context/UserContext";
import MetaDecorator from "../../../utils/MetaDecorator";
import ContentTitle from "../utils/ContentTitle";
import Form from "../utils/Form";
import SelectList from "../utils/SelectList";
import WeiterButton from "../utils/WeiterButton";

const options = ["Austria", "Austria2", "Austria3"]; // Wird die Seite überhazpt verwendet???

const NationalityPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [nationality, setNationality] = useState("");

  const { user, setUser } = useContext(UserContext);
  const { setHeader } = useContext(HeaderContext);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "registration",
        progress: "80",
        title: t("pageTitle.registrationPages"),
      };
    });
  }, []);

  const isValid = () => {
    return !!nationality;
  };

  const updateUser = () => {
    setUser((curr) => {
      return { ...curr, Nationality: nationality };
    });
  };

  const onChangeHandler = (e) => {
    setNationality(e.target.value);
  };

  const onClickHandler = () => {
    updateUser();
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (isValid()) {
      updateUser();
      history.push(ApplicationRoute.registerForeignCountry);
    }
  };

  useEffect(() => {
    setNationality(user.Nationality ?? "");
  }, [user]);

  return (
    <>
      <MetaDecorator title="pageTitle.registrationPages" />
      <ContentTitle id="contentTitle" text="pages.nationality.contentTitle" />
      <Form
        id="form"
        onSubmit={onSubmitHandler}
        ariaLabelledBy="contentTitleId"
      >
        <SelectList
          id="selectListId"
          ariaLabelledBy="contentTitle"
          placeHolder="input.nationality.placeholder"
          state={nationality}
          onChangeHandler={onChangeHandler}
          options={options}
        />

        <HiddenSubmitInput />

        <WeiterButton
          disabled={!isValid()}
          path={ApplicationRoute.registerForeignCountry}
          onClick={onClickHandler}
        />
      </Form>
    </>
  );
};

export default NationalityPage;