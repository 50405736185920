import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import EditInput from "../../../common/EditInput";
import HorizontalRule from "../../../common/HorizontalRule";
import InputValidation from "../../../common/InputValidaiton";
import { getEnumFromGenderText, getGenderTextFromEnum, hasMiddleName } from "../../../utils/helpers";
import {
  IsDateBiggerThanTodayValidation,
  IsDateSmallerThan_1900_1_1_Validation,
  hasAtSymbolEmailValidation,
  hasNumber,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isDateValidation,
  isEmailValidation,
  isOlderOrIsEighteenYearsOlds,
  isPhoneValidation,
  isValidFirstAndLastName,
} from "../../../utils/validation";

const Relative = ({
  onChange,
  FirstLastName,
  Email,
  PhoneNumber,
  Gender,
  BirthDate,
  Nationality,
  DegreeOfRelationship,
  countries,
  index = "",
}) => {
  Email = Email ?? "";
  FirstLastName = FirstLastName ?? "";
  PhoneNumber = PhoneNumber ?? "";

  const { t } = useTranslation();

  const relationships = useMemo(
    () => [
      t("input.relative.options.parent"),
      t("input.relative.options.sibling"),
      t("input.relative.options.child"),
      t("input.relative.options.partner"),
      t("input.relative.options.etc"),
    ],
    [t]
  );

  return (
    <>
      <EditInput
        id={`id${index}-Name`}
        placeholder="input.vorNachname.text"
        value={FirstLastName}
        labelText="input.vorNachname.text"
        ariaRequired={true}
        onChange={onChange}
      />
      <InputValidation
        text={
          hasNumber(FirstLastName)
            ? "errors.nameHasNumber"
            : "errors.notValidContactPersonFirstAndLastName"
        }
        isHidden={isValidFirstAndLastName(FirstLastName) || hasMiddleName(FirstLastName)}
      />
      <InputValidation
        isHidden={!hasMiddleName(FirstLastName)}
        text="errors.middleName"
        ariaLabel="errors.middleName"
      />
      <HorizontalRule />
      <EditInput
        id={`id${index}-MobilePhone`}
        placeholder="input.contact.placeholder"
        value={PhoneNumber}
        labelText="input.mobilenum.text"
        ariaRequired={true}
        onChange={onChange}
      />
      <InputValidation
        text="errors.notValidPhoneNumber"
        isHidden={isPhoneValidation(PhoneNumber, true) || PhoneNumber.length === 0}
      />

      <HorizontalRule />

      <EditInput
        id={`id${index}-Gender`}
        placeholder="input.gender.text"
        value={getGenderTextFromEnum(Gender, t)}
        labelText="input.gender.text"
        ariaRequired={true}
        onChange={(value) =>
          onChange({
            target: {
              id: `id${index}-Gender`,
              value: getEnumFromGenderText(value, t),
            },
          })
        }
        options={[t("input.gender.options.male"), t("input.gender.options.female"), t("input.gender.options.other")]}
      />

      <HorizontalRule />
      <EditInput
        id={`id${index}-Birthdate`}
        placeholder="input.date.birthdate"
        value={BirthDate}
        labelText="input.date.birthdate"
        ariaRequired={true}
        onChange={onChange}
      />
      <InputValidation text="errors.notValidDate" isHidden={isDateValidation(BirthDate)} />
      <InputValidation
        text="errors.ageLessThan_18"
        isHidden={
          !IsDateBiggerThanTodayValidation(BirthDate) ||
          !isDateValidation(BirthDate) ||
          isOlderOrIsEighteenYearsOlds(BirthDate)
        }
      />
      <InputValidation
        text="errors.dateBiggerThanToday"
        isHidden={!isDateValidation(BirthDate) || IsDateBiggerThanTodayValidation(BirthDate)}
      />
      <InputValidation
        text="errors.dateOfBirthLessThan_01_01_1900"
        isHidden={!isDateValidation(BirthDate) || !IsDateSmallerThan_1900_1_1_Validation(BirthDate)}
      />

      <HorizontalRule />
      <EditInput
        id={`id${index}-Nationality`}
        labelText="input.nationality.text2"
        value={Nationality}
        onChange={(value) =>
          onChange({
            target: { id: `id${index}-Nationality`, value: value },
          })
        }
        options={countries}
        filtered={true}
      />

      <HorizontalRule />
      <EditInput
        id={`id${index}-DegreeOfRelationship`}
        labelText="input.relative.text"
        value={DegreeOfRelationship}
        onChange={(value) =>
          onChange({
            target: { id: `id${index}-DegreeOfRelationship`, value: value },
          })
        }
        options={relationships}
      />

      <HorizontalRule />
      <EditInput
        id={`id${index}-Email`}
        placeholder="input.email.text"
        value={Email}
        labelText="input.email.text"
        ariaRequired={false}
        onChange={onChange}
      />
      <InputValidation
        isHidden={
          Email.length === 0 ||
          !hasAtSymbolEmailValidation(Email) ||
          !hasTwoCharactersAfterAtSymbolEmailValidation(Email) ||
          isEmailValidation(Email)
        }
        text="errors.notValidEmail"
      />
      <InputValidation
        isHidden={Email.length === 0 || hasAtSymbolEmailValidation(Email)}
        text="errors.missingAtSymbolEmail"
      />
      <InputValidation
        isHidden={
          Email.length === 0 ||
          !hasAtSymbolEmailValidation(Email) ||
          hasTwoCharactersAfterAtSymbolEmailValidation(Email)
        }
        text="errors.charactersAfterAtSymbolLessThanTwoEmail"
      />
    </>
  );
};

export default Relative;