import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Separater = ({ text, secondRow, buttonText, buttonClickHandler, linkText, linkTo, isHidden }) => {
  const { t } = useTranslation();

  return (
    <div style={{ padding: "28px 0px" }} className="clear-contact-div" hidden={isHidden} aria-hidden={isHidden}>
      <div className="clear-contact-div__separater-title">
        <h2 style={{ fontSize: "1.6rem" }}>
          {t(text)}

          {secondRow &&
            <>
              <br />
              {t(secondRow)}
            </>
          }
        </h2>

        {buttonText && buttonClickHandler &&
          <button className="clear-contact-div__separater-button" type="button" onClick={buttonClickHandler}>
            {t(buttonText)}
          </button>
        }

        {linkText && linkTo &&
          <Link className="clear-contact-div__separater-button" to={linkTo} onClick={buttonClickHandler}>
            {t(linkText)}
          </Link>
        }
      </div>
    </div>
  );
};

export default Separater;