import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import apipath from "../../../apipath";
import ArrowRight from "../../../svg/ArrowRight";
import Star from "../../../svg/Star";
import { isBlockedFlag } from "../../../utils/helpers";

const CountryCard = ({
  flag,
  countryName,
  riskLvl,
  id,
  Id,
  favourite = false,
  favouriteToggle,
  onClick,
  countryUid,
  hasStar = true,
  role = "",
}) => {
  const { t } = useTranslation();

  function savePosition(ev) {
    localStorage.setItem("countryOverviewPosition", window.scrollY);
  }

  if (isBlockedFlag(countryName)) {
    flag = `${apipath.pathBMEIAgvat_API}flags/.png`;
  }

  return (
    <div className="country__card" role={role}>
      <Link
        style={{
          textDecoration: "none",
          color: "#000000",
        }}
        aria-label={`${t("pages.countryList.geheZu")} ${t(countryName)} ${t(riskLvl)}`}
        role="button"
        to={`/country/${countryUid}`}
        onClick={savePosition}
      >
        <div className="country__flag">
          <img className="country__flag--round" src={flag} alt="" onError={(ev) => ev.currentTarget.remove()} />
        </div>
        <div className="country__name">
          <p>{countryName}</p>
          {riskLvl && <p className="country__risk">{t(riskLvl)}</p>}
        </div>

        <div className="arrow__right" id={Id} onClick={onClick}>
          <ArrowRight />
        </div>
      </Link>{" "}
      <div
        className="favorit__icon"
        onClick={(ev) => {
          favouriteToggle(ev, id);
        }}
      >
        {hasStar && <Star favourite={favourite} />}
      </div>
    </div>
  );
};

export default CountryCard;