const defaultUserOrig = {
  UsedId: "",
  State: "",
  Nationality: "",
  NationalityUIID: "",
  LivingIn: "",
  LivingInUIID: "",
  Country: "",
  CountryUIID: "",
  County: "",
  CountyUIID: "",
  FirstName: "",
  LastName: "",
  City: "",
  CityUIID: "",
  Street: "",
  PostalCode: "",
  Email: "",
  Password: "",
  SocialSecurityNumber: "",
  PassNumber: "",
  BirthDate: "",
  Gender: "",
  PhoneNumber: "",
  Passport: "",
  IdNumber: "",
  IsLivingAbroad: "",
  ContactPerson: { FirstLastName: "", PhoneNumber: "", Email: "" },
  FamilyGroupMember: [],
  Relatives: [],
  TempDeletedContact: "",
  TempDeletedRelatives: [],
  HasPersonInit: "",
  HasRelativesInit: "",
  TempPerson: "",
  TempRelatives: [],
  SaveChanges: false,
};

const defaultUserValue = {
  ...defaultUserOrig,
  ContactPerson: { ...defaultUserOrig.ContactPerson },
};

export default defaultUserValue;