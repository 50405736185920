import React from "react";
import { useTranslation } from "react-i18next";

const AnswerNewStays = () => {
    const { t } = useTranslation();

    return (
        <>
            <p className="answer-text">
                {t("pages.faq.answerNewStays")}
            </p>
            <div className="answer-image-container">
                <div className="answer-image-box">
                    <img className="" src="./images/faqs/new-stays-1.png" alt="" />
                </div>
            </div>
        </>
    );
};

export default AnswerNewStays;
