import { useContext, useEffect, useState } from "react";
import apipath from "../../apipath";
import HeaderContext from "../../context/HeaderContext";
import PushContext from "../../context/PushContext";
import fetcher from "../../services/fetcher";
import MetaDecorator from "../../utils/MetaDecorator";
import {
  getDistance,
  isEmptyObject,
  toShowRepresentation,
} from "../../utils/helpers";
import {
  getCountryNameFromId,
  getCountryUidFromName,
  getIsoCodeFromName,
} from "../travel-registration/utils/countryInfo";
import SecondPushStepComponent from "./utils/2-push-step-component";

const initCard = {
  country: "",
  img: "",
  phones: [""],
  address: "",
  flag: "",
};

const SecondPushStep = () => {
  const { setHeader } = useContext(HeaderContext);
  const { pushNotifcations } = useContext(PushContext);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [, setSubmitting] = useState(false);

  const [showCrisisCard, setShowCrisisCard] = useState(false);

  const [repLocation, setRepLocation] = useState({ long: "", lat: "" });

  const [card, setCard] = useState(initCard);

  const getCrisis = () => {
    fetcher(
      `${apipath.path}crisis/${pushNotifcations.crisisId}`,
      "GET",
      setData,
      setError,
      setLoading,
      setSubmitting,
      true,
      "application/json"
    );
  };

  useEffect(() => {
    if (!pushNotifcations || !isEmptyObject(pushNotifcations)) {
      getCrisis();
    }
  }, [pushNotifcations]);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "noHeader",
      };
    });
  }, []);

  useEffect(() => {
    if (!loading && !error) {
      let countries = JSON.parse(localStorage.getItem("countriesVB")).countries;

      let isoCODE = getIsoCodeFromName(getCountryNameFromId(data?.CrisisAreas?.[0].CountryId));

      let country = countries.filter((c) => c.countryIsoCode === isoCODE)[0];

      let countryUID = getCountryUidFromName(getCountryNameFromId(data?.CrisisAreas?.[0].CountryId));

      //No country in bmeia.gv.at
      if (!country) {
        return;
      }

      let flag = `${apipath.pathBMEIAgvat_API}flags/${isoCODE.toLowerCase()}.png`;

      let img = `${apipath.pathBMEIAgvat_API}country/${countryUID}.jpg`;

      let address = "";
      let numbers = [];
      let type = "";
      let city = "";

      country.representations = country.representations.filter((r) => toShowRepresentation(r.type));

      if (country.representations.length > 0) {
        setShowCrisisCard(true);

        if (country.representations.length === 1) {
          setRepLocation((curr) => {
            return {
              ...curr,
              long: country.representations[0].longitude,
              lat: country.representations[0].latitude,
            };
          });

          //numbers
          if (country.representations[0].phone.length > 0) {
            country.representations[0].phone.forEach((obj) => {
              numbers.push(obj.number);
            });
          }
          if (country.representations[0].mobile.length > 0) {
            country.representations[0].mobile.forEach((obj) => {
              numbers.push(obj.number);
            });
          }
          address = country.representations[0].address;
          type = country.representations[0].type;
          city = country.representations[0].city;
        }
        //More than one representation => finding nearest
        else if (country.representations.length > 1) {
          let distances = [];
          for (let index = 0; index < country.representations.length; index++) {
            let userLat = pushNotifcations.latitude;
            let userLong = pushNotifcations.longitude;

            let latitudeRepre = country.representations[index].latitude;
            let longitudeRepre = country.representations[index].longitude;

            if (!latitudeRepre || !longitudeRepre) {
              latitudeRepre = 99999999;
              longitudeRepre = 99999999;
            }

            distances.push(getDistance(userLat, userLong, latitudeRepre, longitudeRepre, index));
          }

          const minDistanceArrayIndex = distances.reduce((prev, curr) => (prev.distance < curr.distance ? prev : curr))
            .arrayIndex;

          if (country.representations[minDistanceArrayIndex].phone.length > 0) {
            country.representations[minDistanceArrayIndex].phone.forEach((obj) => {
              numbers.push(obj.number);
            });
          }

          if (country.representations[minDistanceArrayIndex].mobile.length > 0) {
            country.representations[minDistanceArrayIndex].mobile.forEach((obj) => {
              numbers.push(obj.number);
            });
          }
          address = country.representations[minDistanceArrayIndex].address;
          type = country.representations[minDistanceArrayIndex].type;
          city = country.representations[minDistanceArrayIndex].city;

          setRepLocation((curr) => {
            return {
              ...curr,
              long: country.representations[minDistanceArrayIndex].longitude,
              lat: country.representations[minDistanceArrayIndex].latitude,
            };
          });
        }
      }

      setCard((curr) => {
        return {
          ...curr,
          country: city,
          flag: flag,
          address: address,
          phones: numbers,
          img: img,
          type: type,
        };
      });
    }
  }, [loading, error]);

  return (
    <>
      <MetaDecorator title="pageTitle.crisisWarningPage" />
      <SecondPushStepComponent
        needHelp={pushNotifcations.needHelp}
        crisisCardObj={card}
        showTopPart={true}
        showBottomText={true}
        showCrisisCard={showCrisisCard}
        longitude={repLocation.long}
        latitude={repLocation.lat}
      />
    </>
  );
};

export default SecondPushStep;