import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import apipath from "../../../apipath.js";
import ApplicationRoute from "../../../config/routes.js";
import HeaderContext from "../../../context/HeaderContext.js";
import UserContext from "../../../context/UserContext.js";
import fetcher from "../../../services/fetcher.js";
import MetaDecorator from "../../../utils/MetaDecorator.jsx";
import InfoMailForm from "../../foreign-registration/utils/InfoMailForm.jsx";
import WeiterButton from "../../registration/utils/WeiterButton.jsx";

//HINT* REGISTRATION
const InfoMailPage = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const { user, setUser } = useContext(UserContext);
  const { setHeader } = useContext(HeaderContext);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [GDPRForeignAustrianOfficialInfo, setGDPRForeignAustrianOfficialInfo] = useState(user.GDPRForeignAustrianOfficialInfo ? true : null);
  const [GDPRForeignAustrianInofficialInfo, setGDPRForeignAustrianInofficialInfo] = useState(user.GDPRForeignAustrianInofficialInfo ? true : null);

  const confirmInfo = () => {
    fetcher(
      // Change api call when backend/middleware are modified
      `${apipath.path}users/CURRENT/info`,
      "POST",
      setData,
      setError,
      setLoading,
      setSubmitting,
      false,
      "application/json",
      JSON.stringify({
        GDPRForeignAustrianOfficialInfo: GDPRForeignAustrianOfficialInfo,
        GDPRForeignAustrianInofficialInfo: GDPRForeignAustrianInofficialInfo
      }) // add content
    );
  };

  const submittedWithNoErrors = useCallback(
    () => {
      return !loading && !error;
    }, [loading, error]);

  useEffect(() => {
    if (!user.GDPRForeignAustrianOfficialInfoViewflag && !user.GDPRForeignAustrianInofficialInfoViewflag) {
      history.push(ApplicationRoute.TravelDashboardNoPermissions);
    }
  }, [user, history]);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "dse",
        title: t("pageTitle.completeAccount") + "%noarrow%",
        ariaLabel: t("terms.consent"),
        progress: "no",
      };
    });
  }, [setHeader]);

  //After submit
  useEffect(() => {
    if (submittedWithNoErrors()) {
      setUser((curr) => {
        return {
          ...curr,
          GDPRForeignAustrianOfficialInfo: GDPRForeignAustrianOfficialInfo,
          GDPRForeignAustrianInofficialInfo: GDPRForeignAustrianInofficialInfo,
          GDPRForeignAustrianOfficialInfoViewflag: false,
          GDPRForeignAustrianInofficialInfoViewflag: false,
        };
      });
      history.push(ApplicationRoute.TravelDashboardNoPermissions);
    }
  }, [loading, error, data, history, submittedWithNoErrors, setUser]);

  const submittingNow = () => {
    return submitting && loading;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    confirmInfo();
  };

  const isValid = () => {
    return (
      GDPRForeignAustrianOfficialInfo !== null &&
      GDPRForeignAustrianInofficialInfo !== null
    );
  };

  return (
    <div className="login-middle-container">
      <MetaDecorator title="pageTitle.completeAccount" />
      <p className="content-title" style={{ margin: "3.2rem 1.6rem" }}>
        {t("pages.infoMailPage.info")}
      </p>

      <InfoMailForm
        GDPRForeignAustrianOfficialInfo={GDPRForeignAustrianOfficialInfo}
        setGDPRForeignAustrianOfficialInfo={setGDPRForeignAustrianOfficialInfo}
        GDPRForeignAustrianInofficialInfo={GDPRForeignAustrianInofficialInfo}
        setGDPRForeignAustrianInofficialInfo={setGDPRForeignAustrianInofficialInfo}
      />

      <WeiterButton
        disabled={!isValid() || submittingNow()}
        onClick={onSubmitHandler}
        path={ApplicationRoute.TravelDashboardNoPermissions}
        positioned="static"
      />
    </div>
  );
};

export default InfoMailPage;