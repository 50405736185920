import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import EditInput from "../../../common/EditInput";
import HorizontalRule from "../../../common/HorizontalRule";
import InputValidation from "../../../common/InputValidaiton";
import Separater from "../../../common/Separater";
import { hasMiddleName, isIn, pluck } from "../../../utils/helpers";
import {
  IsDateBiggerThanTodayValidation,
  IsDateSmallerThan_1900_1_1_Validation,
  hasAtSymbolEmailValidation,
  hasNumber,
  hasTwoCharactersAfterAtSymbolEmailValidation,
  isDateValidation,
  isEmailValidation,
  isPhoneValidation,
  isValidFirstAndLastName,
} from "../../../utils/validation";
import { getCountryNameFromId } from "./countryInfo";

const TravelMember = ({
  FirstLastName,
  Birthdate,
  MobilePhone,
  Email,
  Gender,
  Nationality,
  DegreeOfRelationship,
  onChange,
  id = "",
  foreign = false,
  buttonText,
  buttonClickHandler,
  linkText,
  linkTo,
}) => {
  const { t } = useTranslation();

  const relationships = useMemo(
    () => [
      t("input.relative.options.parent"),
      t("input.relative.options.sibling"),
      t("input.relative.options.child"),
      t("input.relative.options.partner"),
      t("input.relative.options.etc"),
    ],
    [t]
  );

  const relationshipEnums = useMemo(
    () => {
      const rel = {};

      rel[t("input.relative.options.parent")] = 100000000;
      rel[t("input.relative.options.sibling")] = 100000001;
      rel[t("input.relative.options.child")] = 100000002;
      rel[t("input.relative.options.partner")] = 100000003;
      rel[t("input.relative.options.etc")] = 100000004;

      return rel;
    },
    [t]
  );

  const relationshipInvertEnums = useMemo(
    () => {
      return {
        100000000: t("input.relative.options.parent"),
        100000001: t("input.relative.options.sibling"),
        100000002: t("input.relative.options.child"),
        100000003: t("input.relative.options.partner"),
        100000004: t("input.relative.options.etc")
      }
    },
    [t]
  );

  let text = `${t("input.mitreisende.one")} (${FirstLastName})`;

  if (foreign) {
    text = `${t("input.relatives.text")} (${FirstLastName})`;
  }

  const countriesRaw = JSON.parse(localStorage.getItem("countries"));
  let countries = countriesRaw ? pluck(countriesRaw, "Name") : [];
  countries = countries ? countries.filter((n) => !isIn(n)) : countries;

  return (
    <div>
      <Separater
        text={text}
        buttonText={buttonText}
        buttonClickHandler={buttonClickHandler}
        linkText={linkText}
        linkTo={linkTo}
      />
      <EditInput
        id={"FirstLastName" + id}
        placeholder="input.vorNachname.text"
        value={FirstLastName}
        labelText="input.vorNachname.text"
        ariaRequired={true}
        onChange={(e) =>
          onChange({
            target: { id: id + "FirstLastName", value: e.target.value },
          })
        }
      />
      <InputValidation
        text={
          hasNumber(FirstLastName)
            ? "errors.nameHasNumber"
            : "errors.notValidContactPersonFirstAndLastName"
        }
        isHidden={isValidFirstAndLastName(FirstLastName) || hasMiddleName(FirstLastName)}
      />
      <InputValidation
        isHidden={!hasMiddleName(FirstLastName)}
        text="errors.middleName"
        ariaLabel="errors.middleName"
      />

      <HorizontalRule />
      <EditInput
        id={"Birthdate" + id}
        value={Birthdate}
        onChange={(e) => onChange({ target: { id: id + "Birthdate", value: e.target.value } })}
        placeholder="input.date.birthdate"
        labelText="input.date.birthdate"
        showValidationBorder={
          !isDateValidation(Birthdate) ||
          !IsDateSmallerThan_1900_1_1_Validation(Birthdate) ||
          IsDateBiggerThanTodayValidation(Birthdate)
        }
      />
      <InputValidation isHidden={isDateValidation(Birthdate)} text="errors.notValidDate" />

      <InputValidation
        isHidden={!IsDateSmallerThan_1900_1_1_Validation(Birthdate) || !isDateValidation(Birthdate)}
        text="errors.dateOfBirthLessThan_01_01_1900"
      />

      <InputValidation
        isHidden={IsDateBiggerThanTodayValidation(Birthdate) || !isDateValidation(Birthdate)}
        text="errors.dateBiggerThanToday"
      />

      <HorizontalRule />
      <EditInput
        id={"MobilePhone" + id}
        placeholder="input.contact.placeholder"
        value={MobilePhone}
        labelText="input.mobilenum.text"
        ariaRequired={true}
        onChange={(e) =>
          onChange({
            target: { id: id + "MobilePhone", value: e.target.value },
          })
        }
      />
      <InputValidation
        text="errors.notValidPhoneNumber"
        isHidden={isPhoneValidation(MobilePhone, true) || MobilePhone.length === 0 || MobilePhone === ""}
      />
      <HorizontalRule />
      <EditInput
        id={"Email" + id}
        placeholder="input.email.text"
        value={Email}
        labelText="input.email.text"
        ariaRequired={false}
        onChange={(e) => onChange({ target: { id: id + "Email", value: e.target.value } })}
      />
      <InputValidation
        isHidden={
          Email.length === 0 ||
          !hasAtSymbolEmailValidation(Email) ||
          !hasTwoCharactersAfterAtSymbolEmailValidation(Email) ||
          isEmailValidation(Email)
        }
        text="errors.notValidEmail"
      />
      <InputValidation
        isHidden={Email.length === 0 || hasAtSymbolEmailValidation(Email)}
        text="errors.missingAtSymbolEmail"
      />
      <InputValidation
        isHidden={
          Email.length === 0 ||
          !hasAtSymbolEmailValidation(Email) ||
          hasTwoCharactersAfterAtSymbolEmailValidation(Email)
        }
        text="errors.charactersAfterAtSymbolLessThanTwoEmail"
      />
      <HorizontalRule />
      <EditInput
        id={"Gender" + id}
        placeholder="input.gender.text"
        value={Gender}
        labelText="input.gender.text"
        ariaRequired={true}
        onChange={(value) => onChange({ target: { id: id + "Gender", value: value } })}
        options={[t("input.gender.options.male"), t("input.gender.options.female"), t("input.gender.options.other")]}
      />
      <HorizontalRule />
      <EditInput
        id={"Nationality" + id}
        placeholder="input.nationality.text2"
        value={getCountryNameFromId(Nationality)}
        labelText="input.nationality.text2"
        ariaRequired={true}
        onChange={(value) =>
          onChange({
            target: {
              id: id + "Nationality",
              value: countriesRaw.find((el) => {
                return el.Name === value;
              })["Id"],
            },
          })
        }
        filtered={true}
        options={countries}
      />
      <HorizontalRule />
      <EditInput
        id={"DegreeOfRelationship" + id}
        placeholder="input.relative.text"
        value={relationshipInvertEnums[DegreeOfRelationship]}
        labelText="input.relative.text"
        ariaRequired={true}
        onChange={(value) =>
          onChange({
            target: {
              id: id + "DegreeOfRelationship",
              value: relationshipEnums[value],
            },
          })
        }
        options={relationships}
      />
    </div>
  );
};

export default TravelMember;